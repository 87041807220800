
<template>
     <v-dialog v-model="dialogPerfil" fullscreen hide-overlay transition="dialog-bottom-transition">         
            <v-card>
               
            <v-toolbar dark color="orange">
                <v-btn icon dark @click="dialogPerfil = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Mi Perfil</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> 
                    <v-btn :disabled="habilitaGuardar"  dark flat :loading="loadingSubmit"   @click="submit">Guardar</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            

            <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
               
                <v-flex xs12 sm12 md12> <h3  > Datos de Inicio se sesion</h3></v-flex>
                <v-flex xs12 sm6 md6>                     
                    <v-text-field
                        :value="usuario.name"
                        label="Username"
                        disabled
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                   <v-text-field
                        :value="usuario.email"
                        label="Email"
                        disabled
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="usuario.password"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        outline
                        :type="show1 ? 'text' : 'password'"                        
                        label="Password"
                        hint="Ingrese al menos 8 caracteres"
                        counter
                        @click:append="show1 = !show1"
                          v-validate="{ required: false }"
                        :error-messages="errors.collect('Password')"
                        item-text="Password"
                        data-vv-name="Password"
                    ></v-text-field>                  
                </v-flex>

                <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="usuario.password_confirm"                      
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                       outline
                        :type="show1 ? 'text' : 'password'"                         
                        label="Confirme password"
                        hint="Ingrese la misma password"
                        counter
                        @click:append="show1 = !show1"
                        v-validate="{ required: false  }"
                        :error-messages="errors.collect('Confirme password')"
                        item-text="Confirme password"
                        data-vv-name="Confirme password"
                    ></v-text-field>
                </v-flex>
                 
                
                </v-layout>
            </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
             
         
            </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>
import  VeeValidate,{Validator} from 'vee-validate'; 
import { mapState, mapActions } from 'vuex' 
import api from '../../api/inscripcion'
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
Validator.localize('es', es);  
Vue.use(VeeValidate);
 
export default {
    data () {
        return {
            show1:false,
            loadingSubmit:false,
            dialogPerfil:false,
            usuario:{
                name:null,
                email:null,
                password:null,
                password_confirm:null
            }
        }
    },

    computed: {
        habilitaGuardar(){
            return !(this.usuario.password && this.usuario.password == this.usuario.password_confirm);
        }
    },
     
    watch:{
     
    },
     
    methods:{
        getCleanUsuario(){
            return  {
                name:null,
                email:null,
                password:null,
                password_confirm:null
            }
        },
        open(){

            api.getInfoPerfil().then((res)=>{
                this.usuario.name = res.name;
                this.usuario.email = res.email;
            })
 
            this.dialogPerfil = true;
        },

        close(){
            this.dialogPerfil = false;
        },
        submit(){
            this.loadingSubmit = true;
            if(this.usuario.password && this.usuario.password == this.usuario.password_confirm){
                api.actualizarPerfil(this.usuario).then((res)=>{                 
                    if(res.status == 200){
                        this.$store.commit('app/SET_MESSAGE',res.message );
                        this.$store.commit('app/SET_SHOW', true);
                        this.dialogPerfil = false;
                        this.usuario = this.getCleanUsuario()
                    }
                    //this.getDataFromApi();
                    this.loadingSubmit = false;
                    this.dialogPerfil = false;
                })
            }
            
    
             
        }
    }
}

 
</script>