<template>
    <v-layout align-center justify-center>

        <div v-if="loadingEvento" class="my-3 xs12 sm4 ">
            <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
        </div>

        <EditarPersona v-if="!loadingEvento" ref="dialogPersona" :persona="personaEditar">
        </EditarPersona>


        <v-flex v-if="!loadingEvento">
            <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
            <!-- <AutocompleteSearch /> -->

            <v-layout row wrap pa-3>
                <v-flex xs6 md2>
                    <v-img contain height="150" :src=evento.banner_full_path></v-img>
                </v-flex>
                <v-flex xs6 md3>
                    <h2 class="display-1">Evento</h2>
                    <h2 class="headline">{{ evento.nombre }}</h2>
                    <h2 class="subheading"> Lugar</h2>
                    <h2 class="title"> {{ evento.ciudad }}</h2>
                    <h2 class="subheading">Fecha</h2>
                    <h2 class="title"> {{ evento.fecha_hora | formatDate }}</h2>
                </v-flex>
                                
                <!-- <v-flex xs12 md2 mx-1 my-3>
                    <v-card>
                        <v-card-title class="headline"> 
                            Inscriptos y Bonificados
                        </v-card-title>
                        <v-card-text>
                            <h2 class="display-1">150</h2>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md2 mx-1 my-3>
                    <v-card>
                        <v-card-title class="headline"> 
                            Pendientes
                        </v-card-title>
                        <v-card-text>
                            <h2 class="display-1">150</h2>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md2 mx-1 my-3>
                    <v-card>
                        <v-card-title class="headline"> 
                            Vencidos
                        </v-card-title>
                        <v-card-text>
                            <h2 class="display-1">150</h2>
                        </v-card-text>
                    </v-card>
                </v-flex> -->
            </v-layout>
            <v-divider class="mt-3"></v-divider>         

            <v-btn dark small color="orange" @click="nuevaIncripcion" rigth>Nueva Inscripcion </v-btn>
            

            <v-btn v-if="isAdmin" small dark color="secondary"  :to="{ name: 'eventoEditar', params: { id: evento.id } }" rigth flat>Editar Evento </v-btn>

            <v-btn v-if="isAdmin" dark small color="orange" :to="{name: 'eventoAsignarNumeros', params: { id: evento.id } }" rigth>Asignar numeros </v-btn>

            <v-btn dark small color="orange" :to="{name: 'eventoAsignarNumerosManual', params: { id: evento.id } }" rigth>Asignar numeros Manual </v-btn>
 
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn color="secondary" dark v-on="on" small>
                        <v-icon right dark>clouda_download</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-tile @click="descargarInscripciones">

                        <v-list-tile-title>Planilla (Rufus)</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="descargarParticipantes">
                        <v-list-tile-title>Planilla Participantes</v-list-tile-title>
                    </v-list-tile>
                </v-list>
            </v-menu>





            <v-tabs v-model="model" color="orange" dark slider-color="black">
                <v-tab :key="1" :href="`#tab-1`">
                    Inscriptos
                </v-tab>

             
                <v-tab :key="3" :href="`#tab-3`">
                    Cupones
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="model">

                <v-tab-item :key="1" :value="`tab-1`">
                    <InscriptionsList :estados="['PAGO_REALIZADO', 'PAGO_BONIFICADO', 'pago_no_paga']" />
                </v-tab-item>

              

                <v-tab-item :value="`tab-3`" :key="3" :lazy="true">
                    <v-card flat>
                        <v-container>
                            <CuponDialog :evento="evento" @saveCupon="$event => { refrescarCupones() }" />
                            <CuponesTabla :evento="evento" ref="cuponesTabla" />
                        </v-container>
                    </v-card>
                </v-tab-item>


            </v-tabs-items>
        </v-flex>

    </v-layout>
</template>

<script> 
import { mapGetters, mapState } from "vuex";
import api from '@/api/inscripcion'
import config from '@/config'

import CuponDialog from '../../../components/admin/common/CuponDialog.vue';
import CuponesTabla from '../../../components/admin/common/CuponesTabla.vue';
import debounce from '../../../directives/debounce';
import InscriptionsList from './components/inscriptions-list.vue';
 
export default {
    directives: { debounce },
    components: {
        "CuponDialog": CuponDialog,
        "CuponesTabla": CuponesTabla,
        "InscriptionsList": InscriptionsList 
    },
    data() {
        return {
            dialog: false,
            fechaExpiracion: null,
            fechaExpiracionFormateada: null,
            dialogTitle: "Agregar cupón",
            cupon: "",
            habilitado: false,
            cuponRules: [
                (v) => !!v || "El cupón es requerido",
                (v) =>
                    (v && /^[A-Za-z0-9]{1,8}$/.test(v)) ||
                    "El cupón debe ser alfanumérico y tener máximo 8 caracteres",
            ],
            cuponErrors: [],
            model: null,
            editarInscripcion: {
                state: '',
            },
            dialogInscripcion: false,
            inscripcionEditar: {},
            inscripcionBorrar: {},
            loadingEvento: true,
            loading: false,
            personaEditar: {},
            pagination: {
                search: '',
                descending: false,
                page: 1,
                rowsPerPage: 20,
                sortBy: "id",
                totalItems: 0,
                filters: {
                    estados: this.estados
                }
            },

            items: [],
            totalItems: 0,
            estados_inscripcion: [
                {
                    text: 'Todos',
                    value: '',
                },
                {
                    text: 'PAGO_PENDIENTE',
                    value: 'PAGO_PENDIENTE',
                },
                {
                    text: 'PAGO_REALIZADO',
                    value: 'PAGO_REALIZADO',
                },
                {
                    text: 'PAGO_BONIFICADO',
                    value: 'PAGO_BONIFICADO',
                },
                {
                    text: 'PAGO_NO_PAGA',
                    value: 'PAGO_NO_PAGA',
                }
            ]
        }
    },
    computed: {
        ...mapGetters("auth", [
            "isLoggedIn",
            "isAdmin",
            "isOrganizador",
            "nombreUsuario",
        ]),
        ...mapState({
            evento: state => state.eventos.eventoActual,
            modalidades: state => state.eventos.eventoActual.modalidades,
            steps: state => [
                {
                    text: 'Eventos',
                    disabled: false,
                    href: '/#/backoffice/evento/lista'
                },
                {
                    text: state.eventos.eventoActual.nombre,
                    disabled: true,
                    href: '/#/backoffice/event/' + state.eventos.eventoActual.id
                }
            ],

        }),
    },


    created() {
        scroll(0, 0);
        let eventoId = this.$route.params.id;       
        this.$store.dispatch('eventos/getEventoById', eventoId).then(( ) => {
            this.loadingEvento = false;
        });
    },
    watch: {

        pagination: {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        },

    },
    mounted() {

    },
    methods: {
        paginationChange(pagination) {
            this.pagination = pagination;
        },
        search(value) {
            this.pagination.search = value;
            this.pagination.page = 1;
            this.getDataFromApi();
        },

        refrescarCupones() {
            this.$refs.cuponesTabla.loadData();
        },
        getDataFromApi() {
            this.loading = true;
            let eventoId = this.$route.params.id;
            api.getInscriptosEventoOrganizadorId(eventoId, this.pagination).then((res) => {
                this.items = res.data;
                this.totalItems = res.total;
                this.loading = false;
            })
        },

        nuevaIncripcion() {
            if(this.evento.privado)
                this.$router.push("/admin/evento/"+ this.evento.id+"/"+this.evento.codigo_acceso +"/inscripcion"   );
            else
                this.$router.push({ "name": "eventoInscripcionAdmin" , params: { id: this.evento.id  }});             
        },


        descargarParticipantes() {
            let url = config.api_base_url;
            url = url + config.api_participantes_evento_export.replace('ID', this.$route.params.id);
            url = url + '?token=' + sessionStorage.getItem("access_token")
            window.open(url)
        },

        descargarInscripciones() {
            let url = config.api_base_url;
            url = url + config.api_inscriptos_evento_export.replace('ID', this.$route.params.id);
            url = url + '?token=' + sessionStorage.getItem("access_token")
            window.open(url)
        },



        abrirEditarPersonaDialog(persona) {
            this.$refs.dialogPersona.abrirDialog();
            this.personaEditar = persona;
        },



        abrirEditarInscripcion(inscripcion) {
            this.inscripcionEditar = JSON.parse(JSON.stringify(inscripcion));
            this.$refs.dialogInscripcion.abrirDialog();
        },

        abrirVerInscripcion(inscripcion) {
            this.$refs.dialogVerInscripcion.abrirDialog(inscripcion);
        },

        abrirBorrarInscripcion(inscripcion) {
            this.inscripcionBorrar = JSON.parse(JSON.stringify(inscripcion));

            this.$refs.dialogBorrarInscripcion.abrirDialog();
        },

    }

}
</script>                   
