<template>   
<v-layout align-center justify-center>
	<v-flex>
        <v-dialog v-model="dialogDisciplina" max-width="600px"  transition="dialog-bottom-transition">         
			<v-card>
				<v-toolbar dark color="orange">
					<v-btn icon dark @click="dialogDisciplina = false">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>Disciplina</v-toolbar-title>
					<v-spacer></v-spacer>
					<!-- <v-toolbar-items> 
                        <v-btn  dark flat :loading="loadingSubmit"  @click="submitDisciplina">Guardar</v-btn>
					</v-toolbar-items> -->
				</v-toolbar>
              <v-card-text>
                    <v-container grid-list-md>
                        <v-form autocomplete="off">
                            <v-layout wrap>
								<v-flex xs12 > <h3> Datos de la Disciplina</h3></v-flex>
								
								<v-flex xs12 >
                                    <v-text-field 
                                        v-model="disciplina.nombre" 
                                        label="Nombre Disciplina"
                                        v-validate="'required'"
                                                :error-messages="errors.collect('Nombre Disciplina')"
                                        item-text="Nombre Disciplina"
                                        data-vv-name="Nombre Disciplina"
                                    >
                                    </v-text-field>
								</v-flex>  
                            </v-layout>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" outline @click="dialogDisciplina = false">Cancelar</v-btn>
                    <v-btn color="orange"   primary :loading="loadingSubmit"  @click="submitDisciplina">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        
        <v-dialog v-model="dialogBorradoDisciplina" max-width="290">
        <v-card>
        <v-card-title class="headline">¿Eliminar la disciplina?</v-card-title>

        <v-card-text v-if="eventos_count!==0" >
          Existen  {{ eventos_count }} eventos asociados a esta disciplina, 
          si procede a eliminarla, los eventos dejaran de estar asociados a ella.
          
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="secondary"
            flat="flat"
            @click="dialogBorradoDisciplina = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="orange"
            flat="flat"
            @click="deleteDisciplina"
          >
            Borrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

       
        <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
        <v-btn color="orange" rigth  @click="openNewDisciplina" >
            Crear Disciplina
            <v-icon right small>library_add</v-icon>
        </v-btn> 

             
            <section>
                <v-card>
                    <v-card-title>
                      <h1 class="mx-2">Disciplinas</h1>    
                    <v-spacer></v-spacer>
                    
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :total-items="totalItems"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :items="items" 
                    class="elevation-3"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.id }}</td>
                        <td class="text-xs-left">{{ props.item.nombre }}</td> 
                        <td class="text-xs-center">
                            <v-chip v-if="props.item.eventos_count !==0" color="orange" >{{ props.item.eventos_count }} Eventos</v-chip></td>  
                        <td class="text-xs-center">
                            <v-btn title="Editar" flat  icon  @click="editarDisciplina(props.item)"  >
                                <v-icon alt="editar" small > edit</v-icon>
                            </v-btn> 
                            <v-btn title="Borrar" flat  icon  @click="confirmarBorradoDisciplina(props.item)"  >
                                <v-icon alt="eliminar" small > delete</v-icon>
                            </v-btn> 
                        
            
                        </td>
                    </template>
                    <template slot="no-data">
                        <v-alert :value="true" color="warning" icon="warning">
                            <span v-if="!loading">Sin Disciplinas creadas.</span>
                            <span v-if="loading">Cargando...</span>
                        </v-alert>
                    </template>
                </v-data-table>
                </v-card>
            </section>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex'; 
import  VeeValidate,{Validator} from 'vee-validate'; 
import api from '../../../api/disciplina'
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
Validator.localize('es', es);  
Vue.use(VeeValidate);

export default {
    data () {
        return { 
            loadingSubmit:false,
            dialogDisciplina:false,
            dialogBorradoDisciplina:false,
            indexDisciplinaBorrar:null,
            eventos_count: 0,
            disciplina:{
                id:'',
                nombre:''
            },
            disciplinas:[],
            loading:false,
            steps:[                 
                {
                    text: 'Disciplinas',
                    disabled: false,
                    href: '/#/admin/disciplina/lista'
                }],
            pagination: {
                sortBy:'id',
                descending:true,
                search:null
            },
            headers: [
                {text:'Id', align:'center', sortable:true, value:'id'},
                {
                    text: 'Nombre Disciplina',
                    align: 'left',
                    sortable: true,
                    value: 'nombre'
                },
                {
                    text: 'Eventos etiquetados',
                    align: 'center',
                    sortable: true,
                    value: 'nombre'
                },
                { text: 'Acciones', value: 'acciones', align: 'center',sortable: false },
            ],
            
        }
    },
    computed: mapState({
        items: state => state.disciplinas.disciplinas_listado? state.disciplinas.disciplinas_listado.data:[],
        totalItems: state => state.disciplinas.disciplinas_listado? state.disciplinas.disciplinas_listado.total:0
    }),
    created () {
        scroll(0,0);
        //this.$store.dispatch('eventos/getEventosListado', {filter:{},page:1})
    },
    watch:{
        pagination:{
            handler(){
                this.loading = true;
                this.getDataFromApi() 
            },
            deep: true
        }        
    },
    mounted(){
        this.loading = true;
        //this.getDataFromApi()
    },
    
    methods:{
        getDataFromApi(){
            this.loading = true;
            return this.$store.dispatch('disciplinas/getDisciplinasListado', this.pagination) 
            .then((res)=>{
                this.loading = false;
            })
        },

				editarDisciplina(disciplina){
						this.dialogDisciplina = true;
						this.disciplina = {
								id:disciplina.id,
								nombre:disciplina.nombre
						}
				},
        
				getCleanDisciplina(){ 
						return { nombre:''}
				},
        
        submitDisciplina(){
            this.loadingSubmit = true;
            this.$validator.validateAll()
            .then( isOk => { 
                if (isOk && this.disciplina.id){ // para actualizar existente
                    api.actualizarDisciplina(this.disciplina.id, this.disciplina)
                    .then(res => {         
                        debugger;    
                        if(res.status == 200 || res.status == 201){
                            this.handleSaveSuccess(res);
                        }
                })
                    .catch(error => {
                            this.handleSaveError(error.response);
                    });
                }
        
                else if(isOk){ // para crear nueva
                    api.crearDisciplina(this.disciplina)
                    .then(res => {                 
                        if(res.status == 201){
                             this.handleSaveSuccess(res);                           
                        }
                    })
                    .catch(error => {
                        this.handleSaveError(error.response);
                    });
                }      
            })
            .finally(() => this.loadingSubmit = false)

        },
        
				openNewDisciplina(){
					this.dialogDisciplina = true;
					this.disciplina = {
						id:null,
						nombre:'',
					}
				},
				
				confirmarBorradoDisciplina(disciplina){
					this.dialogBorradoDisciplina = true;
					this.indexDisciplinaBorrar = disciplina.id;
					this.eventos_count = disciplina.eventos_count;
				},
		
				deleteDisciplina(){
						api.borrarDisciplina(this.indexDisciplinaBorrar)
						.then((res)=>{                 
								if(res.status == 202){
								this.$store.commit('app/SET_MESSAGE',res.message );
								this.$store.commit('app/SET_SHOW', true);
								this.dialogBorradoDisciplina = false;
								}
								this.getDataFromApi();
								this.loadingSubmit = false;
						})
						.catch(error => {
								this.loading = false;
								//console.log(error.status);
								})	
				},

				handleSaveError(error) {
						//console.log(error);
						this.exito = false;
						this.$store.commit('app/SET_MESSAGE', error.data.message);
						this.$store.commit('app/SET_TYPE', 'error');
						this.$store.commit('app/SET_SHOW', true);
				},

				handleSaveSuccess(res) {
                        this.getDataFromApi();
						this.exito = true;
						this.$store.commit('app/SET_MESSAGE', res.message);
						this.$store.commit('app/SET_TYPE', 'success');
						this.$store.commit('app/SET_SHOW', true);
                        this.dialogDisciplina = false;
				},
    }
}
</script>                   
