<template>
    <v-tooltip v-if="hasPaymentInfo" top>
        <template v-slot:activator="{ on }">
            <v-btn @click="clickHandler" flat color="primary" dark v-on="on">{{ paymentMethod }}</v-btn>
        </template>
        <span>
            <ul v-if="payment">
                <li>Metodo: {{ paymentType }}</li>
                <li>Status: {{ paymentStatus }}</li>
                <li>Fecha Creacion: {{ payment.date_created | formatDateTime  }}</li>
                <li>Fecha Aprobación: {{ payment.date_approved | formatDateTime }}</li>
            </ul>
        </span>
    </v-tooltip>
    <span v-else></span>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        inscripcion: Object
    },
    methods: {
        clickHandler(){
            this.$emit('click', this.merchant_order);
        }
    },
    computed:{
        paymentType(){
            return this.payment?this.payment.payment_type_id  : ''
        },
        payment(){
            return this.merchant_order.payments[0];
        },

        merchant_order(){             
            return this.inscripcion.merchant_order? this.inscripcion.merchant_order : null;
        },
        hasPaymentInfo(){
            return this.merchant_order && this.merchant_order.payments.length > 0 && this.merchant_order.payments[0].payment_method_id;
        },
        paymentMethod(){
            return (this.merchant_order && this.merchant_order.payments.length > 0)?  this.merchant_order.payments[0].payment_method_id : '';
        },
        paymentStatus(){
            return (this.merchant_order && this.merchant_order.payments.length > 0)?  this.merchant_order.payments[0].status : '';
        },
    },

}
</script>

