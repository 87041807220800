<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar   color="orange">
                <v-toolbar-title>Iniciar Sesión</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                   
                   
                </v-tooltip>
                <v-tooltip right>
                  <v-btn slot="activator" icon large href="https://codepen.io/johnjleider/pen/wyYVVj" target="_blank">
                    <v-icon large>mdi-codepen</v-icon>
                  </v-btn>
                 
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field 
                        v-validate="'required'"
                        data-vv-as="email" 
                        data-vv-name="email" 
                        v-model="email" 
                        prepend-icon="person" 
                        name="email" 
                        label="Email o Nombre de Usuario"
                        :error-messages="errors.collect('email')" 
                        @keyup.enter="login"
                        type="text">
                    </v-text-field>
                  <v-text-field  @keyup.enter="login" v-model="password" id="password" prepend-icon="lock" name="password" label="Password" type="password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
              
                <v-btn   block :loading="isPending" @click="login" color="orange">Ingresar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import  VeeValidate,{Validator} from 'vee-validate'; 
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'  
import api from '../api/inscripcion'
Validator.localize('es', es);  
Vue.use(VeeValidate);
import { mapState } from 'vuex' ;
import Vuex from 'vuex'
import { ErrorBag } from 'vee-validate';


export default {
    data: () => ({
        email:'',
        password:'',
        drawer: null
    }),
    props: {
        source: String
    },
    computed: {
        ...Vuex.mapGetters("auth",["isPending", "isLoggedIn", "isAdmin", "isOrganizador"])
    }, 
    methods:{
        login(){
            this.$validator.validateAll().then((isOk)=>{
                if(isOk){
                    this.$store.dispatch("auth/login", {
                        email:this.email,
                        password:this.password
                    }).then((res)=>{
                        if(this.isLoggedIn ){
                            this.$router.push("/backoffice/dashboard");                      
                        }else{
                            //faltaria ver si viene un error difrente de 401
                            const bag = new ErrorBag(); 
                            this.errors.add({
                                field: 'email',
                                msg: 'email y/o password incorrectos'
                            });
                            // Display it like this:
                            bag.first('email');                            
                        }                
                    })
                }
            })
        }
    }
}
</script>