<template>
    <v-dialog v-model="loading"  persistent width="300">
            <v-card color="orange" dark>
                <v-card-text>
                    Espere por favor...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
</template>
<script>

export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        
    })
}
</script>