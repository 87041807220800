import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login' 
import Home from '@/pages/public/Home' 
import InscripcionEvento from '@/pages/public/evento/InscripcionEvento' 
import ResultadosFotosEvento from '@/components/ResultadosFotosEvento' 
//import Calendario from '@/components/Calendario' 
//import CalendarioList from '@/components/CalendarioList' 
import InformacionEvento from '@/pages/public/evento/InformacionEvento'
import Contacto from '@/pages/public/contacto/Contacto'

import Dashboard from '@/pages/backoffice/dashboard'

import EventoListaOrganizador from '@/components/admin/EventoListaOrganizador'
import EventoAdjuntosAdmin from '@/components/admin/EventoAdjuntosAdmin'

import Event from '@/pages/backoffice/events/event'
import EventoAsignarNumeros from '@/pages/backoffice/events/asignar-numeros'
import EventoAsignarNumerosManual from '@/pages/backoffice/events/asignar-numeros-manual'
import EventoEditar from '@/pages/backoffice/events/editar' 
import CamposCustom from '@/pages/backoffice/events/campos-custom'

import EventoLista from '@/components/admin/EventoLista'
import EventoConfigurarImagen from '@/components/admin/EventoConfigurarImagen'
import OrganizadoresLista from '@/components/admin/OrganizadoresLista'
import ResultadoInscripcion from '@/pages/public/evento/ResultadoInscripcion'
import LinkInvalido from '@/components/LinkInvalido'
import InscripcionPagada from '@/components/InscripcionPagada'
import DisciplinaLista from '@/pages/backoffice/disciplinas/DisciplinaLista'
import NotFound from '../pages/NotFound.vue'


import auth from '../api/auth';
import store from '../store';
import ResultadoInscripcionConMensaje from '../pages/public/evento/ResultadoInscripcionConMensaje.vue'

Vue.use(Router)

export default new Router({

  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/contacto',
      name: 'contacto',
      component: Contacto
    },
    /* {
       path: '/calendario/calendar',
       name: 'calendario-calendar',
       component: Calendario
     },
     {
       path: '/calendario/lista',
       name: 'calendario-lista',
       component: CalendarioList
     },*/

    {
      path: '/inscripcion/:id/:codigo_acceso',
      name: 'inscripcionPrivado',
      component: InscripcionEvento
    }, 
    {
      path: '/inscripcion/:id',
      name: 'inscripcion',
      component: InscripcionEvento
    },
    {
      path: '/resultado_ok/:evento_id/:inscripcion_id',
      name: 'resultado_ok',
      component: ResultadoInscripcionConMensaje
    },
    {
      path: '/resultado_ok/:id',
      name: 'resultado_ok',
      component: ResultadoInscripcion
    },
    {
      path: '/resultado_error/:id',
      name: 'resultado_error',
      component: ResultadoInscripcion
    },
    {
      path: '/resultado_pendiente/:id',
      name: 'resultado_pendiente',
      component: ResultadoInscripcion
    },
    {
      path: '/link-invalido',
      name: 'link-invalido',
      component: LinkInvalido
    },
    {
      path: '/inscripcion-pagada/:code',
      name: 'inscripcion-pagada',
      component: InscripcionPagada
    },
    {
      path: '/informacion-evento/:id',
      name: 'informacionEvento',
      component: InformacionEvento
    },
    {
      path: '/informacion-evento/:id/:codigo_acceso',
      name: 'informacionEventoPrivado',
      component: InformacionEvento
    },
    {
      path: '/resultados-fotos-evento/:id',
      name: 'resultadosFotosEvento',
      component: ResultadosFotosEvento
    },
    {
      path: '/backoffice/dashboard',
      name: 'backofficeDashboard',
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next);
      }
    },

    {
      path: '/organizador/evento/lista',
      name: 'eventoListaOrganizador',
      component: EventoListaOrganizador,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next);
      }
    },

    {
      path: '/backoffice/event/:id',
      name: 'event',
      component: Event,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next);
      }
    },
    {
      path: '/backoffice/event/:id/asignar-numeros',
      name: 'eventoAsignarNumeros',
      component: EventoAsignarNumeros,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next);
      }
    },
    {
      path: '/backoffice/event/:id/asignar-numeros-manual',
      name: 'eventoAsignarNumerosManual',
      component: EventoAsignarNumerosManual,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next);
      }
    },

    {
      path: '/admin/evento/:id/campos-extra',
      name: 'event-campos-extra',
      component: CamposCustom,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next);
      }
    },

    {
      path: '/admin/evento/nuevo',
      name: 'eventoNuevo',
      component: EventoEditar,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin');
      }
    },
    {
      path: '/admin/evento/lista',
      name: 'eventoLista',
      component: EventoLista,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin');
      }
    },
    {
      path: '/admin/evento/:id/configurar-imagen',
      name: 'configurarImagen',
      component: EventoConfigurarImagen,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin');
      }
    },
    {
      path: '/admin/evento/:id/editar',
      name: 'eventoEditar',
      component: EventoEditar,
      beforeEnter: (to, from, next) => {
        store.commit('app/SET_MESSAGE', null);
        store.commit('app/SET_SHOW', false);
        auth.validateAuth(next, 'admin');
      }
    },
    {
      path: '/admin/evento/:id/inscripcion',
      name: 'eventoInscripcionAdmin',
      component: InscripcionEvento,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin|organizador');
      }
    },
    {
      path: '/admin/evento/:id/:codigo_acceso/inscripcion',
      name: 'eventoInscripcionAdminPrivada',
      component: InscripcionEvento,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next,'admin|organizador');
      }
    },
    {
      path: '/admin/evento/:id/adjuntos',
      name: 'eventoAdjuntosAdmin',
      component: EventoAdjuntosAdmin,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin');
      }
    },



    {
      path: '/backoffice/event/:id',
      name: 'event',
      component: Event,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin');
      }
    },

    {
      path: '/admin/organizadores',
      name: 'organizadoresLista',
      component: OrganizadoresLista,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next, 'admin');
      }
    },
    
    {
      path: '/admin/disciplina/lista',
      name: 'disciplinaLista',
      component: DisciplinaLista,
      beforeEnter: (to, from, next) => {
        auth.validateAuth(next,'admin');
      }
    },
    
    // Ruta comodín para manejar cualquier ruta que no coincida con las anteriores
    { path: '*', component: NotFound }
     
  ]
})
