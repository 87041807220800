<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">Agregar cupón</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ dialogTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" @submit.prevent="submitForm">
                    <v-text-field hint="Texto del cupon Ej: FINISH20" v-model="cupon" :rules="cuponRules"
                        :error-messages="cuponErrors" label="Cupón" maxlength="15"></v-text-field>

                    <v-text-field append-icon="percent" type="number" min="1" max="100" v-model="porcentage"
                        :rules="porcentageRules" :error-messages="cuponErrors" label="Porcentaje">
                    </v-text-field>

                    <v-menu ref="menuDate1" :close-on-content-click="false" v-model="menuDate1" :nudge-right="40" lazy
                        transition="scale-transition" offset-y full-width min-width="290px">

                        <v-text-field v-validate="'required'" :error-messages="errors.collect('fecha_inicio_validez')"
                            name="fecha_inicio_validez" slot="activator" v-model="fechaInicioValidezFormateada"
                            data-vv-name="fecha_inicio_validez" label="Fecha Inicio Validez" prepend-icon="event" ></v-text-field>
                            
                            <v-date-picker ref="picker" v-model="fechaInicioValidez" min="2017-01-01" @change="saveDateInicio"
                            locale="es-ar"></v-date-picker>
                    </v-menu>
                    

                    <v-menu ref="menuDate2" :close-on-content-click="false" v-model="menuDate2" :nudge-right="40" lazy
                        transition="scale-transition" offset-y full-width min-width="290px">

                        <v-text-field v-validate="'required'" :error-messages="errors.collect('fecha_fin_validez')"
                            name="fecha_fin_validez" slot="activator" v-model="fechaFinValidezFormateada"
                            data-vv-name="fecha_fin_validez" label="Fecha Fin Validez" prepend-icon="event" ></v-text-field>
                            
                            <v-date-picker ref="picker" v-model="fechaFinValidez" min="2017-01-01" @change="saveDateFin"
                            locale="es-ar"></v-date-picker>
                    </v-menu>

                    <v-switch v-model="habilitado" label="Habilitado"></v-switch>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="saveCupon" :disabled="!validForm">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import cupones from '../../../api/cupones';
export default {
    props: {
        evento: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            dialog: false,
            dialogTitle: 'Agregar cupón',
            cupon: '',
            porcentage: '',
            fechaInicioValidez: new Date().toISOString().substr(0, 10),
            fechaFinValidez: new Date().toISOString().substr(0, 10),
            fechaInicioValidezFormateada: '',
            fechaFinValidezFormateada: '',
            habilitado: true,
            menuDate1: false,
            menuDate2: false,
            cuponRules: [
                v => !!v || 'El cupón es requerido',
                v => (v && v.length <= 15) || 'El cupón debe tener como máximo 15 caracteres',
                v => (v && v.length >= 4) || 'El cupón debe tener como mínimo 4 caracteres',
                v => (v && /^[a-zA-Z0-9]+$/.test(v)) || 'El cupón debe ser alfanumérico',
            ],
            porcentageRules: [
                v => !!v || 'El porcentaje es requerido',
                v => (v && v <= 100) || 'El porcentaje debe ser menor o igual a 100',
            ],
            cuponErrors: [],
        }
    },
    computed: {
        validForm() {
            return this.cupon && this.porcentage && this.fechaInicioValidez && this.fechaFinValidez;
        }
    },
    methods: {
        saveDateInicio(date) {
            this.$refs.menuDate1.save(date)
        },

        saveDateFin(date) {
            this.$refs.menuDate2.save(date)
        },
        saveCupon() {
            if (this.$refs.form.validate()) {
                //guardar en la base de datos
                cupones.crearCupon({
                    codigo: this.cupon,
                    porcentaje_descuento: this.porcentage,
                    fecha_inicio_validez: this.fechaInicioValidez,
                    fecha_fin_validez: this.fechaFinValidez,
                    activo: this.habilitado,
                    evento_id: this.evento.id,
                }).then(response => {
                    this.$emit('saveCupon', response.data);
                    this.dialog = false;
                }).catch(error => {
                    alert("Ocurrio un error, intente mas tarde");
                    this.dialog = false; 
                }

                );
            } else {
                this.cuponErrors = this.$validator.errors.all();
            }
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    },
    watch: {
        dialog(val) {
            val || this.$emit('close');
        },
        fechaInicioValidez() {
            this.fechaInicioValidezFormateada = this.formatDate(this.fechaInicioValidez)
        },

        fechaFinValidez() {
            this.fechaFinValidezFormateada = this.formatDate(this.fechaFinValidez)
        }
    }
}

</script>