<template>
      
            
    <v-layout align-center justify-center>

        <div v-if="loading "  class="my-3 xs12 sm4 ">
            <v-progress-circular
                :size="50"
                color="amber"
                indeterminate
            ></v-progress-circular>
        </div>
        
        <v-flex v-if="!loading" > 
            <v-toolbar dark color="secondary">
                <v-toolbar-title>Inscripcion a:  <span v-if="evento">{{evento.nombre}}</span> -
                <div>Fecha : {{evento.fecha_hora | formatDateString }}</div>
                 </v-toolbar-title>
                <v-spacer></v-spacer>                
            </v-toolbar>
            <v-container>
                <v-layout align-center justify-center>
                    <v-flex>
                <v-alert
                    :value="resultado_pendiente"
                    type="warning"
                    >
                    Su pago esta pendiente.
                    Cualquier duda, comuniquese con Finish Time 
                    </v-alert>
                    <v-alert
                    :value="resultado_ok"
                    type="success"
                    >
                        Inscripción Registrada correctamente.
                    </v-alert>

                    <v-alert
                    :value="resultado_error"
                    type="error"
                    >
                        Ocurrió un error!
                    </v-alert>

                    <v-card>
                        

                        <v-card-title primary-title>
                        <div> 
                            <div v-if="resultado_ok" v-html="mensaje_inscripcion_correcta"></div>
                            <div v-if="resultado_error"  v-html="mensaje_inscripcion_error" ></div>
                            <div v-if="resultado_pendiente">
                                <p>Si eligió un medio de pago offline tiene 72hs para realizar el pago.</p>
                                <p>Gracias!</p>
                            </div>
                        </div>
                        </v-card-title>

                        <v-card-actions> 
                        </v-card-actions>
                    </v-card>
                    
                    
                    <v-btn v-if="hasRedirect" block :to="hasRedirect" color="secondary">                   
                        Volver
                    </v-btn> 
                    <v-btn v-else block to="/" color="secondary">                   
                        Inicio
                    </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
            
        </v-flex>
    </v-layout>
</template>

<script>
 
import Vue from 'vue'
import { mapGetters,mapState, mapActions } from 'vuex' 

 

export default {
    

    data: vm =>  ({ 
        loading:true,
        
         
    }),
    props: {
        source: String
    },
    computed: mapState({
        evento: state => state.eventos.eventoActual,
        modalidades: state => state.eventos.eventoActual.modalidades,
        resultado_ok : function(){
            return this.$router.currentRoute.name == 'resultado_ok';
        },
        resultado_error : function(){
            return this.$router.currentRoute.name == 'resultado_error';
        },
        resultado_pendiente : function(){
            return this.$router.currentRoute.name == 'resultado_pendiente';
        },
        hasRedirect : function(){             
            return this.$router.currentRoute.query.next;
        },
        mensaje_inscripcion_correcta:
            state => state.eventos.eventoActual.mensaje_inscripcion_correcta?
                            state.eventos.eventoActual.mensaje_inscripcion_correcta:
                            'Inscripción registrada correctamente.',
        mensaje_inscripcion_error:
            state => state.eventos.eventoActual.mensaje_inscripcion_error?
                            state.eventos.eventoActual.mensaje_inscripcion_error:
                            'Ocurrió un error. Comuniquese con Finish Time.',
    }), 

    created(){
        scroll(0,0);
        let eventoId = this.$route.params.id;
        this.loading = true;
        console.info('App currentRoute:', this.$router.currentRoute)

        this.$store.dispatch('eventos/getEventoById', eventoId  ).then(res=>{
           this.loading = false;
        })
    },
     
    watch: {
        
    },
    methods: {
         
    }
}
</script>