<template>
     <v-text-field 
        @keyup="search"
        v-model="value" append-icon="search"
        :label="label" single-line hide-details class="mx-2">
    </v-text-field>
</template>

<script>

    export default {
        props:{
            label: String
        },
        data() {
            return {
                value: '',
                outputValue: '',
                timeout: null
            }
        },
        methods: {          
          // after entering some text in the input field
          // the text will be shown after 1 second
          search: function() {            
            // clear timeout variable
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.
                console.log('searching:', self.value);
                self.outputValue = self.value;
                self.$emit('search', self.value);                
            }, 500);

          }
        }
    }
</script>