const inscripcionTablaMixins = {
    methods: {
        getFechaNacimiento(inscripcion) {
            if (inscripcion.personas.length == 1) {
                return inscripcion.personas[0].fecha_nacimiento;
            } else {
                return '-';
            }
        },

        getNombreYApellido(inscripcion) {
            let nombre = ''
            if ( inscripcion.personas.length == 1) {
                nombre = inscripcion.personas[0].nombre + " " + inscripcion.personas[0].apellido
            } else {
                inscripcion.personas.forEach((p, idx) => {
                    nombre += (idx > 0) ? " / " : "";
                    nombre += p.nombre + " " + p.apellido;
                });
            }
            return nombre;
        },

        getNombre(inscripcion) {
            let nombre = ''
            if (inscripcion.personas.length == 1) {
                nombre = inscripcion.personas[0].nombre;
            } else {
                inscripcion.personas.forEach((p, idx) => {
                    nombre += (idx > 0) ? " / " : "";
                    nombre += p.nombre;
                });
            }
            return nombre;
        },

        getApellido(inscripcion) {
            let apellido = ''
            if (inscripcion.personas.length == 1) {
                apellido = inscripcion.personas[0].apellido;
            } else {
                inscripcion.personas.forEach((p, idx) => {
                    apellido += (idx > 0) ? " / " : "";
                    apellido += p.apellido;
                });
            }
            return apellido;
        },

        getCiudad(i) {
            console.log(i)
            let ciudad = ''
            if (i.personas.length == 1) {
                ciudad = i.personas[0].ciudad;
            } else {
                i.personas.forEach((p, idx) => {
                    ciudad += (idx > 0) ? " / " : "";
                    ciudad += p.ciudad;
                });
            }
            return ciudad;
        },

        getDocumento(i) {
            let documento = ''
            if (i.personas.length == 1 && i.personas[0]) {
                documento = i.personas[0].documento;
            } else {
                i.personas.forEach((p, idx) => {
                    documento += (idx > 0) ? " / " : "";
                    documento += p.documento;
                });
            }
            return documento;
        },

        getCategoria(i) {
            return i.categoria;
        },

        //se calcula en el back
        getSexo(i) {
            return i.sexo;
        },
        getCondiscapacidad(i){
            return i.personas[0].con_discapacidad ? 'SI' : 'NO';
        },
        getColorEstado(i) {
            let cssClass = '';
            if (i.state == 'pago_pendiente') {
                cssClass = 'yellow lighten-1'
            }
            if (i.state == 'pago_realizado') {
                cssClass = 'green lighten-3'
            }
            return cssClass;
        }
    }

}

export default inscripcionTablaMixins;