import inscripcionAPI from '../../api/inscripcion'
import eventoAPI from '../../api/evento'


// initial state
const state = {
    eventos_visibles: [],
    eventos_visibles_pasados: [],
    eventos_visibles_pasados_page: 0,
    eventos_listado:[],
    organizadores:[],
    eventoActual:{}
}

// getters
const getters = {
     
}

// actions
const actions = {
    getAllEventos ({ commit }) {
        var prom ;
        if(this.state.eventos.eventos_visibles.length == 0){
            prom = inscripcionAPI.getEventosVisibles().then(eventos => {
                commit('setEventos', eventos)
            })
        }

        if(this.state.eventos.eventos_visibles_pasados.length == 0){
            prom = inscripcionAPI.getEventosVisiblesPasados().then(res => {
                commit('setEventosPasados', res)
            })
        }
        return prom ;
    },

    loadEventosPasadosNextPage({ commit }) {
        
        var page = this.state.eventos.eventos_visibles_pasados_page  + 1; 
        return inscripcionAPI.getEventosVisiblesPasados(page).then(res => {
                    commit('setEventosPasados', res)
                })
        
    },
    getEventoById({ commit }, eventoId){  
        return inscripcionAPI.getEventoById(eventoId).then( evento => {
            commit('setEventoActual', evento)
        })
    },
    getFrontEventoByIdAndCodigoAcceso({ commit }, {eventoId,codigoAcceso}){  
        return eventoAPI.getFrontEventoByIdAndCodigoAcceso(eventoId,codigoAcceso).then( evento => {
            commit('setEventoActual', evento)
        })
    },

    getFrontEventoById({ commit }, eventoId){  
        return eventoAPI.getFrontEventoById(eventoId).then( evento => {
            commit('setEventoActual', evento)
        })
    },
    getEventosListado({ commit }, params){
        return inscripcionAPI.getEventosListado(params).then( (eventos) => {
                commit('setEventosListado', eventos);
            })
    },
    getEventosOrganizadorListado({ commit }, params){
        return inscripcionAPI.getEventosOrganizadorListado(params).then( (eventos) => {
                commit('setEventosListado', eventos);
            })
    },
    getOrganizadores({ commit }, params){
        inscripcionAPI.getOrganizadores({},params).then( (organizadores) => {
            commit('setOrganizadores', organizadores);
        })
    },
}

// mutations
const mutations = {
    setEventos (state, eventos) {
        state.eventos_visibles = eventos
    },
    setEventosPasados (state, res) { 
        state.eventos_visibles_pasados = state.eventos_visibles_pasados.concat(res.data); 
        state.eventos_visibles_pasados_page = res.current_page;
    },
    setEventosListado(state, eventos) { 
        state.eventos_listado = eventos
    },
    setEventoActual (state, evento) {
        state.eventoActual = evento
    },
    setOrganizadores (state, organizadores) {
        state.organizadores = organizadores
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}