<template>

    <v-layout justify-space-around   align-content-center>
        <div xs5 mt10 align-self-center >
        
            <v-card align-self-center elevation="20" class="elevation-23  mt-3 mx-2" color="green" dark max-width="400">
                <v-card-title>
                    <v-icon large left>
                        check
                    </v-icon>
                    <span class="title">Inscripción Pagada</span>
                </v-card-title>
    
                <v-card-text class="headline font-weight-bold">
                    Su inscripción al evento ya se encuentra pagada.
                    <br/>
                    Muchas Gracias!
                </v-card-text>
    
                <v-card-actions>
                    <v-btn flat to="/">Ir al inicio</v-btn>                     
                </v-card-actions>
            </v-card>
        </div>
    </v-layout>
       
    </template>
    
    <script>
     
    
    export default {
        data: vm => ({
            loading: true,
    
        })
    }
    </script>