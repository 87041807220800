<style>
.flexcard {
    display: flex !important;
    flex-direction: column;
    justify-content:   space-between
}

.magnifier {
    position: absolute;
    top: 100px;
    right: 46%;
    display: block;
    color: orange;
}
</style>

<template>
    <v-card class="flexcard" :elevation="(pasado) ? 5 : 15" height="100%">

        <v-img @click="showBanner(val.banner_full_path)" :src=val.image_full_path height="300" style="max-height: 300px;">
        </v-img>
        <v-icon @click="showBanner(val.image_full_path)" size="50" class="magnifier">search</v-icon>

        <v-card-title px2 >
            <v-layout column>

                <v-layout>
                    <v-chip class="subheading" label dark color="#ff4900">{{ val.fecha_hora | formatDateStringShort
                    }}</v-chip>
                </v-layout>
                <h2 class="headline mb-0 font-italic">{{ val.nombre }} - {{ val.ciudad }}</h2>
                <h2 class="subheading mb8"><v-icon>list</v-icon>
                    <span :key="mod.id" v-for="(mod, idx) in val.modalidades">
                        {{ mod.nombre + ((idx != val.modalidades.length - 1) ? ' /' : '') }}
                    </span>
                </h2>
                <div>
                    <strong><v-icon>calendar_today</v-icon> Dia: </strong> {{ val.fecha_hora | formatDateString }}<br />
                    <strong><v-icon>alarm</v-icon> Hora: </strong> {{ val.fecha_hora | formatTime }}<br>
                    <strong><v-icon>place</v-icon></strong> {{ val.lugar }}<br>
                    <!-- <strong><v-icon>person</v-icon>Organiza</strong> {{ val.organizador.nombre }}<br> -->
                </div>
            </v-layout>
        </v-card-title>


        <v-card-actions>
            <v-layout column>
                <div v-if="!pasado">
                    <v-btn flex block dark :to="{ name: 'informacionEvento', params: { id: val.id } }" flat color="orange">Mas
                        info</v-btn>
                </div>
                <div v-if="!pasado">
                    <v-btn block dark v-if="val.inscripcion_abierta" :to="{ name: 'inscripcion', params: { id: val.id } }"
                        color="orange">Inscripción</v-btn>
                    <v-btn block v-if="inscripcionPasada(val)" disabled color="red lighten-3">Inscripción Finalizada</v-btn>
                    <v-btn block v-if="inscripcionProxima(val)" disabled color="orange  lighten-4">Inscripción
                        Proximamente</v-btn>
                </div>
                <div v-if="pasado">
                    <v-btn block :to="{ name: 'resultadosFotosEvento', params: { id: val.id } }" v-if="hasLinks(val)"
                        color="orange">Resultados y Fotos</v-btn>
                </div>
            </v-layout>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {

    data() {
        return {

        }
    },

    props: {
        val: Object,
        pasado: Boolean
    },

    created() {
    },

    methods: {

        showBanner(src) {
            this.$emit('showBanner', src);
        },

        inscripcionProxima(event) {
            return new Date() < new Date(event.fecha_inicio_inscripcion)
        },

        inscripcionPasada(event) {
            return new Date() > new Date(event.fin_inscripcion)
        },

        hasLinks(event) {
            const { event_result_links, photo_album_links } = event;
            const jsonLinks = event_result_links ? JSON.parse(event_result_links) : []
            const jsonPhotos = photo_album_links ? JSON.parse(photo_album_links) : []
            return (jsonLinks && jsonLinks.length > 0) || (jsonPhotos && jsonPhotos.length > 0)
        }

    }
}
</script>