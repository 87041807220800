import axios from 'axios'
import config from '../config'
import auth from './auth'


const validarCuponApi = (params) => {
    return axios.post("validar-cupon", params)
    .then(res=> res.data)
    .catch(res => { 
        if(res.response  && res.response.data){
            throw res.response.data.message;
        }else{
            throw "Ocurrió un error, no se puede aplicar el cupón.";
        }
    } ); 
}

export default validarCuponApi  