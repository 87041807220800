<template>
    <v-dialog v-model="dialogBorrar" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">Borrar Inscripcion</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    ¿Está seguro de borrar esta inscripcion?
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" flat @click="cerrarDialog">Cancelar</v-btn>
                <v-btn :disabled="loading" :loading="loading" color="red darken-1" flat @click="borrarInscripcion">
                    Borrar
                    <template v-slot:loader>
                        <span>Borrando...</span>
                    </template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "../../../api/inscripcion";
export default {
    props: {
        inscripcion: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            dialogBorrar: false,
            loading: false
        };
    },

    methods: {
        abrirDialog() {
            this.dialogBorrar = true;
        },
        cerrarDialog() {
            this.dialogBorrar = false;
        },

        async borrarInscripcion() {
            try {
                this.loading = true;
                const result = await api.borrarInscripcion(this.inscripcion.id)
                console.log(result)
                if (!result ||  result.status != 200 ) {
                    alert("Ocurrio un error")
                }
                this.dialogBorrar = false;
                this.$emit("refrescarDatos");
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    }
};
</script>