<template>
     
    <v-layout align-center justify-center>
        <v-flex>

        <v-dialog v-model="dialogOrganizador" fullscreen hide-overlay transition="dialog-bottom-transition">         
            <v-card>
               
            <v-toolbar dark color="orange">
                <v-btn icon dark @click="dialogOrganizador = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Organizador</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> 
                    <v-btn  dark flat :loading="loadingSubmit"   @click="submitOrganizador">Guardar</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            

            <v-card-text>
            <v-container grid-list-md>
                <v-form autocomplete="off"  >
                <v-layout wrap>
                <v-flex xs12 sm12 md12 > <h3  > Datos del Organizador</h3></v-flex>
                
                <v-flex xs12 sm6 md6>
                    <v-text-field 
                        v-model="organizador.nombre" 
                        label="Nombre organizador"
                        v-validate="'required'"
                        :error-messages="errors.collect('Nombre Organizador')"
                        item-text="Nombre Organizador"
                        data-vv-name="Nombre Organizador"
                        >
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field v-model="organizador.ciudad"
                        label="Ciudad"
                        v-validate="'required'"
                        :error-messages="errors.collect('Ciudad')"
                        item-text="Ciudad"
                        data-vv-name="Ciudad"
                    >
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field 
                    v-model="organizador.nombre_contacto" 
                    label="Nombre Contacto"
                    v-validate="'required'"
                    :error-messages="errors.collect('Nombre Contacto')"
                    item-text="Nombre Contacto"
                    data-vv-name="Nombre Contacto"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field 
                    v-model="organizador.apellido_contacto" 
                    label="Apellido Contacto"
                    v-validate="'required'"
                    :error-messages="errors.collect('Apellido Contacto')"
                    item-text="Apellido Contacto"
                    data-vv-name="Apellido Contacto"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field 
                    v-model="organizador.telefono_contacto"
                     label="Telefono Contacto"
                     v-validate="'required'"
                    :error-messages="errors.collect('Telefono Contacto')"
                    item-text="Telefono Contacto"
                    data-vv-name="Telefono Contacto"
                     ></v-text-field>
                </v-flex>
                 <v-flex xs12 sm6 md6>
                     
                </v-flex>
                
                 <v-flex xs12 sm12 md12> <h3  > Datos de Inicio se sesion</h3></v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field outline 
                    v-model="organizador.name" 
                    label="Username"
                    v-validate="'required|alpha|min:4'"
                    :error-messages="errors.collect('Username')"
                    item-text="Username"
                    data-vv-name="Username"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field outline 
                    v-model="organizador.email" 
                    label="Email"
                    v-validate="'required|email'"
                    :error-messages="errors.collect('Email')"
                    item-text="Email"
                    data-vv-name="Email"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="organizador.password"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        outline
                        :type="show1 ? 'text' : 'password'"                        
                        label="Password"
                        hint="Ingrese al menos 8 caracteres"
                        counter
                        @click:append="show1 = !show1"
                          v-validate="{ required: requiredPass }"
                        :error-messages="errors.collect('Password')"
                        item-text="Password"
                        data-vv-name="Password"
                    ></v-text-field>                  
                </v-flex>

                <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="organizador.password_confirm"                      
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                       outline
                        :type="show1 ? 'text' : 'password'"                         
                        label="Confirme password"
                        hint="Ingrese la misma password"
                        counter
                        @click:append="show1 = !show1"
                        v-validate="{ required: requiredPass  }"
                        :error-messages="errors.collect('Confirme password')"
                        item-text="Confirme password"
                        data-vv-name="Confirme password"
                    ></v-text-field>
                </v-flex>
                 <v-flex xs12 sm6 md6>
                    <v-flex md12>
                            <v-switch
                                label="Activo"
                                v-model="organizador.activo"
                                color="orange"
                            ></v-switch>
                        </v-flex>
                </v-flex>
                
                </v-layout>
                </v-form>
            </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
             
         
            </v-card-actions>
            </v-card>
        </v-dialog>
           
            <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
            <v-btn color="orange" rigth @click="openNewOrganizador"  >
                Crear Organizador
                <v-icon right small>library_add</v-icon>
            </v-btn> 

             
            <section>
                <v-card>
                    <v-card-title>
                      <h1>Organizadores</h1>    
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="pagination.search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :total-items="totalItems"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :items="organizadores" 
                    class="elevation-3"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-left">{{ props.item.id }}</td>
                        <td class="text-xs-left">{{ props.item.nombre }}</td>
                        <td class="text-xs-left">{{ props.item.ciudad }}</td>
                        <td class="text-xs-left">{{ props.item.user?props.item.user.name:'' }}</td>
                        <td class="text-xs-left">{{ props.item.user?props.item.user.email:'' }}</td>
                        <td class="text-xs-left">
                            <v-icon v-if="props.item.integrado_mp" alt="Si" small color="green" > brightness_1</v-icon>
                            <v-icon v-if="!props.item.integrado_mp" alt="No" small > brightness_1</v-icon>
                        </td>
                        <td class="text-xs-left">
                            <v-icon v-if="props.item.activo" alt="Si" small color="green" > brightness_1</v-icon>
                            <v-icon v-if="!props.item.activo" alt="NO" small > brightness_1</v-icon>
                        </td>
                        <td class="text-xs-left">
                            <v-btn title="Editar" flat  icon  @click="editarOrganizador(props.item)"  >
                                <v-icon alt="editar" small > edit</v-icon>
                            </v-btn> 
                        </td>
                    </template>
                    <template slot="no-data">
                        <v-alert :value="true" color="warning" icon="warning">
                          Sin Organizadores
                        </v-alert>
                    </template>
                </v-data-table>
                </v-card>
            </section>
        </v-flex>
    </v-layout>
</template>

<script>
import  VeeValidate,{Validator} from 'vee-validate'; 
import { mapState, mapActions } from 'vuex' 
import api from '../../api/inscripcion'
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
Validator.localize('es', es);  
Vue.use(VeeValidate);
 
export default {
    data () {
        return {
             
            show1:false,
            loadingSubmit:false,
            dialogOrganizador:false,
            organizador:{
                id:'',
                nombre:'',
                ciudad:'',
                name:'',
                nombre_contacto:'',
                apellido_contacto:'',
                telefono_contacto:'',
                password:'',
                password_confirm:'',
                activo:true
            },
            organizadores:[],
            totalItems:0,
            loading:false,
            steps:[                 
                {
                    text: 'Organizadores',
                    disabled: false,
                    href: '/#/admin/organizadores'
                }],
            pagination: {
                sortBy:'id',
                descending:true,
                search:null
            },
            headers: [
                {text:'Id', align:'center', sortable:true, value:'id'},
                {
                    text: 'Nombre Organizador',
                    align: 'left',
                    sortable: true,
                    value: 'nombre'
                },
                { text: 'Ciudad', value: 'ciudad' },
                { text: 'Usuario', sortable:false, value: 'name' },
                { text: 'Email', sortable:false, value: 'email' },
                { text: 'MercadoPago', value: 'integrado_mp' },
                { text: 'Activo', value: 'activo' },
                { text: 'Acciones', value: 'acciones' },
            ],
            
        }
    },
    computed:   {
        requiredPass:function(){
            return !this.organizador.id;
        }
    },
    created () {
         
    },
    watch:{
        pagination:{
            handler(){ 
                this.getDataFromApi() 
            },
            deep: true
        }
        
    },
    mounted(){
        //this.getDataFromApi()
    },
    methods:{
        closeDialogOrganizador(){
            this.dialogOrganizador = false;
            this.organizador = this.getCleanOrganizador()
        },
        editarOrganizador(organizador){
            this.dialogOrganizador = true;
             
            this.organizador = {
                id:organizador.id,
                nombre:organizador.nombre,
                ciudad:organizador.ciudad,
                activo:organizador.activo,
                name:organizador.user?organizador.user.name:'',
                nombre_contacto:organizador.nombre_contacto,
                apellido_contacto:organizador.apellido_contacto,
                telefono_contacto:organizador.telefono_contacto,
                email:organizador.user?organizador.user.email:'',
                email_contacto:organizador.email_contacto,
            }
        },

        openNewOrganizador(){
            this.dialogOrganizador = true;
             
            this.organizador = {
                id:null,
                nombre:'',
                ciudad:'',
                activo:true,
                name:'',
                nombre_contacto:'',
                apellido_contacto:'',
                telefono_contacto:'',
                email:'',
                email_contacto:'',
            }
        },
        getDataFromApi(){
            this.loading = true;
            api.getOrganizadoresListado(this.pagination).then((res)=>{
                this.totalItems = res.total;
                this.organizadores = res.data;                 
                this.loading = false;
            })
        },

        getCleanOrganizador(){
            return {
                    nombre:'',
                    ciudad:'',
                    name:'',
                    nombre_contacto:'',
                    apellido_contacto:'',
                    telefono_contacto:'',
                    password:'',
                    password_confirm:'',
                    activo:true
                    }
        },
        submitOrganizador(){
            this.loadingSubmit = true;
            this.$validator.validateAll().then((isOk)=>{ 
                if(isOk){}
                
                    if(this.organizador.id){
                        api.actualizarOrganizador(this.organizador.id,this.organizador).then((res)=>{                 
                        if(res.status == 200){
                            this.$store.commit('app/SET_MESSAGE',res.message );
                            this.$store.commit('app/SET_SHOW', true);
                            this.dialogOrganizador = false;
                            this.organizador = this.getCleanOrganizador()
                        }
                        this.getDataFromApi();
                        this.loadingSubmit = false;
                    })
                    }else{
                    api.crearOrganizador(this.organizador).then((res)=>{                 
                            if(res.status == 201){
                                this.$store.commit('app/SET_MESSAGE',res.message );
                                this.$store.commit('app/SET_SHOW', true);
                                this.dialogOrganizador = false;
                                this.organizador = this.getCleanOrganizador()
                            }
                            this.getDataFromApi();
                            this.loadingSubmit = false;
                        }) 
                    }
            });
            
        }
    }

}
</script>                   
