import axios from 'axios'; 
import config from '../config';
import Vue from 'vue'
import store from '../store';
import router from '../router'
export default{ 

    login: function(credentials){
        return  axios.post( config.api_login_url, {
            email:credentials.email,
            password:credentials.password
        }).then(res=>{   
            //sessionStorage.setItem('access_token', res.data.access_token);           
            //sessionStorage.setItem('expires_in', res.data.expires_in);
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
            return axios.post(config.api_logged_user_url).then(result => {
                let data = result.data;
                data.access_token = res.data.access_token;
                data.expires_in = res.data.expires_in;
                data.roles = res.data.roles;
                data.user_info = res.data.user_info;
                return data;
            })
        })
    },

    validateRoles(next,roles){
        if(roles){
            const rolesArray = roles.split('|');

            const found = rolesArray.find(role => {
                return store.getters['auth/getRoles'].includes(role)
            });

            if(found){
                next();
            }else{
                next('/login');
            }            
        }else{
            next();
        }     
    },


    validateAuth(next,roles){
        //pasar esto a algo desaclopado
        let isLoggedIn = store.getters['auth/isLoggedIn'];
        let expires_at = store.getters['auth/expiresAt'];
        if(isLoggedIn && ((new Date().getTime())/1000) < expires_at ){
            this.validateRoles(next,roles);            
        }else{ 
            this.logout();
        }
    },

    logout(){
        Vue.axios.defaults.headers.common['Authorization'] = null;
        store.dispatch('auth/logout',{}).then(()=>{
            router.push('/login')
        })
    }
}
