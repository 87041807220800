import axios from 'axios'
import auth from './auth'
export default {
    crearCupon(parameters){
        return axios.post("cupon-descuento", parameters)
            .then((res) => {return res.data})
            .catch(res=>{                 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }else{
                        throw res;                
                    }
                }else{
                    throw res;
                }
            }); 
    },
    getCuponesEvento(idEvento){
        return axios.get("cupon-descuento/evento/"+idEvento+"/lista")
            .then((res) => {return res.data})
            .catch(res=>{                 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }else{
                        throw res;                
                    }
                }else{
                    throw res;
                }
            }); 
    },
    updateActivo(idCupon, activo){
        return axios.put("cupon-descuento/"+idCupon+"/activo", {activo: activo})
            .then((res) => {return res.data})
            .catch(res=>{                 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }else{
                        throw res;                
                    }
                }else{
                    throw res;
                }
            }); 
    },
    deleteCupon(idCupon){
        return axios.delete("cupon-descuento/"+idCupon)
            .then((res) => {return res.data})
            .catch(res=>{                 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }else{
                        throw res;                
                    }
                }else{
                    throw res;
                }
            }); 
    }
}
