<style>
@keyframes fillBox {
    0% {
        border-radius: 0 0 50% 50%;
        width: 0;
        height: 0;
    }

    50% {
        border-radius: 0 0 50% 50%;
        width: 100%;
        height: 50%;
    }

    100% {
        border-radius: 0;
        width: 100%;
        height: 100%;
    }
}

.animated-alert {     
    overflow: hidden;
}
</style>
<template>


    <v-layout align-center justify-center>

        <div v-if="loading" class="my-3 xs12 sm4 ">
            <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
        </div>

        <v-flex v-if="!loading">
            <v-toolbar dark color="secondary">
                <v-toolbar-title>Inscripcion a: <span v-if="evento">{{ evento.nombre }}</span> -
                    <div>Fecha : {{ evento.fecha_hora | formatDateString }}</div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>

            <v-container>
                <v-layout align-center justify-center>
                    <v-flex>

                        <v-alert :value="resultado_pendiente" type="warning">
                            Su pago esta pendiente.
                            Cualquier duda, comuniquese con Finish Time
                        </v-alert>


                        <v-alert :value="resultado_ok" type="success" :class="{ 'animated-alert': resultado_ok }">
                            <p style="margin-bottom: 0;" class="headline">Inscripción Registrada correctamente.</p>
                        </v-alert>

                        <v-alert :value="resultado_error" type="error">
                            Ocurrió un error!
                        </v-alert>

                        <v-card>


                            <v-card-text primary-title>
                                <v-layout row wrap justify-center>
                                    <v-flex xs12 md6>
                                        <div v-if="resultado_ok" v-html="mensaje_inscripcion_correcta"></div>

                                    </v-flex>
                                    <v-flex xs12 md6>
                                        <v-layout row wrap justify-center>
                                            <v-flex xs12 sm8 pa-3 style="background-color: rgba(255, 157, 0, 0.182);">


                                                <v-layout row wrap justify-space-between>
                                                    <v-flex xs6>Valor</v-flex>
                                                    <v-flex xs6 text-xs-right> 
                                                        <span :class="{'tachado': datos_pago.codigo}" > $ {{ datos_pago.valor_modalidad }}</span>
                                                    </v-flex>
                                                </v-layout>

                                                <v-layout v-if="datos_pago.codigo" row wrap justify-space-between>
                                                    <v-flex xs6>Valor con descuento</v-flex>
                                                    <v-flex xs6 text-xs-right> 
                                                        <span> $ {{ datos_pago.valor }}</span>
                                                    </v-flex>
                                                </v-layout>

                                                
                                                <!-- <v-layout v-if="successCupon" row wrap justify-center>
                                            <v-flex xs6>Valor con descuento:</v-flex>
                                            <v-flex xs6 text-xs-right>$ {{ valorInscripcion }}</v-flex>
                                        </v-layout> -->

                                                <v-layout row wrap justify-space-between
                                                    v-if="datos_pago.costo_servicio > 0">
                                                    <v-flex xs6>Costo servicio FinishTime</v-flex>
                                                    <v-flex xs6 text-xs-right> $ {{ datos_pago.costo_servicio }}
                                                    </v-flex>
                                                </v-layout>
                                                
                                                <v-layout v-if="datos_pago.codigo" row wrap justify-space-between >
                                                    <v-flex xs6>Cupón aplicado</v-flex>
                                                    <v-flex xs6 text-xs-right> <span>{{ datos_pago.codigo }}
                                                        </span></v-flex>
                                                </v-layout>

                                                <v-layout row wrap justify-center>
                                                    <v-flex xs6 class="title font-weight-black">Total</v-flex>
                                                    <v-flex xs6 text-xs-right class="title font-weight-bold">$ {{
                                                        datos_pago.total }}</v-flex>
                                                </v-layout>

                                            </v-flex>
                                        </v-layout>
                                    </v-flex>


                                </v-layout>
                            </v-card-text>

                        </v-card>


                        <v-btn v-if="hasRedirect" block :to="hasRedirect" color="secondary">
                            Volver
                        </v-btn>
                        <v-btn v-else large block to="/" color="secondary">
                            Inicio
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>

        </v-flex>
    </v-layout>
</template>

<script>

import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import inscripcion from '../../../api/inscripcion';


export default {

    data: vm => ({
        loading: true,
        mensaje_inscripcion_correcta: '',
        datos_pago: {
            valor: 0,
            costo_servicio: 0,
            total: 0,
            fecha_vencimiento: ''
        }
    }),
    props: {
        source: String
    },
    computed: mapState({
        evento: state => state.eventos.eventoActual,
        modalidades: state => state.eventos.eventoActual.modalidades,
        resultado_ok: function () {
            return this.$router.currentRoute.name == 'resultado_ok';
        },
        hasRedirect: function () {
            return this.$router.currentRoute.query.next;
        }
    }),

    created() {
        scroll(0, 0);
        let eventoId = this.$route.params.evento_id;
        this.loading = true;
        console.info('App currentRoute:', this.$router.currentRoute)

        this.$store.dispatch('eventos/getEventoById', eventoId).then(res => {
            this.loading = false;
        })

        debugger;
        if (this.$router.currentRoute.name == 'resultado_ok') {
            const { evento_id, inscripcion_id } = this.$route.params;
            inscripcion.getMensajeInscripcion(evento_id, inscripcion_id).then(res => {
                this.mensaje_inscripcion_correcta = res.data.mensaje;
                this.datos_pago = res.data.datos_pago
            })

        }


    },

    watch: {

    },
    methods: {

    }
}
</script>