// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'tiptap-vuetify/dist/main.css'
import store from './store'
import Qs from 'qs'
import axios from 'axios'
import VueAxios from 'vue-axios'
import config from './config'
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-vue';
import UploadImage from 'vue-upload-image';

Vue.component('upload-image', UploadImage)
Vue.use( CKEditor );
Vue.use(Vuetify)
Vue.use(VueAxios,axios)


Vue.config.productionTip = false
Vue.axios.defaults.paramsSerializer = params => {
    return Qs.stringify(params, {arrayFormat: 'brackets'});
};
Vue.axios.defaults.baseURL = config.api_base_url;
moment.locale('es');
/*
* Este metodo interceptor adjunta el JWT en el header antes de cada request del AXIOS
*/
Vue.axios.interceptors.request.use(function(config) { 
    const token = sessionStorage.getItem('access_token') ; 
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});
//Vue.axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatDateStringShort', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMMM')
    }
});

Vue.filter('formatDateString', function(value) {
    if (value) {
        return moment(String(value)).format('LL')
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
    }
});

Vue.filter('formatDateTimeString', function(value) {
    if (value) {
        return moment(String(value)).format('LL HH:mm')
    }
});

Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
});

import wysiwyg from "vue-wysiwyg";
 Vue.use(wysiwyg, {}); // config is optional. more below


/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App,UploadImage },
     template: '<App/>'
})
