<template>
    <v-layout v-if="persona" wrap>
        <v-flex xs12 sm12 md12>
            <v-text-field v-model="persona.documento" label="Documento"></v-text-field>
        </v-flex>
        <v-flex xs12 pa-1 sm6 md6>
            <v-text-field v-model="persona.nombre" label="Nombre"></v-text-field>
        </v-flex>
        <v-flex xs12 pa-1 sm6 md6>
            <v-text-field v-model="persona.apellido" label="Apellido"></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12>
            <v-text-field v-model="persona.sexo" label="Sexo"></v-text-field>
        </v-flex>

        <v-flex xs12 pa-2  sm4 md4>
            <v-text-field   v-model="persona.fecha_nacimiento" label="Fecha Nacimiento"></v-text-field>
        </v-flex>

        <v-flex xs12 pa-2 sm6 md6>
            <v-text-field v-model="persona.provincia" label="Provicia"></v-text-field>
        </v-flex>

        <v-flex xs12 pa-2 sm6 md6>
            <v-text-field v-model="persona.ciudad" label="Ciudad"></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12>
            <v-text-field v-model="persona.domicilio" label="Domicilio"></v-text-field>
        </v-flex>
        <v-flex xs12 pa-2 sm6 md6>
            <v-text-field v-model="persona.telefono" label="Telefono"></v-text-field>
        </v-flex>
        <v-flex xs12 pa-2 sm6 md6>
            <v-text-field v-model="persona.email" label="Email"></v-text-field>
        </v-flex>

        
        <v-flex xs12 pa-2 sm6 md6>
            <v-select data-vv-name="talle" 
                locale="es" id="talle" 
                v-model="persona.talle"   
                item-text="label" item-value="abbr" :items="talles" label="Talle Remera">
            </v-select>
        </v-flex>
    
        <v-flex xs12 pa-2 sm6 md6>
            <v-text-field v-model="persona.team" label="Team"></v-text-field>
        </v-flex>

        <v-flex pa-1 sm6 md6>
            <v-checkbox 
                v-model="persona.con_discapacidad"                 
                name="con_discapacidad"
                label="Persona con Certificado Único de Discapacidad"></v-checkbox>
         </v-flex>

    </v-layout>
</template>


<script>
 
export default {
    props: ['persona'],
    data () {
        return {
            diaNac:'',
            mesNac:'',
            anioNac:'',
            dialogAbierto:false,
        }
    },
    computed: {
        talles: ( ) => {
            const t = [
                { abbr:'XS',label: 'XS'},
                { abbr: 'S', label: 'S' },
                { abbr: 'M', label: 'M' },
                { abbr: 'L', label: 'L' },
                { abbr: 'XL', label: 'XL' },      
                { abbr: 'XXL', label: 'XXL' }     
            ];            
            return t;
        },
    },
    watch:{
        persona: function(newVal, oldVal){
            [this.anioNac, this.mesNac, this.diaNac] = newVal.fecha_nacimiento.split('-')             
        }        
    }
}
</script>                   
