<template>
    <span v-if="isAuthorized">
        <slot></slot>
    </span>
    <span v-else></span>
  </template>
  
  <script>  
  import Vuex from 'vuex'
  export default {
    props: ['roles'],
    data() {
      return {
        isAuthorized: false,
      };
    },
    computed: {
        ...Vuex.mapGetters("auth",["isLoggedIn","isAdmin","isOrganizador", "nombreUsuario", "getRoles"])
    },
    async created() {
      if ( (this.isLoggedIn && this.isAdmin) ||         
      (this.isLoggedIn && this.roles.some(item => this.getRoles.includes(item)))
         ) {
        this.isAuthorized = true;
      } 
    },
  };
  </script>