<template>
    <v-dialog v-model="openDialogExisteInscripcion" max-width="600" persistent>
        <v-card>
            <v-card-title class="headline">Existe inscripcion</v-card-title>

            <v-card-text>
                <v-alert v-show="existe_inscripcion_msg" :type="alertClass">
                    {{ existe_inscripcion_msg }}
                </v-alert>

                <div v-show="mercadoPagoInitPoint" > 

                    <v-flex xs12 subheading>
                        <v-btn :href="mercadoPagoInitPoint" block color="#009ee3" dark>Pagar En MercadoPago</v-btn>
                    </v-flex>
                    <v-flex xs12 subheading>
                        <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/575X40.jpg"
                            title="MercadoPago - Medios de pago" alt="MercadoPago - Medios de pago" width="575" height="40" />
                    </v-flex>
                </div>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="secondary darken-1" flat="flat" @click="closeDialog">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


    export default {
        props: {
            openDialogExisteInscripcion: {
                type: Boolean,
                default: false
            },
            existe_inscripcion_msg: {
                type: String,
                default: ''
            },
            inscripcion_state: {
                type: String,
                default: ''
            },
            mercadoPagoInitPoint: {
                type: String,
                default: null
            },
        },
        data() {
            return {

             }
        },
        computed:{
            alertClass() {
                if (this.inscripcion_state == 'pago_realizado' || this.inscripcion_state == 'pago_bonificado') {
                    return 'success';
                } else {
                    return 'warning';
                }
            }
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialogExisteInscripcion');
            },
        }
    }
</script>