<template>
    <v-dialog v-model="dialogAbierto" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="60%">


        <v-card v-if="inscripcion && inscripcion.id">
            <v-card-title>
                <span class="headline">Ver Inscripción {{ inscripcion.id }} </span>
            </v-card-title>

            <v-card-text>

                <v-container elevation-3 py-3 px-1>
                    <v-layout row wrap>
                        <v-flex xs12 mb-3>
                            <h3 class="headline ">{{ (inscripcion.personas.length > 1 ? 'Equipo' : 'Participante') }}</h3>
                            <span class="subheading">
                                {{ inscripcion.personas.length > 1 ? inscripcion.nombre : inscripcion.personas[0].nombre + ' '
                                    + inscripcion.personas[0].apellido + ' (' + inscripcion.personas[0].documento + ')' }}
                            </span>
                            <v-divider></v-divider>
                        </v-flex>

                        <v-flex xs6 sm6 md4>
                            <h3 class="subheading  font-weight-medium ">Modalidad</h3>
                            <span>
                                {{ inscripcion.modalidad.nombre }}
                            </span>
                        </v-flex>

                        <v-flex xs6 sm6 md4>
                            <h3 class="subheading font-weight-medium">Categoria</h3>
                            <span>
                                {{ inscripcion.categoria }}
                            </span>
                        </v-flex>



                        <v-flex xs6 sm6 md4>
                            <h3 class="subheading font-weight-medium">Fecha Inscripción</h3>
                            <span>
                                {{ inscripcion.created_at | formatDate }}
                            </span>
                        </v-flex>


                        <v-flex xs6 sm6 md4>
                            <h3 class="subheading font-weight-medium">Ultima Modificación</h3>
                            <span>
                                {{ inscripcion.updated_at | formatDate }}
                            </span>
                        </v-flex>


                        <v-flex xs12 sm6 md4>
                            <h3 class="subheading font-weight-medium">Estado</h3>
                            <span>
                                <LabelEstado :state="inscripcion.state"></LabelEstado>
                            </span>
                        </v-flex>


                        <v-flex xs12 sm6 md4 v-if="inscripcion.numero">
                            <h3 class="subheading font-weight-medium">Nro / ChipId</h3>
                            <span>
                               
                                <v-btn depressed small :color="'#fedfb3'">
                                    {{ inscripcion.numero }} / {{ inscripcion.chip_id }}
                                </v-btn>
                            </span>
                        </v-flex>

                    </v-layout>
                </v-container>


                <v-tabs v-model="model" slider-color="black">
                    <v-tab :key="'persona_tab'" :href="`#tab-personas`">
                        Datos Personales
                    </v-tab>
                    <v-tab :key="'pagos_tab'" :href="`#tab-pagos`">
                        Pagos
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="model">
                    <v-tab-item :key="'persona'" :value="`tab-personas`">
                        <v-container>
                            <v-layout v-for="(persona, index) in inscripcion.personas" row wrap>
                                
                                <v-flex xs12 v-if="inscripcion.personas.length > 1">
                                    <h3 class="headline font-weight-medium ">Participante {{ index + 1 }}</h3>                                    
                                </v-flex>

                                <v-flex v-for="(key, index) in Object.keys(persona).filter(item => item != 'campo_custom_values') " xs6 sm6 md4 mt-3>

                                    <h3 class="subheading  font-weight-medium text-capitalize">{{key.replace('_', ' ')}}</h3>
                                    
                                    <span>
                                        {{ persona[key] }}
                                    </span>

                                </v-flex>      
                             
                               <v-flex xs12 >
                                    <h3 class="headline font-weight-medium ">Datos Adicionales</h3>                                    
                                </v-flex>

                                <v-flex v-for="(key, index) in  persona.campo_custom_values" xs6 sm6 md4 mt-3>

                                <h3 class="subheading  font-weight-medium text-capitalize">{{key.label}}</h3>

                                <span>
                                    {{ key.value}}
                                </span>

                                </v-flex> 
                                                                
                            </v-layout>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item :key="'pagos'" :value="`tab-pagos`">
                        <v-container>
                            <p>Proximamente informacion de pagos registrados</p>
                        </v-container>
                    </v-tab-item>

                </v-tabs-items>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary  " @click="cerrarDialog">Cerrar</v-btn>
            </v-card-actions>
        </v-card>



    </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import api from "../../../api/inscripcion";
 import CanAccess from "../../utils/CanAccess.vue";
import LabelEstado from "../../common/LabelEstado.vue";
 export default {
    props: {},
    components: { CanAccess: CanAccess, LabelEstado: LabelEstado },
    data() {
        return {
            model: "tab-personas",
            dialogAbierto: false,
            inscripcion: {}
        };
    },
    computed: {

    },
    created() { },
    watch: {

    },
    mounted() { },
    methods: {
        abrirDialog(inscripcion) {

            api.getInscripcionById(inscripcion.id).then((response) => {
                console.log(response)
                this.inscripcion = response.results;
                this.dialogAbierto = true;
            }).catch((error) => {
                console.log(error);
            });

           
        },
        cerrarDialog() {
            this.dialogAbierto = false;
        } 
    }
};
</script>
