<style>
.compact {
    padding: 0px 10px !important;
    line-height: 16px !important;
    margin: 0px !important;
    font-size: 15px !important;
    height: 15px !important; 
}
</style>
<template>
    <v-layout row wrap justify-center>  
        <v-flex xs12 md6>
            <v-data-table v-model="selected" :headers="headers" :total-items="totalItems" :pagination.sync="pagination"
                :loading="loading" :items="mappedItems" class="elevation-3">
                <template slot="items" slot-scope="props">
                    <tr :class="{ 'white': props.item.color == 'white', 'grey lighten-3': props.item.color != 'white'   }">
                        <template v-if="props.item.dummy">
                            <td colspan="6" style="border-top: 1px solid #CCC; font-size: 16px; font-weight: bold;" class=" text-xs-left">Categoria: {{ props.item.categoria }}</td>
                        </template>
                        <template v-else>
                            <td class="compact text-xs-left hidden-sm-and-down" style="font-size: 16px ">{{ getDocumento(props.item) }}</td>
                            <td class="compact text-xs-center" >{{ getApellido(props.item) }}, {{ getNombre(props.item) }}</td>
                            <td class="compact text-xs-center" >{{ props.item.modalidad.nombre }}</td>
                            <td class="compact text-xs-center" >{{ props.item.categoria_anio_calendario }}</td>    
                            <td class="compact text-xs-center">
                                <v-text-field   height="15"  type="text"  v-model="props.item.numero"  />
                            </td>
                            <td width="10px">
                                <v-icon v-if="hayduplicado(props.item.numero)" color="red lighten-1">warning</v-icon>

                            </td>
                        </template>
                    </tr>
                </template>
                <template slot="no-data">
                    <v-alert :value="true" color="warning" icon="sentiment_dissatisfied">
                        <span v-if="!loading">No hay inscripciones que coincidan con la búsqueda.</span>
                        <span v-if="loading">Cargando...</span>
                    </v-alert>
                </template>
                <template slot="pageText" slot-scope="props">
                    Inscriptos desde {{ props.pageStart }} a {{ props.pageStop }} de {{
                        props.itemsLength }}
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import inscripcionTablaMixins from '@/mixins/utils';
 
export default {
    data() {
        return {
            pagination: {
                descending: false,
                page: 1,
                rowsPerPage: -1,
                sortBy: "id",
            },
            selected: [],
            mappedItems: [],
        }
    },

    mixins: [inscripcionTablaMixins],
    
    props: {
        loading: Boolean,
        items: Array,
        totalItems: Number,
        evento: Object,
    },

    watch: {
        items: {
            handler() {
                 let tmpItems = [];
        
                let color = 'white';
                for(let i = 0; i < this.items.length; i++){
                    if(i>0){
                        if(this.items[i].categoria_anio_calendario != this.items[i-1].categoria_anio_calendario   ){
                            if(color == 'white'){
                                color = 'grey lighten-3';
                            }else{
                                color = 'white';
                            }                    
                            tmpItems.push({dummy:true, categoria: this.items[i].categoria_anio_calendario, color: color});
                        }
                    }
                    tmpItems.push({...this.items[i], color: color});
                }
                this.mappedItems = tmpItems;
            }
        },
      
        pagination: {
            handler() {
                this.$emit('paginationChange', this.pagination);
            },
            deep: true,
        },
    },

    methods: {    
        getMappedItems() {
            return this.mappedItems.filter(item => !item.dummy);
        },
        hayduplicado(numero){
            if(!numero || numero == ''){
                return '';
            }
            let duplicados = this.getMappedItems().filter(item => item.numero == numero);
            if(duplicados.length > 1){
                return 'Duplicado';
            }
            return '';
        }
    },
    computed: {
     
        headers() {
            let headers = [
                    {  text: 'Doc',  class: 'hidden-sm-and-down', align: 'left', sortable: false, value: 'documento' ,   width: '30px' },
                    { text: 'Nombre/Apellido', align: 'center', sortable: false, value: 'nombre' },
                    { text: 'Modalidad',  align: 'center', sortable: false, value: 'modalidad_id' },
                    { text: 'Categoria',   align: 'center', sortable: false },
                    { text: 'Número', align: 'center', sortable: false, value: 'numero' },
                    { text: 'dup', align: 'center', sortable: false, value: 'dup' },
                ];
            return headers;
        }
    }
}
</script>