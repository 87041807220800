<template>
    <v-container fluid grid-list-xl>
        <v-layout text-xs-center center row wrap>
            <v-flex xs12 sm12>
                <h1>Panel de Inicio</h1>
            </v-flex>
        </v-layout>

        <v-layout class="hidden-sm-and-up" text-xs-center center row wrap>
            <v-flex xs12>
                <v-btn v-if="isAdmin" :to="{ name: 'eventoLista' }">Eventos</v-btn>
                <v-btn v-if="isOrganizador" :to="{ name: 'eventoListaOrganizador' }">Mis Eventos</v-btn>
                <v-btn v-if="isAdmin" :to="{ name: 'organizadoresLista' }">Organizadores</v-btn>
            </v-flex>
        </v-layout>


        <v-layout row wrap>
            <v-flex xs12 sm12 md6>
                <h3 class="headline  my-2   text-xs-center ">Próximos eventos</h3>
                <v-card>
                    <table class="v-datatable v-table v-datatable--select-all theme--light">
                        <thead>
                            <tr>
                                <th>Evento</th>
                                <th class="hidden-sm-and-down">Ciudad</th>
                                <th>Fecha</th>
                                <th>Inscriptos</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for=" e in eventos">
                                <td class="text-xs-center">{{ e.nombre }}</td>
                                <td class="text-xs-center  hidden-sm-and-down">{{ e.ciudad }}</td>
                                <td class="text-xs-center">{{ e.fecha_hora | formatDateTime }}</td>
                                <td class="text-xs-center">
                                    
                                    <v-btn outline color="primary" small type="secondary"  :to="{ name: 'event', params: { id: e.id } }">{{ e.total_personas_inscriptos }} Inscriptos</v-btn>    
                                </td>
                                 
                            </tr>
                        </tbody>
                    </table>
                </v-card>
            </v-flex>

            <v-flex xs12 sm12 md6>
                <h3 class="headline my-2   text-xs-center">Últimos inscriptos </h3>
                <v-card>

                    <table class="v-datatable v-table v-datatable--select-all theme--light">
                        <thead>
                            <tr>
                                <th>Nombre/Apellido</th>
                                <th>Fecha Inscr</th>
                                <th>Evento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for=" i in inscripciones">
                                <td class="text-xs-center">{{ i.personas[0].nombre }}, {{ i.personas[0].apellido }}</td>
                                <td class="text-xs-center">{{ i.created_at | formatDateTime }}</td>
                                <td class="text-xs-center">{{ i.modalidad.evento.nombre }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Vuex from 'vuex'
import api from '../../api/inscripcion'

export default {
    data() {
        return {
            inscripciones: [],
            eventos: [],
            contenido: "",
            total: "-"
        }
    },
    computed: {
        ...Vuex.mapGetters("auth", ["isLoggedIn", "isAdmin", "isOrganizador", "nombreUsuario"]),
        ...Vuex.mapState("auth", ["user.name"]),
    },
    mounted() {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi() {
            this.loading = true;
            if (this.isAdmin) {
                this.getDataAdmin()
            } else {
                this.getDataOrganizador()
            }
        },

        getDataOrganizador() {
            api.getMisUltimasInscripciones().then((res) => {
                this.inscripciones = res;
            }).finally(() => {
                this.loading = false;
            })

            api.getMisProximosEventos().then((res) => {
                console.log(res);
                this.eventos = res.data;
            }).finally(() => {
                this.loading = false;
            })
        },

        getDataAdmin() {
            api.getUltimasInscripciones().then((res) => {
                this.inscripciones = res;
            }).finally(() => {
                this.loading = false;
            })

            api.getProximosEventos().then((res) => {
                console.log(res);
                this.eventos = res.data;
            }).finally(() => {
                this.loading = false;
            })
        },        
    }
}
</script>                   
