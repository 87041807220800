export default { 
    api_login_url: 'auth/login',
    api_logged_user_url: 'auth/me',
    api_eventos_visibles_url:'eventos-visibles',
    api_eventos_visibles_pasados_url:'eventos-visibles-pasados',
    api_eventos_index:'eventos',
    api_eventos_list_organizador:'organizador/eventos',
    api_eventos_show:'eventos/ID',
    api_eventos_get:'v1/eventos/ID',
    api_eventos_privado_get:'v1/eventos/ID/CODIGO_ACCESO',
    api_eventos_subir_imagen:'eventos/ID/subir-imagen',
    api_eventos_borrar_imagen:'eventos/ID/borrar-imagen/IDIMG',

    api_eventos_create:'eventos',
    api_organizadores_index:'organizadores',
    api_organizadores_list:'organizadores/todos',
    api_organizadores_create:'organizadores',
    api_organizadores_update:'organizadores/ID',
    api_inscripcion_create:'inscripciones',
    //api_inscripcion_update:'inscripciones/ID',
    api_inscripcion_delete:'inscripciones/ID',
    api_eventos_update:'eventos/ID',
    api_inscriptos_evento:'eventos/ID/inscriptos',
    api_inscriptos_evento_export:'eventos/ID/exportar-inscripciones',
    api_participantes_evento_export:'eventos/ID/exportar-participantes',
    api_verificar_existencia_dni_evento:'eventos/ID/verificar_existencia',
    api_ultimas_incripciones_url:'inscripciones/ultimas',
    api_proximos_eventos_url:'dash/eventos/proximos',
    api_proximos_eventos_organizador_url:'organizador/eventos/proximos',
    api_ultimas_incripciones_organizador_url:'organizador/inscripciones/ultimas',
    api_inscriptos_evento_organizador_url:'organizador/eventos/ID/inscriptos',
    api_perfil_url:'perfil',
    api_send_emails_url:'send-email'
}
