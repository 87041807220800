<template>
    <div>
      <h1>Página no encontrada</h1>
      <p>Lo sentimos, la página que está buscando no existe.</p>
    </div>
  </template>
  
  <script>
  export default {
    // Código del componente NotFound
  }
  </script>
  
  <style scoped>
  /* Estilos para NotFound */
  </style>
  