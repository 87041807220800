<template>

<v-layout justify-space-around   align-content-center>
    <div xs5 mt10 align-self-center >
    
        <v-card align-self-center elevation="20" class="elevation-23  mt-3 mx-2" color="#ff5252" dark max-width="400">
            <v-card-title>
                <v-icon large left>
                    error
                </v-icon>
                <span class="title">Link Invalido</span>
            </v-card-title>

            <v-card-text class="headline font-weight-bold">
                El link de pago es inválido, comunicate con el organizador para validar el estado de tu inscripcion.
            </v-card-text>

            <v-card-actions>
                <v-btn flat to="/"  >Ir al inicio</v-btn>
                 
            </v-card-actions>
        </v-card>
    </div>
</v-layout>
   
</template>

<script>

import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'



export default {


    data: vm => ({
        loading: true,

    }),
    props: {
        source: String
    },
    computed: mapState({

    }),



    watch: {

    },
    methods: {

    }
}
</script>