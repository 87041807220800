<template>
    <v-container align-center align-content-center>
        <v-layout justify-center>
            <v-flex xs12 md8>
                <v-form autocomplete="off">
                    <h2>Adjuntar Links de Clasificacion y album de fotos</h2>

                    <div>
                        <h3>Links clasificación</h3>
                        <div>
                            <v-layout :key="'link' + index" v-for="(link, index) in links" row wrap
                                style="border: 1px solid #ccc" my-2>
                                <v-flex pa-3 xs12 md5>
                                    <v-text-field v-model="links[index].textoBoton" label="Texto del Link"
                                        v-validate="'required'" :error-messages="errors.collect('textoLink_' + index)"
                                        :name="'textoLink_' + index" placeholder="Ingrese el texto del link"></v-text-field>
                                </v-flex>
                                <v-flex pa-3 xs12 md5>
                                    <v-text-field v-model="links[index].url" :name="'url_' + index"
                                        :error-messages="errors.collect('url_' + index)" v-validate="'required'"
                                        label="Url del archivo" placeholder="Ingrese la url del archivo">
                                    </v-text-field>
                                </v-flex>

                                <v-flex pt-4 xs12 md2>
                                    <v-btn @click="removeItem(index)"> Eliminar </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout justify-center>
                                <v-btn outline color="secondary" @click="addFileSlot()">
                                    Agregar Resultado
                                </v-btn>
                            </v-layout>
                        </div>
                    </div>

                    <div>
                        <h3>Link a album de fotos</h3>
                        <div>
                            <v-layout :key="'link' + index" v-for="(link, index) in photosLinks" row wrap
                                style="border: 1px solid #ccc" my-2>
                                <v-flex pa-3 xs12 md5>
                                    <v-text-field :name="'photo_textoBoton_' + index"
                                        :error-messages="errors.collect('photo_textoBoton_' + index)"
                                        v-model="photosLinks[index].textoBoton" label="Texto del Link"
                                        v-validate="'required'" placeholder="Ingrese el texto del link"></v-text-field>
                                </v-flex>
                                <v-flex pa-3 xs12 md5>

                                    <v-text-field v-model="photosLinks[index].url" :name="'photo_url_' + index"
                                        :error-messages="errors.collect('photo_url_' + index)" label="Url del album"
                                        v-validate="'required'" placeholder="Ingrese la url del album">
                                    </v-text-field>
                                </v-flex>


                            </v-layout>
                            <v-layout justify-center>
                            
                            </v-layout>
                        </div>
                    </div>

                    <v-layout justify-end>
                        <v-btn large color="secondary" @click="volver">
                            Cancelar
                        </v-btn>
                        <v-btn large color="orange" @click="saveLinks()"> Guardar </v-btn>
                    </v-layout>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import VeeValidate, { Validator } from "vee-validate";
import api from "../../api/inscripcion";
import es from "vee-validate/dist/locale/es";
import Vue from "vue";
Validator.localize("es", es);
Vue.use(VeeValidate);

export default {
    data() {
        return {
            evento: null,
            eventoLoading: false,
            links: [{ textoBoton: "", url: "" }],
            photosLinks: [{ textoBoton: "", url: "" }],
            loadingSubmit: false,
        };
    },

    computed: {},

    watch: {},
    mounted() {
        this.eventoId = this.$route.params.id;
        api.getEventoById(this.eventoId).then(evento => {
            this.evento = evento;
            this.eventoLoading = false;
            this.links = evento.event_result_links ? JSON.parse(evento.event_result_links) : [{ textoBoton: "", url: "" }]
            this.photosLinks = evento.photo_album_links ? JSON.parse(evento.photo_album_links) : [{ textoBoton: "", url: "" }]
        })
    },

    methods: {
        volver() {
            this.$router.go(-1)
        },
        addFileSlot() {
            this.links.push({ textoBoton: "", url: "" });
        },
        removeItem(index) {
            this.links.splice(index, 1);
        },
        saveLinks() {
            this.loadingSubmit = true;
            
            const eventoID = this.$route.params.id
            
            api
                .saveLinks(eventoID, { event_result_links: this.links, photo_album_links: this.photosLinks })
                .then((res) => {
                    if (res.status == 200) {
                        this.$store.commit("app/SET_MESSAGE", res.message);
                        this.$store.commit("app/SET_SHOW", true);
                    }
                })
                .finally(() => {
                    this.loadingSubmit = false;
                });
                
         
        },
    },
};
</script>
