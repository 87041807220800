<template>
    <v-tooltip v-if="cupon !== null" top>
        <template v-slot:activator="{ on }">
            <v-btn flat color="primary" dark v-on="on">{{ (cupon && cupon.codigo) ? cupon.codigo : '' }}</v-btn>
        </template>
        <span>
            <ul v-if="cupon">
                <li>Codigo: {{ cupon.codigo }}</li>
                <li>Porcentaje: {{ cupon.porcentaje }} %</li>
                <li>Fecha Inicio Validez: {{ cupon.fecha_inicio_validez | formatDate  }}</li>
                <li>Fecha Fin validez: {{ cupon.fecha_fin_validez | formatDate }}</li>
            </ul>
        </span>
    </v-tooltip>
    <span v-else></span>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        cupon: Object
    },
    methods: {

    }
}
</script>