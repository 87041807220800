<template>
    <v-dialog v-model="openVerificarEstadoInscripcion" max-width="800px" persistent>

        <v-card style="text-align: center;" :color="bgColor">
            <v-card-title v-show="!(existeInscripcionMensaje && inscripcionState)" class="headline">Verificá tu inscripción</v-card-title>

            <v-card-text>
                <div v-show="!(existeInscripcionMensaje && inscripcionState)">
                    <h3 class="subheading">Ingresá tu número de documento y hace click en VERIFICAR.</h3>
                    <br/>
                    <v-form data-vv-scope="validateInscriptionForm" autocomplete="off">
                        <v-text-field v-model="documento" locale="es" :id="'validateDocumento'" v-validate="'required'"
                            data-vv-name="validateDocumento" :data-vv-scope="'validateInscriptionForm'" required
                            :name="'validateDocumento'" label="# Documento"
                            :error-messages="errors.collect('validateInscriptionForm.validateDocumento')"
                            type="text" :disabled="disabledInput"></v-text-field>
                    </v-form>

                    <v-btn :loading="loading" block @click="verificarEstadoInscripcion" color="primary">Verificar</v-btn>

                    <p class=" text-xs-left tbody-2  font-weight-light font-italic ">
                        Recordá que si aun no pagaste, no apareceras como inscripto. Si lo hiciste por Rapipago o Pago Facil, puede demorar hasta 48hs en acreditarse.
                    </p>

                </div>
                
                <span v-show="existeInscripcionMensaje && inscripcionState"
                    :type="SUCCESS_INSCRIPCION_STATES.includes(inscripcionState)  ? 'success' : 'warning'">
                    <h3 style="color: white; margin: 20px " class="heading">{{ existeInscripcionMensaje }}</h3>
                    <div>
                        <v-icon  x-large color="white">check_circle</v-icon>
                    </div>
                </span>

                <v-alert v-show="existeInscripcionMensaje && !inscripcionState" type="error">
                    {{ existeInscripcionMensaje }}
                </v-alert>

                <PendingPaymentInscriptionDialog
                    :openPendingPaymentDialog="openPendingPaymentDialog"
                    :verificationResult="verificationResult"
                    @closePendingPaymentInscriptionDialog="closePendingPaymentInscriptionDialog"    
                />
               
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" flat="flat" @click="closeDialog">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
      
    </v-dialog>
    
</template>

<script>

import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
Validator.localize('es', es);
Vue.use(VeeValidate);

import api from '../../api/inscripcion';
import PendingPaymentInscriptionDialog from './PendingPaymentInscriptionDialog.vue';

const SUCCESS_INSCRIPCION_STATES = ['pago_realizado', 'pago_bonificado'];
const PENDING_INSCRIPCION_STATES = ['pago_pendiente'];


export default {
    components: {
        'PendingPaymentInscriptionDialog': PendingPaymentInscriptionDialog
    },
    props: {
        openVerificarEstadoInscripcion: {
            type: Boolean,
            default: false
        },
        eventoId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            disabledInput: false,
            documento: null,
            existeInscripcionMensaje: null,
            verificationResult : null,
            initPoint: null,
            inscripcionState: null,
            loading: false,
            SUCCESS_INSCRIPCION_STATES: SUCCESS_INSCRIPCION_STATES,
            PENDING_INSCRIPCION_STATES:PENDING_INSCRIPCION_STATES,
            openPendingPaymentDialog: false
        }
    },
    watch: {
        openVerificarEstadoInscripcion(val) {
            if (val) {
                this.disabledInput = false;
                this.documento = null;
                this.existeInscripcionMensaje = null;
                this.initPoint = null;
                this.inscripcionState = null;
            }
        }
    },
    mounted() {
        this.$validator.localize("es", this.dictionary);
    },
    computed:{
        bgColor(){
            if(SUCCESS_INSCRIPCION_STATES.includes(this.inscripcionState))
                return 'green'
            if(PENDING_INSCRIPCION_STATES.includes(this.inscripcionState))
                return '#fb8c00'
            else{                
                return 'white'
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialogVerificarEstadoInscripcion');
        },

        existeInscripcionParaDNI() {
            this.loading = true;
            return api.existeInscripcionParaDNI(this.documento, this.eventoId)
                .then(res => {
                    this.loading = false;
                    return res;
                }).catch(error => {
                    alert("Ocurrio un error validando la inscripción")
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                })
        },

        verificarEstadoInscripcion() {
            this.$validator.validateAll("validateInscriptionForm").then((isFormOk) => {
                if (isFormOk) {
                    this.initPoint = null;
                    this.existeInscripcionMensaje = null;
                    this.inscripcionState = null;
                    this.existeInscripcionParaDNI().then(res => {
                        if (res.results) {
                            this.inscripcionState = res.results.state;
                            this.verificationResult = res.results;
                            this.disabledInput = true;
                            this.existeInscripcionMensaje = res.message;                            
                            if(res.results.state == 'pago_pendiente' && res.results.init_point){
                                this.closeDialog();
                                this.openPendingPaymentDialog = true;
                            }
                        } else {
                            this.existeInscripcionMensaje = res.message;
                        }
                    });
                }
            });
        },
        closePendingPaymentInscriptionDialog(){
            this.openPendingPaymentDialog = false;            
        }
    }
}
</script>