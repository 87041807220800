<style>
.boton-flotante {
    width: 100%;
    border-radius: 0;
    position: fixed;
    bottom: 50px;
    left: 0;
    z-index: 999;
}
</style>
<template>
    <v-container class="px-0" style="max-width: 1280px;">

        <v-layout align-center justify-center>

            <div v-if="loading" class="my-3 xs12 sm4 ">
                <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
            </div>
            <v-flex v-if="!loading">
                <section>
                    <v-card color="white lighten-2" class="align-center elevation-5 black--text">                        
                        <v-img height="400" :src="evento.image_full_path"></v-img>
                        <v-flex v-if= "evento.disciplinas.length" md12>
                        <v-divider  light></v-divider>
                        <v-card-actions class="pa-1">
                          <!-- <v-chip label outline color="#fa5b3f" >
                            <v-icon left>tag</v-icon>Etiquetas:
                          </v-chip> -->
                          <v-spacer></v-spacer>
                          <v-chip small outline color="#fa5b3f" v-for="(tag, key) in evento.disciplinas" ><v-icon small left>tag</v-icon>{{ tag.nombre }}</v-chip>
                      </v-card-actions>
                      </v-flex>
                    </v-card>
                </section>

                <section class="mt-2">
                    <v-layout  v-if="!( evento.id == 144 ||  evento.id == 175 ) "   text-xs-center column align-center justify-center row wrap>
                        <v-flex pa-3 xs12>
                            <div>
                                <div class="display-1">{{ evento.nombre }}</div>
                                <div class="headline">{{ evento.ciudad }}</div>
                            </div>
                        </v-flex>
                    </v-layout>

                    <v-layout   align-center justify-center row wrap>

                        <span v-if="!esEventoPrivado">

                            <v-btn style="height: 60px; font-size: 18px;" v-if="evento.inscripcion_abierta" color="#fa5b3f"  dark large :block="$vuetify.breakpoint.xsOnly"
                                :to="{ name: 'inscripcion', params: { id: evento.id } }">
                                Inscripcion
                                <v-icon dark right>create</v-icon>
                            </v-btn>
                        </span>
                        <span v-else>
                            <v-btn style="height: 60px; font-size: 18px;" v-if="evento.inscripcion_abierta" color="#fa5b3f"  dark large :block="$vuetify.breakpoint.xsOnly"
                                :to="{ name: 'inscripcionPrivado', params: { id: evento.id, code: $route.params.codigo_acceso } }">
                                Inscripcion
                                <v-icon dark right>create</v-icon>
                            </v-btn>
                        </span>


                    </v-layout>


                    <v-layout row wrap grid-list-xl justify-center>
                        <v-flex pa-3 xs12 sm12 md5 lg5 xl5>
                            <div>
                                <div><span class='headline'>Información</span></div>
                                <div><span class='subheading font-weight-bold'> <v-icon>place</v-icon> Lugar: </span>
                                    {{ evento.lugar }}</div>
                                <div><span class='subheading font-weight-bold'> <v-icon>calendar_today</v-icon> Fecha y
                                        Hora: </span> {{ evento.fecha_hora | formatDateString }}</div>
                                <div><span class='subheading font-weight-bold'> <v-icon>alarm</v-icon> Hora: </span>
                                    {{ evento.fecha_hora | formatTime }}</div>
                                <div><span class='subheading font-weight-bold'> <v-icon color="green">edit</v-icon>
                                        Inscripcion desde: </span> {{ evento.fecha_inicio_inscripcion | formatDateTimeString
                                        }}</div>
                                <div><span class='subheading font-weight-bold'> <v-icon color="red">edit</v-icon>
                                        Inscripcion finaliza: </span> {{ evento.fin_inscripcion | formatDateTimeString }}
                                </div>
                                <!-- <div><span class='subheading font-weight-bold'> <v-icon>person</v-icon> Organizado por:
                                    </span>{{ evento.organizador.nombre }}</div> -->
                                <div><span class='subheading font-weight-bold'> <v-icon>info</v-icon> Información:
                                    </span><span v-html="evento.descripcion"> </span></div>
                                <div><span class='subheading font-weight-bold'> <v-icon>list</v-icon>Modalidades: </span>
                                    <ul>
                                        <li v-for="(modalidad, key) in evento.modalidades">
                                            <span class=" subheading font-weight-black">{{ modalidad.nombre }}</span> 
                                            <!-- <span
                                                class=" subheading  ">- Valor Inscripcion:
                                                ${{ modalidad.valor_normal }}</span>
                                            <span v-if="modalidad.costo_servicio > 0"> + Costo servicio Finishtime: ${{
                                                modalidad.costo_servicio }}</span> -->
                                            <div v-if="hayCategoriasVisibles(modalidad)" class="subheading">Categorias:</div>


                                            <v-layout wrap row>

                                                <v-flex v-if="getCategoriasEnModalidadPorSexo(modalidad, 'FEM').length > 0"
                                                    md3>
                                                    <div style="text-decoration:underline" class="body-2">Femeninos</div>
                                                    <div
                                                        v-for="(cat, key1) in getCategoriasEnModalidadPorSexo(modalidad, 'FEM')">
                                                        {{ getCategoriaNombre(cat) }}
                                                    </div>
                                                </v-flex>


                                                <v-flex v-if="getCategoriasEnModalidadPorSexo(modalidad, 'MASC').length > 0"
                                                    md3>
                                                    <div style="text-decoration:underline" class="body-2">Masculinos</div>
                                                    <div
                                                        v-for="(cat, key1) in getCategoriasEnModalidadPorSexo(modalidad, 'MASC')">
                                                        {{ getCategoriaNombre(cat) }}
                                                    </div>
                                                </v-flex>

                                                <v-flex v-if="getCategoriasEnModalidadPorSexo(modalidad, 'MIX').length > 0"
                                                    md3>
                                                    <div style="text-decoration:underline" class="body-2">Mixtos</div>
                                                    <div
                                                        v-for="(cat, key1) in getCategoriasEnModalidadPorSexo(modalidad, 'MIX')">
                                                        {{ getCategoriaNombre(cat) }}
                                                    </div>
                                                </v-flex>

                                            </v-layout>


                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </v-flex>

                        <v-flex pa-2 xs12 sm5 xl5>
                            <v-img :src=evento.banner_full_path></v-img>
                            <v-img v-for=" imagen in evento.imagenes" class="mt-2" :src=imagen.ruta_absoluta></v-img>
                        </v-flex>

                    </v-layout>
                    <v-layout v-if="!esEventoPrivado" align-center justify-center row wrap pa-2>
                        <v-btn v-if="evento.inscripcion_abierta" color="#fa5b3f"  dark large :block="$vuetify.breakpoint.xsOnly"
                            :to="{ name: 'inscripcion', params: { id: evento.id } }">
                            Quiero inscribirme!
                        </v-btn>
                    </v-layout>
                    <v-layout v-else  align-center justify-center row wrap pa-2>
                        <v-btn v-if="evento.inscripcion_abierta" color="#fa5b3f"  dark large :block="$vuetify.breakpoint.xsOnly"
                            :to="{ name: 'inscripcionPrivado', params: { id: evento.id, code: $route.params.codigo_acceso } }">
                            Quiero inscribirme!
                        </v-btn>
                    </v-layout>

                </section>

           
              
                <v-btn class="hidden-xs-only" dark fab fixed bottom left @click="back" color="secondary">
                    <v-icon>arrow_back</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'



export default {

    data: vm => ({
        loading: true
    }),

    computed: {
        ...mapState({
            evento: state => state.eventos.eventoActual,
            modalidades: state => state.eventos.eventoActual.modalidades,
        }),
        esEventoPrivado() {
            return this.$route.params.codigo_acceso !== undefined;
        },
    },

    created() {
        scroll(0, 0);
        let eventoId = this.$route.params.id;
        let codigoAcceso = this.$route.params.codigo_acceso;

        this.loading = true;


        let promise = (eventoId && codigoAcceso)? 
            this.$store.dispatch("eventos/getFrontEventoByIdAndCodigoAcceso", {eventoId, codigoAcceso}): 
            this.$store.dispatch("eventos/getFrontEventoById", eventoId);


        promise.then(res => {
            this.loading = false;
        })


    },

    watch: {

    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        getCategoriaNombre(cat) {
            let edadRango = cat.sexo + cat.edad_inicial + '-' + cat.edad_final;
            return (cat.nombre) ? cat.nombre + '(' + edadRango + ')' : edadRango;
        },

        getCategoriasEnModalidadPorSexo(modalidad, sexo) {
            return modalidad.categorias.filter((c) => c.sexo === sexo && c.visible)
        },

        hayCategoriasVisibles(modalidad) {
            return modalidad.categorias.filter((c) => c.visible).length > 0
        },

        /*getCategoriaNombre(cat){
            let edadRango = cat.sexo + cat.edad_inicial + '-' +cat.edad_final; 
            return (cat.nombre)?cat.nombre   : edadRango; 
        },*/
    }
}
</script>
