<template>
    <v-layout align-center justify-center>

        <div v-if="loadingEvento" class="my-3 xs12 sm4 ">
            <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
        </div>

      
        <v-flex v-if="!loadingEvento">
            <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
            
            <v-layout row wrap pa-3>
                <v-flex xs6 md2>
                    <v-img contain height="80" :src=evento.banner_full_path></v-img>
                </v-flex>
                <v-flex xs6 md3>
                    <h2 class="display-1">Evento</h2>
                    <h2 class="headline">{{ evento.nombre }}</h2>
                    
                </v-flex>
             
            </v-layout>
            <v-divider class="mt-3"></v-divider>         

            <h2 class="headline pa-2">Asignar Numeros Manual </h2>

            <InscriptionsListEditable :estados="['PAGO_REALIZADO', 'PAGO_BONIFICADO', 'pago_no_paga']" />
            
  
        </v-flex>

    </v-layout>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from "vuex";
import api from '@/api/inscripcion'
import config from '@/config'

import CuponDialog from '../../../components/admin/common/CuponDialog.vue';
import CuponesTabla from '../../../components/admin/common/CuponesTabla.vue';
import debounce from '../../../directives/debounce';
import InscriptionsListEditable from './components/inscription-list-editable.vue';
import CanAccess from "@/components/utils/CanAccess.vue";
import evento from '../../../api/evento';

// import AutocompleteSearch from './components/AutocompleteSearch.vue';

export default {
    directives: { debounce },
    components: {
        "CuponDialog": CuponDialog,
        "CuponesTabla": CuponesTabla,
        "InscriptionsListEditable": InscriptionsListEditable,
        // "AutocompleteSearch": AutocompleteSearch
    },
    data() {
        return {
            dialog: false,
            fechaExpiracion: null,
            fechaExpiracionFormateada: null,
          
            fechaExpiracion: null,
            habilitado: false,
            
            cuponErrors: [],
            model: null,
            editarInscripcion: {
                state: '',
            },
            dialogInscripcion: false,
            inscripcionEditar: {},
            inscripcionBorrar: {},
            loadingEvento: true,
            loading: false,
            personaEditar: {},
            pagination: {
                search: '',
                descending: false,
                page: 1,
                rowsPerPage: -1,
                sortBy: "id",
                totalItems: 0,
                filters: {
                    estados: this.estados
                }
            },

            items: [],
            totalItems: 0,
            estados_inscripcion: [
                {
                    text: 'Todos',
                    value: '',
                },
                {
                    text: 'PAGO_PENDIENTE',
                    value: 'PAGO_PENDIENTE',
                },
                {
                    text: 'PAGO_REALIZADO',
                    value: 'PAGO_REALIZADO',
                },
                {
                    text: 'PAGO_BONIFICADO',
                    value: 'PAGO_BONIFICADO',
                },
                {
                    text: 'PAGO_NO_PAGA',
                    value: 'PAGO_NO_PAGA',
                }
            ]
        }
    },
    computed: {
        ...mapGetters("auth", [
            "isLoggedIn",
            "isAdmin",
            "isOrganizador",
            "nombreUsuario",
        ]),
        ...mapState({
            evento: state => state.eventos.eventoActual,
            modalidades: state => state.eventos.eventoActual.modalidades,
            steps: state => [
                {
                    text: 'Eventos',
                    disabled: false,
                    href: '/#/backoffice/evento/lista'
                },
                {
                    text: state.eventos.eventoActual.nombre,
                    disabled: false,
                    href: '/#/backoffice/event/' + state.eventos.eventoActual.id
                },
                {
                    text: state.eventos.eventoActual.nombre,
                    disabled: true,
                    href: '/#/backoffice/event/' + state.eventos.eventoActual.id + '/asignar-numeros-manual'
                }
            ],

        }),
    },


    created() {
        scroll(0, 0);
        let eventoId = this.$route.params.id;

       
        this.$store.dispatch('eventos/getEventoById', eventoId).then((res) => {
            this.loadingEvento = false;

        })

    },
    watch: {

        pagination: {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        },

    },
    mounted() {

    },
    methods: {
  
        getDataFromApi() {
            this.loading = true;
            let eventoId = this.$route.params.id;
            api.getInscriptosEventoOrganizadorId(eventoId, this.pagination).then((res) => {
                this.items = res.data;
                this.totalItems = res.total;
                this.loading = false;
            })
        },

        
 
    }

}
</script>                   
