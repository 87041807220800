<template>
    <p>

    <pre>
        {{ item  | json }}
    </pre>
    <label>Nombre</label>
    <input type="text" v-validate="'required'" :name="'item_name'" placeholder="nombre" class="form-control" v-model="item.value">
   

    <label>Tipo
    <select>
        <option value="text">Texto</option>
        <option value="number">Select</option>
        <option value="textarea">Area de texto</option>
    </select>
</label>

    </p>

</template>
<script>    
 


export default {
   
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        
    }),
    methods: {}
}
</script>
