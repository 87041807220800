<template>
    <v-layout align-center justify-center>

        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="orange" dark>
                <v-card-text>
                    Espere por favor...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-flex>
            <v-toolbar dark color="secondary">
                <v-toolbar-title>
                    <v-layout v-if="evento" row wrap>
                        <v-flex xs12>
                            <h2 class="headline">Inscripción a: {{ evento.nombre }} </h2>
                        </v-flex>
                        <v-flex xs12>
                            <div>Fecha : {{ evento.fecha_hora | formatDateString }}</div>
                        </v-flex>
                    </v-layout>

                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-toolbar dark color="secondary">
                <v-toolbar-title>
                    <v-layout v-if="evento" row wrap>
                        <v-flex xs12>
                            <!-- <v-btn flat @click="openEventDialog()" color="orange">
                                mas info
                            </v-btn> -->
                        </v-flex>

                    </v-layout>

                </v-toolbar-title>

            </v-toolbar>

            <v-stepper v-model="step">

                <v-stepper-header>
                    <v-stepper-step color="orange" step="1" :complete="step > 1">Modalidad</v-stepper-step>
                    <v-divider></v-divider>
                    <span v-for="n in modalidad.nro_participantes">
                        <v-stepper-step color="orange" :step="(n) ? n + 1 : ''" :complete="step > 2">Datos Participante
                            {{ n }}</v-stepper-step>
                    </span>
                    <v-divider></v-divider>
                    <v-stepper-step color="orange"
                        :step="(modalidad.nro_participantes) ? modalidad.nro_participantes + 2 : ''">Confirmación</v-stepper-step>
                    
                        <v-stepper-step v-if="evento.usa_pago_online && modalidad.valor_normal != 0" color="orange"
                        :step="(modalidad.nro_participantes) ? modalidad.nro_participantes + 3 : ''">Pagar</v-stepper-step>

                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">




                        <h2 class="heading">Paso 1</h2>
                        <h3 class="subheading">Seleccione Modalidad e ingrese documento</h3>
                        <br />
                        <v-form data-vv-scope="step-1" autocomplete="off">
                            <v-select data-vv-name="modalidad" data-vv-scope="step-1"  :menu-props="{ maxHeight: '500' }"
                                :error-messages="errors.collect('step-1.modalidad')" locale="es" id="modalidad"
                                v-validate="'required'" required v-model="modalidad" :item-text="itemText"                                
                                :return-object=true :items="modalidades" label="Modalidad">
                            </v-select>
                            <v-menu ref="menu_modalidad" :close-on-content-click="false" v-model="menu_modalidad"
                                :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                            </v-menu>

                            <v-text-field v-model="datos[0].documento" locale="es" :id="'documento[0]'"
                                v-validate="'digits:8|required'" data-vv-name="documento" :data-vv-scope="'step-1'" required
                                :error-messages="errors.collect('step-1.documento')" :name="'documento[0]'"
                                label="# Documento" type="number"></v-text-field>

                            <v-text-field v-if="parseInt(modalidad.nro_participantes) > 1" v-model="nombre" locale="es"
                                v-validate="'required'" :error-messages="errors.collect('step-1.nombre')" :name="'nombre'"
                                :data-vv-scope="'step-1'" data-vv-name="nombre" label="Nombre Equipo" required type="text">
                            </v-text-field>
                        </v-form>


                        <v-layout row justify-space-between>
                            <v-flex grow>

                            </v-flex>
                            <v-flex shrink>
                                <v-btn :disabled="buttonCheckInscripcionDisabled" round
                                    v-if="step < totalSteps && step < totalSteps - 1" :loading="loading" @click="siguiente"
                                    color="orange">Siguiente <v-icon right dark>chevron_right</v-icon></v-btn>
                            </v-flex>
                        </v-layout>

                        <br />
                        <h2 class="heading"></h2>
                        <h3 class="body-2">Si ya te inscribiste, podes validar el estado de tu inscripcion haciendo click en
                            el siguiente botón:</h3>

                        <v-btn outline class="primary" flat @click.stop="openVerificarEstadoInscripcion = true">
                            Ver el estado de mi inscripcion
                        </v-btn>

                        <EstadoInscripcionDialog :openDialogExisteInscripcion="openDialogExisteInscripcion"
                            :existe_inscripcion_msg="existe_inscripcion_msg" :inscripcion_state="inscripcion_state"
                            :mercadoPagoInitPoint="existe_init_point"
                            @closeDialogExisteInscripcion="() => { openDialogExisteInscripcion = false }" />

                        <VerificarEstadoInscripcion :eventoId="evento.id"
                            :openVerificarEstadoInscripcion="openVerificarEstadoInscripcion"
                            @closeDialogVerificarEstadoInscripcion="() => { openVerificarEstadoInscripcion = false }" />





                    </v-stepper-content>
                    <span v-for="(persona, index) in datos" :key="'persona_' + index">
                        <v-stepper-content :step="index + 2">
                            <div class="subheading">Datos participante</div>

                            <v-form autocomplete="off" data-vv-scope="step-2">
                                <v-text-field v-model="datos[index].documento" :readonly="index == 0" :disabled="index == 0"
                                    locale="es" :id="'documento[' + index + ']'" v-validate="'required|max:10'"
                                    data-vv-name="documento" :data-vv-scope="'step-' + (index + 2)" required
                                    :error-messages="errors.collect('step-' + (index + 2) + '.documento')"
                                    :name="'documento[' + index + ']'" label="# Documento" type="text"></v-text-field>



                                <v-text-field v-model="datos[index].nombre" locale="es" v-validate="'required'"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.nombre')"
                                    :name="'nombre[' + index + ']'" :data-vv-scope="'step-' + (index + 2)"
                                    data-vv-name="nombre" label="Nombre" required type="text">
                                </v-text-field>
                                <v-text-field :id="'apellido[' + index + ']'" v-model="datos[index].apellido" locale="es"
                                    v-validate="'required'" :data-vv-scope="'step-' + (index + 2)" data-vv-name="apellido"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.apellido')" :name="'apellido'"
                                    label="Apellido" type="text">
                                </v-text-field>
                                <v-select v-model="datos[index].sexo" v-validate="'required'"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.sexo')"
                                    :data-vv-scope="'step-' + (index + 2)" item-text="label" item-value="abbr"
                                    :items="sexos" :name="'sexo'" label="Sexo">
                                </v-select>
                                <v-menu ref="menu_sexo" v-on:change="checkAgeAndCategory" :close-on-content-click="false"
                                    v-model="datos[index].menu_sexo" :nudge-right="40" lazy transition="scale-transition"
                                    offset-y full-width min-width="290px">
                                </v-menu>
                                <!-- <v-select v-model="datos[index].con_discapacidad" v-validate="'required'"
                                    :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.con_discapacidad')" item-text="label"
                                    item-value="val" :items="[{'val': 1, 'label' : 'SI'},{ 'val': 0, 'label': 'NO'}]" 
                                    name="con_discapacidad" 
                                    label="Persona con discapacidad (CUD)"
                                    hint="Los datos de esta pre-inscripción corresponden a una persona con CUD(Certificado Único de Discapacidad)"
                                    persistent-hint>
                                </v-select> -->

                                <v-layout wrap row>
                                    <v-flex pa-3 md12>
                                        Fecha Nacimiento (DD/MM/AAAA)
                                    </v-flex>
                                </v-layout>

                                <v-layout wrap row>
                                    <v-flex xs3 pa-3 md3>
                                        <v-text-field :disabled="datos[index].sexo == ''"
                                            v-model="datos[index].dia_nacimiento" v-on:keyup="checkAgeAndCategory"
                                            v-validate="'required|integer|min_value:1|max_value:31'" locale="es"
                                            data-vv-name="dia" min="1" max="31" :data-vv-scope="'step-' + (index + 2)"
                                            :error-messages="errors.collect('step-' + (index + 2) + '.dia')"
                                            :name="'dia_nacimiento'" label="Dia" type="number">
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs3 pa-3 md3>
                                        <v-text-field :disabled="datos[index].sexo == ''"
                                            v-model="datos[index].mes_nacimiento" v-on:keyup="checkAgeAndCategory"
                                            v-validate="'required|integer|min_value:1|max_value:12'" min="1" max="12"
                                            locale="es" data-vv-name="mes" :data-vv-scope="'step-' + (index + 2)"
                                            :error-messages="errors.collect('step-' + (index + 2) + '.mes')"
                                            :name="'mes_nacimiento'" label="Mes" type="number">
                                        </v-text-field>
                                    </v-flex>
                                    <!-- |min_value:1900 -->
                                    <v-flex xs4 pa-3 md3>
                                        <v-text-field :disabled="datos[index].sexo == ''" locale="es" data-vv-as="Año"
                                            v-on:keyup="checkAgeAndCategory" v-model="datos[index].anio_nacimiento"
                                            v-validate="'required|integer|min_value:1900'"
                                            :data-vv-scope="'step-' + (index + 2)"
                                            :error-messages="errors.collect('step-' + (index + 2) + '.anio')" :name="'anio'"
                                            label="Año" type="number">
                                        </v-text-field>
                                    </v-flex>

                                    <v-flex xs12 pa-0 ma-0>
                                        <p style="color:red">{{ errors.first('step-' + (index + 2) + '.edad') }}</p>
                                    </v-flex>
                                </v-layout>


                                <v-select v-model="datos[index].provincia" v-validate="'required'"
                                    :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.provincia')" item-text="label"
                                    item-value="abbr" :items="provincias" name="provincia" label="Provincia">
                                </v-select>
                                <v-menu ref="menu_prov" :close-on-content-click="false" v-model="datos[index].menu_prov"
                                    :nudge-right="40" lazy transition="scale-transition" offset-y full-width
                                    min-width="290px"></v-menu>


                                <v-text-field v-model="datos[index].ciudad" v-validate="'required'"
                                    :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.ciudad')" name="ciudad"
                                    label="Ciudad" type="text">
                                </v-text-field>
                                <v-text-field v-model="datos[index].domicilio" v-validate="'required'"
                                    :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.domicilio')" name="domicilio"
                                    label="Domicilio" type="text">
                                </v-text-field>
                                <v-text-field v-model="datos[index].telefono" v-validate="'required'"
                                    :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.telefono')" name="telefono"
                                    label="Teléfono" type="text">
                                </v-text-field>
                                <v-text-field v-model="datos[index].email" :data-vv-scope="'step-' + (index + 2)"
                                    v-validate="'email|required'"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.email')" name="email"
                                    label="Email" type="email"
                                    hint="IMPORTANTE: Ingrese un email válido, ya que será el medio de contacto principal."
                                    persistent-hint>
                                    >
                                </v-text-field>
                                <v-spacer></v-spacer>
                                <div v-if="evento.usa_talle_remera">
                                    <v-select v-model="datos[index].talle" :data-vv-scope="'step-' + (index + 2)"
                                        :error-messages="errors.collect('step-' + (index + 2) + '.talle')" item-text="label"
                                        item-value="abbr" :items="talles" name="talle" label="Talle Remera">
                                    </v-select>
                                    <v-menu ref="menu_talle" :close-on-content-click="false"
                                        v-model="datos[index].menu_talle" :nudge-right="40" lazy
                                        transition="scale-transition" offset-y full-width min-width="290px"></v-menu>
                                </div>
                                <v-text-field v-model="datos[index].team" :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.team')" name="team"
                                    label="Team" type="team">
                                </v-text-field>

                                <v-spacer></v-spacer>

                                <v-checkbox v-if="evento.id == 164" v-model="datos[index].con_remera"
                                    :data-vv-scope="'step-' + (index + 2)"
                                    :error-messages="errors.collect('step-' + (index + 2) + '.con_remera')"
                                    name="con_remera"
                                    :label="'Quiero mi remera del evento (Costo adicional $3000)'"></v-checkbox>
                                <v-spacer></v-spacer>

                                <h3 v-if="modalidad.campos_custom && modalidad.campos_custom.length > 0">Datos adicionales</h3>
                                <span class="error--text" if="errors.first('step-' + (index + 2) + '.campos_custom_values')">{{ errors.first('step-' + (index + 2) + '.campos_custom_values') }}</span>
                                <div v-for="item in modalidad.campos_custom">
                                    <CampoCustomRenderer :config="item" :index="index" v-on:change="handleCampoCustomChange"  />
                                </div>

                            </v-form>
                        </v-stepper-content>
                    </span>


                    <v-stepper-content :step="modalidad.nro_participantes + 2">
                         
                        <StepConfirmarDatos :datos="datos" :modalidad="modalidad" :evento="evento"
                            @aplicarCupon="aplicarCupon" />

                    </v-stepper-content>


                    <v-stepper-content v-if="evento.usa_pago_online" :step="modalidad.nro_participantes + 3">

                        <v-container grid-list-md>
                            <v-layout row wrap justify-space-between>
                                <v-flex xs12>
                                    <v-card-title>
                                        <h2>Pago Online</h2>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                                <v-flex xs4 subheading> Modalidad: </v-flex>
                                <v-flex xs8 subheading> {{ modalidad.nombre }} </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                                <v-flex xs4 subheading> Valor inscripción: </v-flex>
                                <v-flex xs8 subheading> $ {{ valorInscripcion }} </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                                <v-flex xs12 subheading>{{ mensaje_resultado }}</v-flex>
                                <v-flex xs12 subheading>
                                    <v-btn :href="mp_checkout" block color="orange" dark>Pagar</v-btn>
                                </v-flex>
                                <v-flex xs12 subheading>
                                    <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/120X240.jpg"
                                        title="MercadoPago - Medios de pago" alt="MercadoPago - Medios de pago" width="120"
                                        height="240" />
                                </v-flex>
                            </v-layout>



                        </v-container>

                    </v-stepper-content>


                </v-stepper-items>
                <v-layout row justify-space-between>
                    <v-flex grow>
                        <v-btn dark v-if="step == 1" fab fixed bottom left @click="$router.go(-1)" color="secondary">
                            <v-icon>arrow_back</v-icon>
                        </v-btn>

                        <v-btn outline light round="" v-if="step > 1 && step != totalSteps" @click="step--"> <v-icon left
                                dark>chevron_left</v-icon> </v-btn>
                    </v-flex>
                    <v-flex shrink>
                        <v-btn round v-if="step > 1 && step < totalSteps && step < totalSteps - 1" :loading="loading"
                            @click="siguiente" color="orange">Siguiente <v-icon right dark>chevron_right</v-icon></v-btn>

                        <v-btn round="" v-if="step == totalSteps - 1" :loading="loading" @click="submit"
                            color="orange">Guardar Datos </v-btn>
                    </v-flex>
                </v-layout>
            </v-stepper>
        </v-flex>
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Resultado Inscripción</v-card-title>
                <v-card-text v-html="mensaje_resultado"> </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" flat @click="cerrar">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="disclaimer_dialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">IMPORTANTE!</v-card-title>
                <v-card-text>

                    <v-alert :value="true" type="warning">
                        <p class="headline">ATENCIÓN</p>
                        <p class="subheading">Tendrá un plazo de {{ evento.duracion_inscripcion_pendiente }}Hs para abonar
                            la
                            inscripcion. Caso contrario tendrá que
                            volver a completar sus datos. </p>
                    </v-alert>
                    NOTA: Tener en cuenta que estaras Inscripto cuando:
                    <ul>
                        <li>Hayas completado todos tus datos de inscripcion en este sitio</li>
                        <li>Hayas abonado el monto correspondiente por los medios habilitados</li>
                    </ul>
                    <p>

                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn round outline flat @click="cerrar_disclaimer">Cancelar</v-btn>
                    <v-btn round color="orange darken-1" @click="aceptar_disclaimer">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog v-model="dialogInfoEvento" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="orange">
                    <v-btn icon dark @click="dialogInfoEvento = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Información del Evento</v-toolbar-title>

                </v-toolbar>
                <div>
                    <v-container class="px-0" style="max-width: 1280px;">

                        <v-layout align-center justify-center>

                          
                            <v-flex  >
                                <section>
                                    <v-card color="white lighten-2" class="align-center elevation-5 black--text">
                                        <v-img height="400" :src="evento.image_full_path"></v-img>
                                    </v-card>
                                </section>

                                <section class="mt-2">
                                    <v-layout v-if="!(evento.id == 144 || evento.id == 175)" text-xs-center column
                                        align-center justify-center row wrap>
                                        <v-flex pa-3 xs12>
                                            <div>
                                                <div class="display-1">{{ evento.nombre }}</div>
                                                <div class="headline">{{ evento.ciudad }}</div>
                                            </div>
                                        </v-flex>
                                    </v-layout>

                                 


                                    <v-layout row wrap grid-list-xl justify-center>
                                        <v-flex pa-3 xs12 sm12 md5 lg5 xl5>
                                            <div>
                                                <div><span class='headline'>Información</span></div>
                                                <div><span class='subheading font-weight-bold'> <v-icon>place</v-icon>
                                                        Lugar: </span>
                                                    {{ evento.lugar }}</div>
                                                <div><span class='subheading font-weight-bold'>
                                                        <v-icon>calendar_today</v-icon> Fecha y
                                                        Hora: </span> {{ evento.fecha_hora | formatDateString }}</div>
                                                <div><span class='subheading font-weight-bold'> <v-icon>alarm</v-icon> Hora:
                                                    </span>
                                                    {{ evento.fecha_hora | formatTime }}</div>
                                                <div><span class='subheading font-weight-bold'> <v-icon
                                                            color="green">edit</v-icon>
                                                        Inscripcion desde: </span> {{ evento.fecha_inicio_inscripcion |
                                                            formatDateTimeString
                                                        }}</div>
                                                <div><span class='subheading font-weight-bold'> <v-icon
                                                            color="red">edit</v-icon>
                                                        Inscripcion finaliza: </span> {{ evento.fin_inscripcion |
                                                            formatDateTimeString }}
                                                </div>
                                                
                                                <div><span class='subheading font-weight-bold'> <v-icon>info</v-icon>
                                                        Información:
                                                    </span><span v-html="evento.descripcion"> </span></div>
                                                <div><span class='subheading font-weight-bold'>
                                                        <v-icon>list</v-icon>Modalidades: </span>
                                                    <ul>
                                                        <li v-for="(modalidad, key) in evento.modalidades">
                                                            <span class=" subheading font-weight-black">{{ modalidad.nombre
                                                            }}</span>
                                                        


                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </v-flex>

                                        <v-flex pa-2 xs12 sm5 xl5>
                                            <v-img :src=evento.banner_full_path></v-img>
                                            <v-img v-for=" imagen in evento.imagenes" class="mt-2"
                                                :src=imagen.ruta_absoluta></v-img>
                                        </v-flex>

                                    </v-layout>
                                  

                                </section>



                                <v-btn class="hidden-xs-only" dark fab fixed bottom left @click="back" color="secondary">
                                    <v-icon>arrow_back</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </div>

            </v-card>
        </v-dialog>


    </v-layout>
</template>

<script>
import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
import { mapState } from 'vuex'
import api from '@/api/inscripcion'
import auth from '@/api/auth'
import StepConfirmarDatos from '@/components/inscripcion-flow/StepConfirmarDatos.vue';
import EstadoInscripcionDialog from '@/components/inscripcion-flow/EstadoInscripcionDialog.vue';
import VerificarEstadoInscripcion from '@/components/inscripcion-flow/VerificarEstadoInscripcionDialog.vue';
import CampoCustomRenderer from '@/components/custom-forms/CampoCustomRenderer.vue';

Validator.localize('es', es);
Vue.use(VeeValidate);
 
export default {
    components: { 
        'VerificarEstadoInscripcion': VerificarEstadoInscripcion, 
        'StepConfirmarDatos': StepConfirmarDatos,
        'EstadoInscripcionDialog': EstadoInscripcionDialog,
        'CampoCustomRenderer': CampoCustomRenderer
    },
    data: vm => ({
        dialogInfoEvento: false,
        cupon: {
            codigo: null,
            descuento: 0,
            mensaje: null
        },
        disclaimer_dialog: false,
        existe_init_point: "",
        existe_inscripcion_msg: "",
        openDialogExisteInscripcion: false,
        openVerificarEstadoInscripcion: false,
        inscripcion_state: "",
        loading: true,
        dialog: false,
        mensaje_resultado: "",
        mp_checkout: "",
        exito: false,
        nombre: "",
        step: 1,
        modalidad: {},
        codigo: null,
        menu_modalidad: "",
        sexos: [
            { abbr: "MASC", label: "Masculino" },
            { abbr: "FEM", label: "Femenino" }
        ],
        provincias: [
            "Buenos Aires",
            "CABA",
            "La Pampa",
            "San Luis",
            "Cordoba",
            "Santa Fe",
            "Mendoza",
            "San Juan",
            "Entre Rios",
            "Corriente",
            "Formosa",
            "Chaco",
            "Jujuy",
            "Salta",
            "Tucumán",
            "La Rioja",
            "Catamarca",
            "Chaco",
            "Santa Cruz",
            "Chubut",
            "Rio Negro",
            "Tierra del Fuego"
        ],
        datos: [
            {
                documento: "",
                nombre: "",
                apellido: "",
                sexo: "",
                email: "",
                telefono: "",
                domicilio: "",
                ciudad: "",
                provincia: "",
                dia_nacimiento: "",
                mes_nacimiento: "",
                anio_nacimiento: "",
                menu_prov: "",
                menu_talle: "",
                menu_sexo: "",
                team: "",
                acepta_compartir_datos: true,
                con_remera: false,
                con_discapacidad: false,
                campos_custom_values: {}
            }
        ],
        //campos_custom_values:{}
    }),
    props: {
        source: String
    },
    computed: mapState({
        talles: (state) => {
            let t = [
                { abbr: "XS", label: "XS" },
                { abbr: "S", label: "S" },
                { abbr: "M", label: "M" },
                { abbr: "L", label: "L" },
                { abbr: "XL", label: "XL" },
                { abbr: "XXL", label: "XXL" }
            ];
            // PARCHE
            if (state.eventos.eventoActual.id == 159) {
                t = [

                    { abbr: "S", label: "S" },
                    { abbr: "M", label: "M" },
                    { abbr: "L", label: "L" },
                    { abbr: "XL", label: "XL" }

                ];
            }
            return t;
        },
        evento: state => state.eventos.eventoActual,
        modalidades: state => state.eventos.eventoActual.modalidades,
        valorInscripcion() {
            if (this.codigo && this.cupon.codigo) {
                return parseFloat(this.cupon.valor_final) + parseFloat(this.modalidad.costo_servicio);
            }
            return parseFloat(this.modalidad.valor_normal) + parseFloat(this.modalidad.costo_servicio);
        },
        totalSteps() {
            return this.modalidad.nro_participantes + 3;
        },
        fromAdmin() { return this.$router.currentRoute.name == "eventoInscripcionAdmin"; },
        buttonCheckInscripcionDisabled() {
            return (this.modalidad ? this.modalidad.id == null : false) || this.datos[0].documento == "";
        }
    }),
    mounted() {
        this.$validator.localize("es", this.dictionary);
    },
    watch: {
        evento() {
            let fromAdmin = (this.$router.currentRoute.name == "eventoInscripcionAdmin");
            if ((!this.evento.visible && !fromAdmin) || (!this.evento.inscripcion_abierta && !fromAdmin)) {
               
                this.$router.push("/");
            }
        },
        modalidad(newVal, old) {
            this.datos = [
                {
                    documento: "",
                    nombre: "",
                    apellido: "",
                    sexo: "",
                    email: "",
                    telefono: "",
                    domicilio: "",
                    ciudad: "",
                    provincia: "",
                    dia_nacimiento: "",
                    mes_nacimiento: "",
                    anio_nacimiento: "",
                    menu_modalidad: "",
                    menu_prov: "",
                    menu_talle: "",
                    menu_sexo: "",
                    acepta_compartir_datos: true,
                    con_remera: false,
                    campos_custom_values: {}
                }
            ];
            for (var i = 1; i < parseInt(newVal.nro_participantes); i++) {
                this.datos.push({
                    documento: "",
                    nombre: "",
                    apellido: "",
                    sexo: "",
                    email: "",
                    telefono: "",
                    domicilio: "",
                    ciudad: "",
                    provincia: "",
                    dia_nacimiento: "",
                    mes_nacimiento: "",
                    anio_nacimiento: "",
                    menu_modalidad: "",
                    menu_prov: "",
                    menu_talle: "",
                    menu_sexo: "",
                    team: "",
                    acepta_compartir_datos: true,
                    con_remera: false,
                    con_discapacidad: false,
                    campos_custom_values: {}
                });
            }

            this.campos_custom_values = {};
        },

    },
    created() {
        scroll(0, 0);
       
        let eventoId = this.$route.params.id;
        let codigoAcceso = this.$route.params.codigo_acceso;
        
        this.loading = true;
        let promise = (eventoId && codigoAcceso)? 
            this.$store.dispatch("eventos/getFrontEventoByIdAndCodigoAcceso", {eventoId, codigoAcceso}): 
            this.$store.dispatch("eventos/getFrontEventoById", eventoId);

        promise.then(res => {            
                this.loading = false;
               
                if(this.evento ){
                    if (this.evento.usa_pago_online) {
                        this.openDisclaimerDialog();
                    }                    
                }else{
                   
                    this.$router.push("/");
                }
            }).catch(error => {
               
                if (error.response.status == 401) {
                    auth.logout();
                }
                else {
                    this.dialog = true;
                    this.exito = false;
                    this.loading = false;
                    this.mensaje_resultado = error.response.data.errors.message;
                }
                console.log(error);
            })
    },
    methods: {

        handleCampoCustomChange(event){       
            
            if (event.value === null) {
                delete this.datos[event.index].campos_custom_values[event.id];
            } else {
                this.datos[event.index].campos_custom_values[event.id] = event.value;
            }

            return false;
           
        },


        openEventDialog() {
            this.dialogInfoEvento = true
        },
        checkAgeAndCategory(event) {
            const step = this.step;
            if (this.datos[step - 2].sexo && this.datos[step - 2].dia_nacimiento && this.datos[step - 2].mes_nacimiento && this.datos[step - 2].anio_nacimiento) {
                if (!this.validateAgeAndCategory(this.step)) {
                    // this.errors.add(
                    //     {
                    //         field: 'anio',
                    //         msg: 'Edad no permitida en la distancia',
                    //         rule: 'Rule Name',
                    //         scope: 'step-'+step                        
                    //     }
                    // )      

                    this.errors.add(
                        {
                            field: 'edad',
                            msg: 'Edad no permitida en la distancia',
                            rule: 'Rule Name',
                            scope: 'step-' + step
                        }
                    )
                } else {
                    // this.errors.remove('anio','step-'+step)
                    this.errors.remove('edad', 'step-' + step)
                }
            }
        },
        aplicarCupon(cupon) {
            this.codigo = cupon.codigo
            this.cupon = cupon
        },
        cerrar_disclaimer() {
            this.disclaimer_dialog = false;
            this.$router.go(-1);
        },
        aceptar_disclaimer() {
            this.disclaimer_dialog = false;
        },
        openDisclaimerDialog() {
            this.disclaimer_dialog = true;
        },
        itemText: item => item.nombre + ((item.nro_participantes > 1) ? " ( " + item.nro_participantes + " participantes )" : ""),
        formatDate(date) {
            if (!date)
                return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        parseDate(date) {
            if (!date)
                return null;
            const [month, day, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        cerrar() {
            this.dialog = false;
            if (!this.exito) {
                return false;
            }
            if (this.$router.currentRoute.name == "eventoInscripcionAdmin") {
                this.$router.go(-1);
            }
            else {
               
                this.$router.push("/");
            }
        },
        fecha_nacimiento_f(dato) {
            let day = dato.dia_nacimiento;
            let month = dato.mes_nacimiento;
            let year = dato.anio_nacimiento;
            return `${day}/${month}/${year}`;
        },
        fecha_nacimiento(dato) {
            let day = dato.dia_nacimiento;
            let month = dato.mes_nacimiento;
            let year = dato.anio_nacimiento;
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        calcularEdad(fecha_nacimiento) {
            var hoy = new Date();
            //var cumpleanos = new Date(fecha);
            var edad = hoy.getFullYear() - fecha_nacimiento.getFullYear();
            var m = hoy.getMonth() - fecha_nacimiento.getMonth();
            if (m < 0 || (m === 0 && hoy.getDate() < fecha_nacimiento.getDate())) {
                edad--;
            }
            return edad;
        },


        validateAgeAllowedModalidad(edad) {
            return this.modalidad.limite_edad_inicial <= edad;
        },

        validateCategory(sexo, edad) {
            return this.modalidad.categorias.find(categoria => {
                return categoria.sexo == sexo && categoria.edad_inicial <= edad && categoria.edad_final >= edad
            })
        },

        isValidDate(d) {
            return d instanceof Date && !isNaN(d.getTime());
        },


        validateAgeAndCategory(step) {
            let scope = "step-" + step;
            const { dia_nacimiento, mes_nacimiento, anio_nacimiento } = this.datos[step - 2];
            const fechaNacimiemto = new Date(this.fecha_nacimiento(this.datos[step - 2]));
            if (!this.isValidDate(fechaNacimiemto)) {
                return false;
            }
            let edad = this.calcularEdad(fechaNacimiemto);

            if (this.modalidad.limite_edad_inicial > 0) {
                return this.validateAgeAllowedModalidad(edad);
            }
 
            return this.validateCategory(this.datos[step - 2].sexo, edad);
        },


        siguiente() {
            let scope = "step-" + this.step;

            this.$validator.validateAll(scope).then((isOk) => {
                if (this.step > 1 && this.step < this.totalSteps) {
                    console.log(this.datos[this.step - 2].campos_custom_values);
                    console.log(this.modalidad.campos_custom);
                    if(this.modalidad.campos_custom && this.modalidad.campos_custom.length > 0){ 

                        if(Object.keys(this.datos[this.step - 2].campos_custom_values).length != this.modalidad.campos_custom.length){
                            this.errors.add(
                                {
                                    field: 'campos_custom_values',
                                    msg: 'Debe completar todos los campos',
                                    rule: 'Rule Name',
                                    scope: 'step-' + this.step
                                }
                            )
                            return false;
                    }else{
                        this.errors.remove('campos_custom_values', 'step-' + this.step)
                    }
                }}
                if (isOk) {
                    if (this.step > 1 && this.step < this.totalSteps) {
                        if (!this.validateAgeAndCategory(this.step)) {
                            this.errors.add(
                                {
                                    field: 'edad',
                                    msg: 'Edad no permitida en la distancia',
                                    rule: 'Rule Name',
                                    scope: 'step-' + this.step
                                }
                            )
                            return false;
                        } else {
                            this.errors.remove('edad', 'step-' + this.step)
                        }
                    }

                    if (this.step == 1) {
                        this.existe_init_point = "";
                        this.existe_inscripcion_msg = "";
                        this.existeInscripcionParaDNI().then(res => {
                            if (res.results) {
                                this.inscripcion_state = res.results.state;
                                if (res.results.state == "pago_pendiente") {
                                    this.existe_init_point = res.results.init_point;
                                    this.existe_inscripcion_msg = res.message;
                                }
                                else {
                                    this.existe_inscripcion_msg = res.message;
                                }
                                this.openDialogExisteInscripcion = true;
                            }
                            else {
                                this.step++;
                                scroll(0, 0);
                            }
                        });
                    }
                    else {
                        this.step++;
                        scroll(0, 0);
                    }
                }
            });


        },

        existeInscripcionParaDNI() {
            let dni = this.datos[0].documento;
            let evento_id = this.evento.id;
            this.loading = true;
            return api.existeInscripcionParaDNI(dni, evento_id)
                .then(res => {
                    this.loading = false;
                    return res;
                }).catch(error => {
                    if (error.response.status == 401) {
                        auth.logout();
                    }
                    else {
                        this.dialog = true;
                        this.exito = false;
                        this.loading = false;
                        this.mensaje_resultado = error.response.data.errors.message;
                    }
                    console.log(error);
                });
        },
        submit() {
            this.loading = true;
            let infoSubmit = {
                modalidad_id: this.modalidad.id,
                nombre: (this.nombre) ? this.nombre : "-",
                participantes: []
            };
            if (this.codigo) {
                infoSubmit.codigo = this.codigo
            }
            this.datos.forEach(dato => {
                let persona = {
                    documento: dato.documento,
                    nombre: dato.nombre,
                    apellido: dato.apellido,
                    sexo: dato.sexo,
                    email: dato.email,
                    telefono: dato.telefono,
                    telefono_emergencia: dato.telefono,
                    domicilio: dato.domicilio,
                    ciudad: dato.ciudad,
                    provincia: dato.provincia,
                    fecha_nacimiento: this.fecha_nacimiento(dato),
                    talle: dato.talle,
                    team: dato.team,
                    acepta_compartir_datos: dato.acepta_compartir_datos,
                    con_remera: dato.con_remera,
                    con_discapacidad: dato.con_discapacidad,
                    campos_custom_values: dato.campos_custom_values
                };
                infoSubmit.participantes.push(persona);
            });
            api.enviarInscripcion(infoSubmit, this.fromAdmin)
                .then(res => {
                    if (res.status == 201) {
                        this.dialog = false,
                            this.exito = true,
                            this.loading = false;
                            this.mensaje_resultado = res.message;

                            const inscripcion = res.results.inscripcion;
                   
                            //mandar a mercadopago si usa pago online y el valor de la inscripcion es distinto de 0 (con descuento aplicado + costo de servicio)

                            if (this.evento.usa_pago_online && res.results.mp_checkout ) {
                                this.mp_checkout = res.results.mp_checkout;
                                this.step++;
                            } else {
                                if(!this.evento.usa_pago_online ){
                                    this.$router.push("/resultado_ok/" + this.evento.id + "/"+ inscripcion.id  +"?next=" + encodeURIComponent("/backoffice/event/" + this.evento.id));                                
                                }else{
                                    this.$router.push("/resultado_ok/" + this.evento.id +"?next=" + encodeURIComponent("/backoffice/event/" + this.evento.id));                                
                                }
                            } 
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        auth.logout();
                    }
                    else {
                        this.dialog = true;
                        this.exito = false;
                        this.loading = false;
                        this.mensaje_resultado = error.response.data.errors.message;
                    }
                    console.log(error);
                });
        },

        openCheckInscripcionModal() {
            this.checkModalStatusOpen = true;
        }
    },

    

}
</script>
