import auth_api from '../../api/auth'
import { stat } from 'fs';

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAIL = "LOGIN_FAIL";
const LOGOUT = "LOGOUT";

// initial state
const state = {
    isLoggedIn: !!sessionStorage.getItem("access_token"),
    expires_in:sessionStorage.getItem("expires_in"),
    expires_at: (!!sessionStorage.getItem("access_token"))? ((new Date().getTime())/1000) + parseInt(sessionStorage.getItem("expires_in")):null,
    pending:false,
    user:{        
        uid:sessionStorage.getItem("uid"),
        name:sessionStorage.getItem("name"),
        roles:sessionStorage.getItem("roles")?JSON.parse(sessionStorage.getItem("roles")):[],
    }
}

 
const getters = {
    isLoggedIn: state => {
        return state.isLoggedIn
    },
    getRoles:state => {
        return state.user.roles
    },
    isAdmin:state => {
        return (state.user)?state.user.roles.includes("admin"):false;
    },
    isOrganizador:state => {
        return (state.user)?state.user.roles.includes("organizador"):false; 
    },
    expiresAt: state => {
        return state.expires_at
    },
    isPending: state => {
        return state.pending
    },
    nombreUsuario: state=>{
        return (state.user)?state.user.name:'';
    }
}

 
const actions = {
    login({ commit }, creds) {
        commit(LOGIN); // show spinner 
        return auth_api.login(creds).then((result)=>{
                sessionStorage.setItem("access_token", result.access_token);
                sessionStorage.setItem("expires_in", result.expires_in);
                sessionStorage.setItem("name", result.name);
                sessionStorage.setItem("uid", result.id);
                sessionStorage.setItem("roles", JSON.stringify(result.roles));
                sessionStorage.setItem("name",result.user_info.name);
                commit(LOGIN_SUCCESS, result); 
                return result
            }).catch((result)=>{                
                commit(LOGIN_FAIL);
                return result
            }) 
    },
    logout({ commit }) {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("expires_in");
        sessionStorage.removeItem("name");
        sessionStorage.removeItem("roles");
        commit(LOGOUT);
    }
}

 
const mutations = {
    [LOGIN] (state) {
        state.pending = true;
    },
    [LOGIN_SUCCESS] (state,payload) {
        state.isLoggedIn = true;
        state.pending = false;
        state.expires_in = payload.expires_in 
        state.expires_at = ((new Date().getTime())/1000) + payload.expires_in;
        state.user.name = payload.name;
        state.user.uid = payload.uid; 
        state.user.roles = payload.roles;
    },
    [LOGIN_FAIL] (state) {
        state.isLoggedIn = false;
        state.pending = false;
    },
    [LOGOUT](state) {
        state.isLoggedIn = false;
        state.expires_in = null;
        state.expires_at = null;
        state.user = {};
    }    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}