<template>
    <v-dialog v-model="dialogAbierto" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="60%">
        <v-card v-if="inscripcion">
            <v-card-title>
                <span class="headline">Editar Inscripción</span>
            </v-card-title>

            <v-card-text>
                <v-container elevation-3 py-3 px-1>
                    <v-form data-vv-scope="step-1" autocomplete="off">
                        <v-layout my-2 wrap>

                            <v-flex xs12 pa-2 v-if="isAdmin || (isOrganizador && !evento.usa_pago_online)">
                                <v-select data-vv-name="modalidad" :error-messages="errors.collect('step-1.modalidad')"
                                    locale="es" id="modalidad" v-validate="'required'" required
                                    v-model="inscripcionEditar.modalidad" :item-text="itemText" :return-object="true"
                                    :items="modalidades" label="Modalidad">
                                </v-select>

                            </v-flex>
                            <v-flex pa-2 xs12 v-else>
                                Modalidad: {{ inscripcionEditar.modalidad.nombre }}
                            </v-flex>
                            <v-flex pa-2 xs12>
                                <v-text-field v-model="inscripcionEditar.categoria" disabled
                                    label="Categoria (Se calcula automaticamente al guardar) ">
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12 pa-2 v-if="isAdmin || isOrganizador">
                                <v-select v-model="inscripcionEditar.state" :items="estados_inscripcion" item-text="name"
                                    item-value="value" label="Estado">
                                </v-select>
                            </v-flex>
                            <v-flex xs12 pa-2 v-else> Estado:
                                <LabelEstado :state="inscripcionEditar.state" />
                            </v-flex>

                            <v-flex xs6 pa-2 v-if="(isAdmin || isOrganizador)  ">
                                <v-text-field v-model="inscripcionEditar.numero"  
                                    label="Número Asignado">
                                </v-text-field>
                            </v-flex>
                            <v-flex xs6 pa-2 v-if="(isAdmin || isOrganizador) ">
                                <v-text-field v-model="inscripcionEditar.chip_id"  
                                    label="Chip Id">
                                </v-text-field>
                            </v-flex>


                        </v-layout>

                        <v-layout my-1 wrap>
                            <v-flex xs12 pa-2> Creada: {{ inscripcionEditar.created_at }} </v-flex>
                            <v-flex xs12 pa-2>
                                Modificada: {{ inscripcionEditar.updated_at }}
                            </v-flex>
                            <v-flex xs12 pa-2> </v-flex>
                        </v-layout>
                    </v-form>
                </v-container>
                <v-tabs v-model="model" slider-color="black">
                    <v-tab v-for="(persona, index) in inscripcionEditar.personas" :key="index + '_persona_tab'"
                        :href="`#tab-${index}`">
                        Participante {{ index + 1 }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="model">
                    <v-tab-item v-for="(persona, index) in inscripcionEditar.personas" :key="index + '_persona'"
                        :value="`tab-${index}`">
                        <v-container>
                            <EditarDatosPersona :persona="persona" />
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" flat @click="cerrarDialog">Cancelar</v-btn>
                <v-btn color="primary" flat @click="guardarDatos()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import api from "../../../api/inscripcion";
import EditarDatosPersona from "./EditarDatosPersona.vue";
import CanAccess from "../../utils/CanAccess.vue";
import LabelEstado from "../../common/LabelEstado.vue";
export default {
    props: {
        inscripcion: {
            type: Object,
            required: true,
        },
    },
    components: { EditarDatosPersona: EditarDatosPersona, CanAccess: CanAccess, LabelEstado: LabelEstado },
    data() {
        return {
            model: "tab-0",
            dialogAbierto: false,
            inscripcionEditar: Vue.util.extend({}, this.inscripcion),
        };
    },
    computed: {
        ...mapGetters("auth", [
            "isLoggedIn",
            "isAdmin",
            "isOrganizador",
            "nombreUsuario",
        ]),
        ...mapState({
            evento: (state) => state.eventos.eventoActual,
            modalidades: (state) => state.eventos.eventoActual.modalidades,
            estados_inscripcion: (state) => state.inscripcion.estados_inscripcion,
        }),
    },
    created() { },
    watch: {
        inscripcion: {
            immediate: true,
            handler(newVal, oldVal) {
                this.inscripcionEditar = Vue.util.extend({}, newVal)
            },
        },
    },
    mounted() { },
    methods: {
        abrirDialog() {
            this.dialogAbierto = true;
        },
        cerrarDialog() {
            this.dialogAbierto = false;
        },
        async guardarDatos() {

            const result = await api.updateInscripcion(this.inscripcionEditar.id, this.inscripcionEditar)
            if (!result || !result.isOk) {
                alert("Ocurrio un error")
            }
            this.dialogAbierto = false;
            this.$emit("refrescarDatos");

        },
        itemText: (item) =>
            item.nombre +
            (item.nro_participantes > 1
                ? " ( " + item.nro_participantes + " participantes )"
                : ""),
    },
};
</script>
