<style>
.flexcard {
  display:  flex !important;
  flex-direction: column;
}
</style>
<template>
<v-container fluid  >

    <v-dialog
      v-model="dialog_banner"
       fullscreen hide-overlay transition="dialog-bottom-transition"
    >
      <v-card>
            <v-toolbar dark color="orange">
                <v-btn icon dark @click="dialog_banner = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Banner</v-toolbar-title>
            
            </v-toolbar>
            <v-img
                alt="Banner Evento"
                :src=src_banner     
                contain
                 
            ></v-img>          
        
      </v-card>
    </v-dialog>

    <section xs12 > 
            <v-layout
                    column
                    align-center
                    justify-center
                    class="black--text"
                >  
               
                <v-img
                    alt="Logo Finishtime"
                    :src=logo
                    aspect-ratio="1"
                    height="200px"
                    width="200px"
                ></v-img>
                <h2  style="color:orange" class="text-xs-center flex display-2 font-weight-light">Inscripciones</h2>
            </v-layout>
            
        </section>
    
    <section>
        <v-layout
          column
          wrap
          class="my-3"
          align-center
        >
            <!-- titulo -->
            <v-flex xs12 sm4 class="my-3">
                <div class="text-xs-center"> 
                    <h3 class=" ">Próximos Eventos <v-icon>calendar_today</v-icon></h3>                    
                </div>                
            </v-flex>

            <v-flex v-if="eventosLoading " xs12 sm4 class="my-3">
                <v-progress-circular
                    :size="50"
                    color="amber"
                    indeterminate
                ></v-progress-circular>
            </v-flex>

            <v-flex v-if="!eventosLoading && items.length == 0 " xs12 sm4 class="my-3">
                No hay eventos proximamente.
            </v-flex>
             
            <v-container v-if="!eventosLoading"  fluid grid-list-lg px-0 >
                
                <v-layout row wrap > 
                    <v-flex :key="val.id" v-for="val in items" xs12 sm6 md4 lg4 xl2 >

                        <EventoCard 
                        :val="val"
                        @showBanner="showBanner"
                        :pasado="false"
                        />
 
                    </v-flex> 
                </v-layout>
            </v-container>

            <!-- Eventos pasados -->
            <v-flex v-if="items_pasados.length > 0" xs12 sm4 class="my-3">
                <div class="text-xs-center">
                    <h3 class=" ">Eventos Pasados <v-icon>assignment_turned_in</v-icon></h3>                    
                </div>                
            </v-flex>

            <v-container v-if="items_pasados.length > 0" fluid grid-list-lg px-0 >
 
                <v-layout row wrap > 
                    <v-flex :key="val.id" v-for="val in items_pasados" xs12 sm6 md4 lg4 xl2 >
                         
                        <EventoCard 
                        :val="val"
                        @showBanner="showBanner"
                        :pasado="true"
                        />
 
                    </v-flex>   
                </v-layout>
            </v-container>
            <v-flex v-if="items_pasados.length > 0"  xs12 sm4 class="my-3">
                <div class="text-xs-center">
                    <v-btn :loading="verMasLoading" small flat @click="loadNextPage" color="orange">Ver Más</v-btn> 
                </div>                
            </v-flex> 
        </v-layout>
    </section>
    
</v-container>
</template>

<script>
import { mapState } from 'vuex' 
import EventoCard from '@/components/EventoCard';
export default {
    components: { 'EventoCard': EventoCard },

    data(){
        return{
            logo:'logo.png',
            image_url:'./logo',
            eventosLoading:false,
            verMasLoading:false,
            dialog_banner:false,
            src_banner:''
        }
    },
  
    props: {
        source: String
    },
    computed: mapState({
        items: state => state.eventos.eventos_visibles,
        items_pasados: state => state.eventos.eventos_visibles_pasados
    }),
    created () {
        
 
        this.eventosLoading = true;
        this.$store.dispatch('eventos/getAllEventos').then(() => {
            this.eventosLoading = false;
        })
    },
    methods:{
        showBanner(src){
            this.src_banner = src;
            this.dialog_banner = true;
        },
        loadNextPage(){
            this.verMasLoading = true
            this.$store.dispatch('eventos/loadEventosPasadosNextPage').then(() => {
                this.verMasLoading = false;
            })
        },

        inscripcionProxima(e){
            return new Date() < new Date(e.fecha_inicio_inscripcion) 
        },
        inscripcionPasada(e){ 
            return new Date() > new Date(e.fin_inscripcion)
        }
    }
}
</script>