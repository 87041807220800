<style></style>
<template>
    <div>
       
        <v-text-field v-if="type === 'text'" v-model="value" :label="label" :required="true">
        </v-text-field>

        <v-select  v-else-if="type === 'select'" v-model="value" :label="label" :items="options" :required="true" :menu-props="{ maxHeight: '500' }">
        </v-select>

        <v-checkbox v-else-if="type === 'checkbox'" v-model="value" :label="label" :required="true"></v-checkbox>

    </div>
</template>

<script>
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        value: null
    }),
    computed: {
        label() {
            return this.config.label;
        },
        type() {
            return this.config.type;
        },
        options() {
            if(this.config.options && this.config.type === 'select')
                return [{value: null, text: 'Seleccione una opción'}, ...this.config.options.map(option => ({value: option, text: option}))];
            return [];
        }
    },
    watch: {
        value() {
            const event = {
                index: this.index,
                id: this.config.id,
                value: this.value,
                name: this.config.name,
                type: this.config.type,

            }
            this.$emit('change',event);
        }
    }
}
</script>