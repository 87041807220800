<template>
    <div>
        <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
            >
            <v-card
                color="orange"
                dark
            >
                <v-card-text>
                Espere por favor...
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-data-table :headers="headers" :items="cupones" class="elevation-1" hide-actions>
            <template v-slot:items="props">
                <td style="text-align: center;">{{ props.item.codigo }}</td>
                <td>{{ props.item.porcentaje }} %</td>
                <td>{{ props.item.fecha_inicio_validez ? props.item.fecha_inicio_validez : '' }} </td>
                <td>{{ props.item.fecha_fin_validez ? props.item.fecha_fin_validez : '' }} </td>
                <td>
                    <v-switch @change="updateActivo(props.item)" v-model="props.item.activo "  ></v-switch> 
                </td>
                <td>
                    <v-icon  @click.stop="abrirBorrarCupon(props.item)"  title="Borrar" small>delete</v-icon>
                </td>
                
            </template>
        </v-data-table>
    </div>
</template>

<script>
import cuponesApi from '../../../api/cupones';
 
export default {
    name: "CuponesDescuentoTabla",
    props: {
        evento: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            loading: false,
            cupones: [],
            headers: [
                {
                    text: 'Código',
                    align: 'start',
                    sortable: true,
                    value: 'codigo',
                },
                { text: 'Porcentaje', value: 'porcentaje', sortable: true, },
                { text: 'Valido desde', value: 'fecha_inicio_validez', sortable: false, },
                { text: 'Valido hasta', value: 'fecha_fin_validez', sortable: false, },
                { text: 'Activo', value: 'activo', sortable: true },
                { text: 'Acciones', value: 'acciones', sortable: false }  
            ],
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        updateActivo(cupon) {
            this.loading = true
            cuponesApi.updateActivo(cupon.id, cupon.activo).then(response => {
                this.$emit('updateCupon', response.data)
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
            })
        },
        loadData() {
            this.loading = true
            cuponesApi.getCuponesEvento(this.evento.id).then(response => {
                this.cupones = response.data
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
            })
        },
        abrirBorrarCupon(cupon){
            const res = confirm("¿Esta seguro que desea borrar el cupon " + cupon.codigo + "?");
            if(res){
                this.loading = true
                cuponesApi.deleteCupon(cupon.id).then(res => {
                    //this.loading = false;
                    this.loadData();
                }).catch(error => {
                    this.loading = false
                    console.log(error)
                })
            }
        }
    }
}
</script>