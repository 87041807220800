<template>
    <v-app>
        <v-navigation-drawer v-model="drawer" disable-resize-watcher fixed app>
            <v-list dense>
                <v-list-tile value="true" v-for="(item, i) in items" :key="i" :to="item.link" v-show="item.show">
                    <v-list-tile-action>
                        <v-icon v-html="item.icon"></v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title v-text="item.title"></v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>

                <v-list-tile @click="openPerfilModal" v-show="isLoggedIn">
                    <v-list-tile-action>
                        <v-icon>person</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>Perfil</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>

                <v-list-tile @click="logout" v-show="isLoggedIn">
                    <v-list-tile-action>
                        <v-icon>launch</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                        <v-list-tile-title>Salir</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>



            </v-list>
        </v-navigation-drawer>

        <v-toolbar dark fixed app>

 
            <v-btn class="hidden-sm-and-up" icon @click.stop="drawer = !drawer">
                <v-icon>menu</v-icon>
            </v-btn>
 
            <v-spacer class="hidden-sm-and-up"></v-spacer>
            <v-toolbar-side-icon>
                <router-link to="/"><v-img :src=logo height="30px" width="40px"></v-img></router-link>
            </v-toolbar-side-icon>

            <v-toolbar-title class="hidden-xs-only mx-1 px-1"><v-btn class="mx-1 px-1" flat to="/" v-text="title"
                    exact></v-btn></v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-xs-only">
             
            </v-toolbar-items>
            <v-toolbar-items class="hidden-xs-only">
                <v-btn v-show="!isLoggedIn" rigth to="/contacto" flat>Contacto</v-btn>
                <v-btn v-show="!isLoggedIn" rigth to="/" flat>Inicio </v-btn>
            </v-toolbar-items>




            <v-toolbar-items class="hidden-xs-only">
            	<v-btn v-show="isLoggedIn && isAdmin" rigth to="/admin/disciplina/lista" flat>Disciplinas </v-btn>
                <v-btn v-show="isLoggedIn && isAdmin" rigth to="/admin/evento/lista" flat>Eventos </v-btn>
                <v-btn v-show="isLoggedIn && isAdmin" rigth to="/admin/organizadores" flat>Organizadores </v-btn>
                <v-btn v-show="isLoggedIn " to="/backoffice/dashboard">Panel Inicio</v-btn>
                 <v-btn v-show="isLoggedIn && isOrganizador" to="/organizador/evento/lista">Mis Eventos</v-btn>
            </v-toolbar-items>

            <div class="hidden-xs-only" v-show="isLoggedIn">

                <v-menu bottom left v-show="isLoggedIn">
                    <template v-slot:activator="{ on }">
                        <v-toolbar-title v-on="on">
                            <v-icon dark>account_circle</v-icon>
                        </v-toolbar-title>
                    </template>

                    <v-list>
                        <v-list-tile>
                            <v-list-tile-title>{{ nombreUsuario }} </v-list-tile-title>
                        </v-list-tile>
                        <v-divider></v-divider>

                        <v-list-tile @click="openPerfilModal">
                            <v-list-tile-action>
                                <v-icon>person</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-title>Perfil</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile @click="logout">
                            <v-list-tile-action>
                                <v-icon>launch</v-icon>
                            </v-list-tile-action>

                            <v-list-tile-title v-show="isLoggedIn">Salir</v-list-tile-title>
                        </v-list-tile>

                    </v-list>
                </v-menu>
            </div>

        </v-toolbar>

        <v-content>


            <v-dialog v-model="showAlert" hide-overlay width="400">
                <v-card  :color="(alertType== 'error')? 'red' : 'green'" dark>
                    <v-card-text>
                        {{ message }}
                    </v-card-text>
                </v-card>
            </v-dialog>

            <router-view />
        </v-content>

   <v-footer
    dark
    height="auto"
  >
    <v-card
      class="flex"
      flat
      tile
    >
        

      <v-card-actions class="grey darken-3 justify-center">
        &copy;2023 — <strong>Finishtime</strong>
         |  <span v-show="isLoggedIn"> Bienvenido {{ nombreUsuario }} |</span>
            <span><v-btn flat v-show="!isLoggedIn" to="/login">Login</v-btn> </span>
            <span><v-btn class="left" v-show="isLoggedIn" @click="logout">Salir</v-btn> </span>
      </v-card-actions>
    </v-card>
  </v-footer>
         <perfil ref="perfilModal">

        </perfil>
    </v-app>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import Vuex from 'vuex'
import auth from './api/auth'
import Perfil from './components/admin/Perfil'
export default {
    components: { 'perfil': Perfil },
    data() {
        return {
            icons: [
        'fa-facebook',
        'fa-twitter',
        'fa-google-plus',
        'fa-linkedin',
        'fa-instagram'
      ],
            on: false,
            logo: 'logo.png',
            image_url: './logo',
            clipped: false,
            drawer: false,
            fixed: false,
            miniVariant: false,
            title: 'FinishTime Inscripciones'
        }
    },
    methods: {
        logout() {
            auth.logout()
        },
        openPerfilModal() {
            this.$refs.perfilModal.open();
        }
    },
    computed: {
        ...Vuex.mapGetters("auth", ["isLoggedIn", "isAdmin", "isOrganizador", "nombreUsuario"]),
        ...Vuex.mapState("app", ["showAlert", "alertType", "message"]),
        ...Vuex.mapState("auth", ["user.name"]),
        showAlert: {
            set: function (newValue) {
                this.$store.commit('app/SET_SHOW', newValue);
            },
            get: function () {
                return this.$store.state.app.showAlert;
            },
        },
        nombreUsuario: function () { return this.$store.state.auth.user.name },
        items: function () {

            return [{
                icon: 'home',
                title: 'Inicio',
                link: '/',
                show: true
            },
            {
                icon: 'phone',
                title: 'Contacto',
                link: '/contacto',
                show: true
            },
            {
                icon: 'calendar_today',
                title: 'Eventos',
                link: '/admin/evento/lista',
                show: this.isLoggedIn && this.isAdmin
            },
            {
                icon: 'person',
                title: 'Organizadores',
                link: '/admin/organizadores',
                show: this.isLoggedIn && this.isAdmin
            },
            {
                icon: 'person_outline',
                title: 'Admin',
                link: '/backoffice/dashboard',
                show: this.isLoggedIn 
            },
            {
                icon: 'calendar_today',
                title: 'Mis Eventos',
                link: '/organizador/evento/lista',
                show: this.isLoggedIn && this.isOrganizador
            },
            {
                icon: 'person_outline',
                title: 'Novedades',
                link: '/organizador/home',
                show: this.isLoggedIn && this.isOrganizador
            }]
        }

    },
    name: 'App'
}
</script>
