<style>
.panel-modalidad {
    background-color: rgb(218, 218, 218);
}
</style>

<template>
    <v-container grid-list-xl>
        <v-layout wrap row align-center justify-center>
            <v-flex md12>
                <h1>Asignación de números</h1>
                <div>
                    <div v-for="(modalidad, idx) in modalidades " class="panel-modalidad pa-4 ma-2">
                        <v-container grid-list-xl>
                            <v-layout wrap row align-center>
                                <v-flex md12>
                                    <v-layout wrap row justify-end align-content-end>
                                        <v-flex xs6>
                                            <h2>Modalidad: {{ modalidades[idx].nombre }}</h2>
                                        </v-flex>
                                        <v-flex xs6 align-self-end>
                                            <v-switch label="Asignar números" v-model="modalidades[idx].usaChip"
                                                color="orange" class="ml-auto"></v-switch>
                                        </v-flex>

                                    </v-layout>


                                    <div v-if="modalidades[idx].usaChip">
                                        <p class="pt-3">Configure la asignacion de numeros para la modalidad</p>


                                        <v-switch label="Numeracion por categorias"
                                            v-model="modalidades[idx].numeracionCategorias" color="orange"
                                            class="ml-auto"></v-switch>


                                        <v-layout wrap row align-center justify-space-around
                                            v-if="!modalidades[idx].numeracionCategorias">
                                            <v-flex m4 pa-1>

                                                <v-text-field v-model="modalidades[idx].desde" type="number" min="0"
                                                    label="Desde"></v-text-field>
                                            </v-flex>
                                            <v-flex md4 pa-1>
                                                <v-text-field v-model="modalidades[idx].hasta" type="number" min="1"
                                                    label="Hasta"></v-text-field>
                                            </v-flex>

                                            <v-flex md2 pa-1>
                                                <v-text-field disabled :value="cantidadNumeros(modalidades[idx])"
                                                    type="number" min="1" label="Cantidad Numeros"></v-text-field>
                                            </v-flex>
                                            
                                            <v-flex xs6 pa-1>
                                                <v-text-field v-model="modalidades[idx].prefijo"
                                                    hint="Texto que sera fijo en todos los numeros. Si no tienen prefijo, no ingrese nada. "
                                                    label="Prefijo"></v-text-field>

                                            </v-flex>
                                            <v-flex xs6 pa-1>
                                                <v-text-field type="number" min="1" max="10"
                                                    v-model="modalidades[idx].digitos"
                                                    label="Cantidad de digitos de los chips (1)"></v-text-field>
                                            </v-flex>
                                            
                                            </v-layout>
                                            <v-layout wrap row align-center justify-space-around
                                            >
                                                <v-flex md2 pa-1>
                                                    <v-text-field disabled :value="modalidades[idx].cantidadInscriptos"
                                                        type="number" min="1" label="Cantidad Inscriptos en modalidad"></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 pa-1>
                                                    <v-text-field v-model="modalidades[idx].faltantes"
                                                        hint="Numeros dentro del rango ingresado, que no deben ser asignados a ningun participante"
                                                        label="Nros faltantes (separados por coma)"></v-text-field>
    
                                                </v-flex>

                                            
                                        </v-layout>
                                        <span v-if="modalidades[idx].numeracionCategorias">

                                            <v-layout style="background-color: #EFEFEF;"
                                                v-for="(cat, idy) in modalidades[idx].categorias " my-3 pa-4 wrap row
                                                align-center justify-space-around>
                                                <v-flex xs12>
                                                    <h4>Categoria: {{ cat.nombre }}</h4>
                                                </v-flex>
                                                <v-flex m4 pa-1>

                                                    <v-text-field v-model="modalidades[idx].categorias[idy].desde"
                                                        type="number" min="0" label="Desde"></v-text-field>
                                                </v-flex>
                                                <v-flex md4 pa-1>
                                                    <v-text-field v-model="modalidades[idx].categorias[idy].hasta"
                                                        type="number" min="1" label="Hasta"></v-text-field>
                                                </v-flex>

                                                <v-flex md2 pa-1>
                                                    <v-text-field  
                                                        disabled
                                                        :value="cantidadNumerosCategoria(modalidades[idx].categorias[idy])"
                                                        type="number" min="1" label="Cantidad Numeros"></v-text-field>                                                   
                                                </v-flex>
                                            <span >
                                                <v-alert type="error" outline :value="cantidadNumerosCategoria(modalidades[idx].categorias[idy]) < 0">
                                                    La cantidad de numeros de la categoria es negativa
                                                </v-alert>
                                            </span>
                                            </v-layout>
                                        </span>


                                        <v-flex md12 pa-3 class=" text-xs-left">
                                            <p>1) Ingrese la cantidad de dígitos que tienen los ID de chips. Ej: Si
                                                ingresa el valor 5 => los chips quedaran con el formato: [prefijo] +
                                                00015 => FT00015.
                                                Completando con 0 (ceros) los numerales que faltan </p>
                                            Ejemplos:
                                            <div v-if="modalidades[idx].digitos > 0 && modalidades[idx].desde">
                                                {{
                                                    `${modalidades[idx].prefijo ? modalidades[idx].prefijo :
                                                        ''}${'0'.repeat(modalidades[idx].digitos
                                                            - modalidades[idx].desde.length)}${modalidades[idx].desde}` }}
                                            </div>
                                            <div v-if="modalidades[idx].digitos > 0 && modalidades[idx].hasta">
                                                {{
                                                    `${modalidades[idx].prefijo ? modalidades[idx].prefijo :
                                                        ''}${'0'.repeat(modalidades[idx].digitos
                                                            - modalidades[idx].hasta.length)}${modalidades[idx].hasta}` }}
                                            </div>

                                        </v-flex>
                                    </div>

                                </v-flex>
                            </v-layout>
                        </v-container>
                    </div>
                </div>


                <v-btn :disabled="disabledSave" :loading="loading" @click="asignarNumeros" color="primary">Asignar
                    números</v-btn>
                <v-btn :loading="loading" @click="confirmResetNumeros" color="primary">Reset Asignacion de
                    numeros</v-btn>

            </v-flex>
        </v-layout>
        <DialogEspere :loading="loading" />
    </v-container>
</template>
<script>
import Vue from 'vue'
import es from 'vee-validate/dist/locale/es';
import apiInscripcion from '@/api/inscripcion'
import { mapGetters, mapState } from "vuex";
import DialogEspere from "@/components/common/DialogEspere.vue";

const modalidadTemplate = {
    id: null,
    nombre: null,
    desde: null,
    hasta: null,
    faltantes: null,
    prefijo: null,
    digitos: null,
    usaChip: false,
    numeracionCategorias: false,
    cantidadInscriptos: 0
};

export default {
    components: {
        DialogEspere
    },
    data: () => ({
        pageLoading: false,
        loading: false,
        modalidades: [
            {
                id: null,
                nombre: null,
                desde: null,
                hasta: null,
                faltantes: null,
                prefijo: null,
                digitos: null,
                usaChip: false,
                numeracionCategorias: false,
                cantidadInscriptos: 0
            }
        ]
    }),
    methods: {
        asignarNumeros() {
            this.loading = true;
            const modalidadeAsignar = this.modalidades.filter(item => item.usaChip);

            apiInscripcion.asignarNumeros(this.evento.id, modalidadeAsignar).then(res => {
                console.log(res);
                this.$store.commit('app/SET_MESSAGE', res.data.message);
                this.$store.commit('app/SET_SHOW', true);
            }).catch(err => {
                console.log(err);
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            })
        },
        confirmResetNumeros() {
            window.confirm("¿Está seguro que desea resetear la asignación de números?") && this.resetNumeros();
        },
        resetNumeros() {
            this.loading = true;

            apiInscripcion.resetNumeros(this.evento.id).then(res => {
                console.log(res);
                this.$store.commit('app/SET_MESSAGE', res.data.message);
                this.$store.commit('app/SET_SHOW', true);
            }).catch(err => {
                console.log(err);
                this.loading = false;
            }).finally(() => {
                this.loading = false;
            })
        },
        cantidadNumeros(modalidad) {
            if (modalidad.desde && modalidad.hasta) {
                let cantbase = parseInt(modalidad.hasta) - parseInt(modalidad.desde) + 1;
                if (modalidad.faltantes) {
                    let faltantes = modalidad.faltantes.split(',');
                    return cantbase - faltantes.length;
                }
                return cantbase;
            }
            return 0;
        },
        cantidadNumerosCategoria(categoria) {
            if (categoria.desde && categoria.hasta) {
                return parseInt(categoria.hasta) - parseInt(categoria.desde);                 
            }
            return 0;
        },

        setModalidades() {
            let modalidadesMap = this.evento.modalidades.map(item => {
                return {
                    ...modalidadTemplate,
                    id: item.id,
                    nombre: item.nombre,
                    categorias: item.categorias.map(cat => {
                        return {
                            id: cat.id,
                            nombre: cat.nombre,
                            desde: null,
                            hasta: null,
                            faltantes: null,
                            prefijo: null,
                            digitos: null

                        }

                    })
                }
            });
            this.$set(this, 'modalidades', modalidadesMap);
        },

        loadModalidadaesInscriptos() {
            let eventoId = this.$route.params.id;

            apiInscripcion.getModalidadesInscriptos(eventoId).then(res => {
                this.modalidades = this.modalidades.map(item => {
                    let modalidad = res.find(m => m.id == item.id);
                    if (modalidad) {
                        item.cantidadInscriptos = modalidad.cantidad;
                    }
                    return item;
                })
            }).catch(err => {
                console.log(err);
            })
        }

    },
    created() {
        scroll(0, 0);
        this.pageLoading = true;
        let eventoId = this.$route.params.id;

        if (this.evento != undefined && this.evento.id == eventoId) {
            this.pageLoading = false;
            this.setModalidades();
        } else {
            this.$store.dispatch('eventos/getEventoById', eventoId).then((res) => {
                this.pageLoading = false;
                this.setModalidades();
            })
        }


        this.loadModalidadaesInscriptos();

    },
    computed: {
        disabledSave() {
            return this.modalidades.filter(item => item.usaChip).length == 0;
        },
        ...mapGetters("auth", [
            "isLoggedIn",
            "isAdmin",
            "isOrganizador",
            "nombreUsuario",
        ]),
        ...mapState({
            evento: state => state.eventos.eventoActual
        }),
    },
    mounted() {


    },
}
</script>
