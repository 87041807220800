<template>
    <v-container>
        <v-layout wrap row align-center justify-center>
            <v-flex md12>
                <h1>Configuración de campos personalizados</h1>
 
                {{ fields  }}
                

                <div v-for="item in fields">                     
                    <CampoCustomRenderer :config="item" />
                </div>

            </v-flex>
        </v-layout>
    </v-container>            
</template>
<script> 
import Vue from 'vue'
import es from 'vee-validate/dist/locale/es';
import api from '@/api/inscripcion'
import apiEventos from '@/api/evento'
import FormItemConfig from './components/FormItemConfig.vue'
import CampoCustomRenderer from '@/components/custom-forms/CampoCustomRenderer.vue';
 
 
export default {
    components: {
        FormItemConfig,
        CampoCustomRenderer
    },
    data: () => ({
        dialog: false,
        pageLoading: false,
        fields: [],
    }),
    methods: {
        // Funciones
        getDataFromApi() {
            this.pageLoading = true;
            this.eventoId = this.$route.params.id;
            api.getEventoById(this.eventoId).then(evento => {
                this.evento = evento;
                this.pageLoading = false;
            });

            apiEventos.getCustomFields(this.eventoId).then(fields => {
                this.fields = fields;
            })

        },

    },
    mounted() {
        scroll(0, 0);

        if (this.$route.params.id) {
            this.eventoId = this.$route.params.id
            this.getDataFromApi()
        }
 
    },
}
</script>
