<style>
.tachado {
    text-decoration: line-through;
}
</style>
<template>
    <v-container grid-list-md >

        <v-layout row wrap justify-center>
            <v-flex xs12 sm8>
                <h4 class="headline">Confirme los datos ingresados</h4>
                <v-divider></v-divider>
            </v-flex>
        </v-layout>



        <v-layout row wrap justify-center>
            <v-flex xs12 sm8>
                <h5 class="title">Modalidad - {{ modalidad.nombre }}</h5>
            </v-flex>
        </v-layout>

        <v-layout row wrap justify-center >
            <v-flex xs12 sm4 mt-4>
                <v-layout row wrap>
                    <h5 class="title">Datos de participante<span v-if="datos.length > 1">s</span></h5>
                </v-layout>

                <v-layout row wrap>
                    <v-flex v-for="(participante, index) in datos" >
                        <v-card>
                            <v-card-title v-if="datos.length > 1">Participante {{ index + 1 }}</v-card-title>
                            <div style="padding:10px">
                                <v-layout row wrap>
                                    <v-flex xs4> Documento: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].documento }} </v-flex>
                                </v-layout>

                                <v-layout row wrap>
                                    <v-flex xs4> Nombre: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].nombre }} </v-flex>
                                </v-layout>


                                <v-layout row wrap>
                                    <v-flex xs4>
                                        Apellido:
                                    </v-flex>
                                    <v-flex xs8 text-xs-right>
                                        {{ datos[index].apellido }}
                                    </v-flex>
                                </v-layout>


                                <v-layout row wrap>
                                    <v-flex xs4> Sexo: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].sexo }} </v-flex>
                                </v-layout>


                                <v-layout row wrap>
                                    <v-flex xs4> Fecha Nacimiento: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ fecha_nacimiento_f(datos[index]) }} </v-flex>
                                </v-layout>


                                <v-layout row wrap>
                                    <v-flex xs4> Provincia: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].provincia }} </v-flex>
                                </v-layout>


                                <v-layout row wrap>
                                    <v-flex xs4> Ciudad: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].ciudad }} </v-flex>
                                </v-layout>

                                <v-layout row wrap>
                                    <v-flex xs4> Domicilio: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].domicilio }} </v-flex>
                                </v-layout>

                                <v-layout row wrap>
                                    <v-flex xs4> Teléfono: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].telefono }} </v-flex>
                                </v-layout>

                                <v-layout row wrap>
                                    <v-flex xs6> Email <br> <strong>(IMPORTANTE: verifique que sea correcto)</strong>: </v-flex>
                                    <v-flex xs6 text-xs-right> {{ datos[index].email }} </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex xs4> Team: </v-flex>
                                    <v-flex xs8 text-xs-right> {{ datos[index].team }} </v-flex>
                                </v-layout>
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 sm4  mt-4>
                <v-layout layout row wrap justify-center>
                    <v-flex xs12 sm8 >
                        <h5 class="title">Resumen de Inscripción</h5>
                    </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                    <v-flex xs12 sm8 pa-3 style="background-color: rgba(255, 157, 0, 0.182);">
                        <v-layout row wrap justify-space-between>
                            <v-flex xs6>Valor</v-flex>
                            <v-flex xs6 text-xs-right> <span :class="{ 'tachado': successCupon != false }"> $ {{ valorNormal }}
                                </span></v-flex>
                        </v-layout>
                        <v-layout v-if="successCupon" row wrap justify-center>
                            <v-flex xs6>Valor con descuento:</v-flex>
                            <v-flex xs6 text-xs-right>$ {{ valorInscripcion }}</v-flex>
                        </v-layout>
 
                        <v-layout row wrap justify-space-between v-if="modalidad.costo_servicio > 0">
                            <v-flex xs6>Costo servicio FinishTime</v-flex>
                            <v-flex xs6 text-xs-right>  $ {{ modalidad.costo_servicio }} </v-flex>
                        </v-layout>

                        <v-layout v-if="inscripcionConCheckRemera" row wrap justify-center>
                            <v-flex xs6>Valor adicional por remera</v-flex>
                            <v-flex xs6 text-xs-right>$ 3000</v-flex>
                        </v-layout>
                       
                        <v-layout  row wrap justify-center>
                            <v-flex xs6 class="subheading font-weight-bold">Total</v-flex>
                            <v-flex xs6 text-xs-right class="subheading font-weight-bold">$ {{ total }}</v-flex>
                        </v-layout>



                        <v-layout  row wrap justify-center>
                            <v-btn v-if="!showCupon" small @click="showCupon = true">Tengo un cupon de descuento</v-btn>
                            <v-flex v-if="showCupon" xs12 subheading>
                                <p>Ingresá tu cupón de descuento:</p>

                                <v-text-field v-model="cupon" locale="es" data-vv-name="Cupón" :name="'cupon'" label="Cupón"
                                    :error-messages="this.errorCupon" :success="this.successCupon != false"
                                    :success-messages="this.successCupon"
                                    :disabled="this.successCupon != false || loadingCupon" type="text">
                                </v-text-field>

                                <v-btn block :loading="loadingCupon" :disabled="this.successCupon != false"
                                    @click="validarCupon" small color="orange" dark>
                                    Aplicar
                                </v-btn>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
import validarCuponApi from '../../api/validarCupon';
Validator.localize('es', es);
Vue.use(VeeValidate);

export default {
    data: vm => ({
        errorCupon: false,
        successCupon: false,
        loadingCupon: false,
        showCupon: false,
        porcentaje: null,
        valor_final: null,
        valor_normal: null
    }),
    computed: {
        valorNormal() {
            return this.modalidad.valor_normal;
        },
        valorInscripcion() {
            if (this.valor_final !== false && this.valor_final !== null)
                return this.valor_final
            else
                return this.modalidad.valor_normal;
        },
        inscripcionConCheckRemera() {
            return this.evento.id == 164 && this.datos[0].con_remera;
        },
        totalConCheckConRemera() {
            return parseFloat(this.valorInscripcion) + parseFloat(3000);
        },
        total() {
            return parseFloat(this.valorInscripcion)  + this.modalidad.costo_servicio;
        }
    },
    props: {
        modalidad: Object,
        evento: Object,
        datos: Array,

    },
    methods: {
        validarCupon() {

            this.loadingCupon = true;
            this.errorCupon = false;
            validarCuponApi({ codigo: this.cupon, evento_id: this.evento.id, modalidad_id: this.modalidad.id })
                .then(res => {
                    this.porcentaje = res.porcentaje;
                    this.valor_final = res.valor_final;
                    this.valor_normal = res.valor_normal;
                    this.successCupon = "Cupón valido: " + res.porcentaje + " descuento";
                    //tendria que venir en la respuesta
                    res.codigo = this.cupon;
                    this.$emit('aplicarCupon', res);
                }).catch(error => {
                    this.errorCupon = error;
                }).finally(() => {
                    this.loadingCupon = false;

                })
        },
        fecha_nacimiento_f(dato) {
            let day = dato.dia_nacimiento;
            let month = dato.mes_nacimiento;
            let year = dato.anio_nacimiento;
            return `${day}/${month}/${year}`;
        },
    },

}
</script>