<style scoped >
.contacto {
    display: grid;
    justify-content: center;
    max-width: 100%;
    margin: auto;
}

h3 {
    text-align: center;
    font-family: sans-serif;
}

.inner_container {
    margin: 0 20px;
    display: flex;
    justify-content: center;
}

li {
    font-size: 15px;
    margin-top: 15px;

}

.redes {
    display: flex;
    padding: 0;
}

.logo {
    width: 35px;
    height: 30 px;
    margin: 5px 15px 5px 15px;
}

.redes>a {
    text-decoration: no;
}

ul {
    list-style-type: none;
    padding: 0;
}

.logo {
    size: 40px;
    margin-left: 25px;
}

.logo-negro {
    filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(1000%);
    margin-right: 15px;
}

.inner_container ul {
    list-style-type: none;
    padding: 0;
}

.inner_container ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.inner_container ul li img {
    margin-right: 10px;
}

.inner_container ul li span {
    margin-left: auto;
}
</style>
<template>
    <v-container>

        <section class="contacto">
            <h3 class="text-xs-center flex display-2 font-weight-light" style="color:orange">Contactanos</h3>
            <div class="inner_container">
                <ul>
                    <li><img class="logo-negro" src="../png/006-ubicacion.png" alt=""> Chivilcoy, Buenos Aires, Arg.
                    </li>
                    <li>

                        <img class="logo-negro" src="../png/001-correo-electrnico-vaco.png" alt="">
                        <a href="mailto: finishtimechivilcoy@gmail.com">
                            finishtimechivilcoy@gmail.com
                        </a>
                    </li>
                    <li><img class="logo-negro" src="../png/005-llamada.png" alt="">2346654774</li>
                </ul>
            </div>
            <div class="inner_container">
                <ul class="redes">
                    <li>
                        <a href=" https://www.facebook.com/people/Finish-Time-Chivilcoy/100057559483655/"
                            target="_blank"><img class="logo" src="../png/001-facebook.png" alt="Facebook"></a>
                    </li>

                    <li>
                        <a href="https://www.instagram.com/finishtimechivilcoy/" target="_blank"><img class="logo"
                                src="../png/003-instagram.png" alt="Instagram"></a>
                    </li>

                    <li class="hidden-xs-only">
                        <a href="https://web.whatsapp.com/send?phone=5492346654774" target="_blank"><img class="logo"
                                src="../png/002-whatsapp.png" alt="Whatsapp"></a>
                    </li>
                    <li class="hidden-sm-and-up">2346654774
                        <a href="https://wa.me/5492346654774" target="_blank"><img class="logo"
                                src="../png/002-whatsapp.png" alt="Whatsapp"></a>
                    </li>

                </ul>
            </div>
        </section>

    </v-container>
</template>

<script>
// a esta parte no le des bola, despues te explico y podemos ver como pasar datos desde aca al template,
//y hasta como obtenerlos desde alguna propiedad de configuracion  s<
export default {
    data: (vm) => ({

    })
}
</script>

 

