<style>
.bottom-bar {
    position: fixed;
    bottom: 40px;
    width: 100%;
    background-color: white;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 5px;
    z-index: 1000;
}
.number-heading {
    background-color: #f5f5f5;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 20px;
    border-bottom: 1px solid #CCC;
    text-align: left;
}

.inscripciones-row td{
    padding: 5px 20px;
    text-align: left;
}

.inscripciones-row td:last-child{
    
    text-align: right;
}


</style>
<template>
    <div v-if="loadingEvento">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </div>
    <div v-else>
        <v-flex>
            <v-card>
                <v-card-content>
                    <InscripcionesTablaEditable :items="items" :evento="evento"
                        @refrescarDatos="() => { this.getDataFromServer() }" @paginationChange="paginationChange"
                        :loading="loading" :totalItems="totalItems" ref="inscripcionesTabla" />
                </v-card-content>
            </v-card>
            <v-layout v-if="!loading" row wrap justify-center>
                <v-flex class="bottom-bar" xs12 md6>
                    <v-btn :disabled="loading" @click="validarDuplicados()" color="orange">Guardar Numeros</v-btn>
                    <v-btn outline secondary @click="() => $router.go(-1)" color="secondary">Cancelar</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>

        <v-dialog v-model="confirmDuplicatedDialog" max-width="500px" persistent>

            <v-card style="text-align: center;" >
                <!-- <v-card-title class="headline">Números duplicados</v-card-title> -->
                <v-card-text>
                    <v-alert  type="warning" value="true">

                        Hay números duplicados en la asignación. Por favor, verifique.
                    </v-alert>
                        <div>
                            <table width="100%" style="border: 1px solid #CCC;">
                                <tr v-for="numero in Object.keys(duplicados)" :key="numero">
                                    <td>
                                        <table width="100%" >
                                            <tr>
                                                <td colspan="3" class="number-heading"  > -- {{ numero }} -- </td>
                                            </tr>
                                            <tr class="inscripciones-row" v-for="inscripcion in duplicados[numero]" :key="inscripcion.id">
                                                <td>{{ getApellido(inscripcion) }}, {{ getNombre(inscripcion) }}</td>
                                                <td>{{ inscripcion.categoria_anio_calendario }}</td>
                                                <td>{{ inscripcion.modalidad.nombre }}</td>
                                            </tr>
                                        </table >
                                    </td>
                                    
                                    
                                </tr>
                            </table>
                        </div>
                    </v-alert>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="guardarAsignacionNumeros()" color="primary">Aceptar y guardar</v-btn>
                        <v-btn @click="confirmDuplicatedDialog = false" color="secondary">Cancelar</v-btn>

                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/inscripcion'

import InscripcionesTablaEditable from './InscripcionesTablaEditable.vue'

export default {
    components: {
        "InscripcionesTablaEditable": InscripcionesTablaEditable
    },
    data() {
        return {
            saving: false,
            loadingEvento: true,
            loading: false,
            totalItems: 0,
            items: [],
            pagination: {
                search: '',
                descending: false,
                page: 1,
                rowsPerPage: 20,
                sortBy: "id",
                totalItems: 0,
                filters: {
                    modalidad: ''
                },
            },
            confirmDuplicatedDialog: false,
            duplicados: []
        }
    },
    computed: mapState({

        evento: state => state.eventos.eventoActual,

        query: function () {
            let filters = {
                search: this.pagination.search,
                filters: {
                    estados: this.selectedStatesFilter.value
                }
            }
            if (this.selectedModalidadFilter) {
                filters.filters.modalidad = this.selectedModalidadFilter;
            }
            return filters;
        }

    }),
    created() {
        scroll(0, 0);
        let eventoId = this.$route.params.id;
        this.$store.dispatch('eventos/getEventoById', eventoId).then(() => {
            this.loadingEvento = false;
        })
    },
    watch: {
        pagination: {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        }
    },
    mounted() {

    },
    methods: {
        paginationChange(pagination) {
            this.pagination = { ...this.pagination, ...pagination }
        },

        getDataFromApi() {
            this.loading = true;
            let eventoId = this.$route.params.id;

            api.getAllInscriptosEventosAsignarNumero(eventoId, this.pagination).then((res) => {
                this.items = res.data;
                this.totalItems = res.total;
                this.loading = false;
            })
        },

        validarDuplicados() {
            this.duplicados = {};
            clearInterval
            let itemsTemp = this.$refs.inscripcionesTabla.getMappedItems();

            
            let duplicados = itemsTemp.reduce((acc, inscripcion) => {
                if (inscripcion.numero && inscripcion.numero !== '') {
                    if (!acc[inscripcion.numero]) {
                        acc[inscripcion.numero] = [];
                    }
                    acc[inscripcion.numero].push(inscripcion);
                }
                return acc;
            }, {});

            const dd = Object.keys(duplicados).filter(numero => duplicados[numero].length > 1);

            for(let i = 0; i < dd.length; i++){
                 this.duplicados[dd[i]] = duplicados[dd[i]];
            }

            
            if (Object.keys(this.duplicados).length > 0) {
                this.confirmDuplicatedDialog = true;                 
                return false;
            }else{
                this.guardarAsignacionNumeros();
            }

        },

        

        guardarAsignacionNumeros() {
            

            this.saving = true;
            let itemsTemp = this.$refs.inscripcionesTabla.getMappedItems();


            const pairs = itemsTemp.map(inscripcio => {
                return {
                    id: inscripcio.id,
                    numero: inscripcio.numero
                }
            })

            api.asignarNumerosManual(this.$route.params.id, pairs).then((res) => {
                if (res.status == 201) {
                    this.$store.commit("app/SET_MESSAGE", res.data.message);
                    this.$store.commit("app/SET_SHOW", true);
                }
                this.$router.replace('/backoffice/event/' + this.$route.params.id);

            }).finally(() => {
                this.saving = false;
            })
        },

        getNombre(inscripcion) {
            let nombre = ''
            if (inscripcion.personas.length == 1) {
                nombre = inscripcion.personas[0].nombre;
            } else {
                inscripcion.personas.forEach((p, idx) => {
                    nombre += (idx > 0) ? " / " : "";
                    nombre += p.nombre;
                });
            }
            return nombre;
        },

        getApellido(inscripcion) {
            let apellido = ''
            if (inscripcion.personas.length == 1) {
                apellido = inscripcion.personas[0].apellido;
            } else {
                inscripcion.personas.forEach((p, idx) => {
                    apellido += (idx > 0) ? " / " : "";
                    apellido += p.apellido;
                });
            }
            return apellido;
        },

    }

}
</script>
