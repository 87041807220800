import Vue from 'vue'
import Vuex from 'vuex'
import inscripcion from './modules/inscripcion'
import eventos from './modules/eventos'
import auth from './modules/auth'
import disciplinas from './modules/disciplinas'
import app from './modules/app'

//import createLogger from '../../../src/plugins/logger'
 
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
   
  modules: {
    app,
    inscripcion,
    eventos,
    auth,
    disciplinas
  },
  strict: debug,
  //plugins: debug ? [createLogger()] : []
})
