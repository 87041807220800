<template>
    <v-dialog v-model="openPendingPaymentDialog" :max-width="maxWidth" :fullscreen="fullScreen" persistent>
        <v-card>
            <v-card-title class="headline">Pagar en MercadoPago </v-card-title>

            <v-card-text>

                <!-- <div v-if="!verificationResult.tiene_cupon">           
                    <h3 class="subheading">
                        Si tenes un cupón de descuento ingresalo aquí:
                    </h3>
                    <div>
                        <v-layout row wrap>
                            <v-flex xs8>
                                <v-text-field v-model="code" locale="es" :id="'validateCupon'" v-validate="'required'"
                                    data-vv-name="validateCupon" :data-vv-scope="'cuponForm'" required
                                    :name="'validateCupon'" label="Cupon"
                                    :error-messages="errors.collect('cuponForm.validateCupon')" type="text"
                                    :disabled="cuponApplied.code"></v-text-field>
                            </v-flex>
                            <v-flex xs4>
                                <v-btn :disabled="cuponApplied.code" :loading="loading" @click="agregarCupon" flat
                                    color="primary">Agregar</v-btn>
                            </v-flex>
                            {{ this.subErrorMessage }}
                        </v-layout>
                    </div>
                </div> -->

                <div style="max-width: 400px; margin: 0 auto;">
                    <v-layout row wrap>
                        <v-flex xs12 mb-3 class="title" > 
                            Inscripcion - {{ verificationResult.modalidad }}
                        </v-flex>
                    </v-layout>
                    

                    <v-layout   row wrap justify-center>
                        <v-flex xs6>Monto:</v-flex>
                        <v-flex xs6 text-xs-right>${{ verificationResult.ammount }}</v-flex>
                    </v-layout>

                    <v-layout row wrap justify-space-between>
                        <v-flex xs6>Costo servicio FinishTime</v-flex>
                        <v-flex xs6 text-xs-right>  ${{ verificationResult.costo_servicio }} </v-flex>
                    </v-layout>

                 
                    <v-layout  row wrap justify-center>
                        <v-flex xs6 class="subheading font-weight-bold">Total</v-flex>
                        <v-flex xs6 text-xs-right class="subheading font-weight-bold">$ {{ total }}</v-flex>
                    </v-layout>

                    
                    <!-- <v-layout align-space-between justify-space-between row fill-height>

                        <div v-if="tieneCupon">
                            Descuento ({{ cuponCode }} - {{ discountPercent }}% )
                        </div>
                        <div v-if="tieneCupon">
                            $ -{{ discountValue }} 
                        </div>
                    </v-layout> -->
                    <!-- <v-layout mt-3 align-space-between justify-space-between row fill-height>

                        <div class="title"> Total
                        </div>
                        <div class="title"> 
                            ${{ verificationResult.ammount }}
                        </div>

                    </v-layout> -->
                </div>



                <v-flex xs12 subheading>
                    <v-btn :href="mercadoPagoInitPoint" block color="#009ee3" dark>Pagar En MercadoPago</v-btn>
                </v-flex>
                <v-flex xs12 subheading>
                    <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/575X40.jpg"
                        title="MercadoPago - Medios de pago" alt="MercadoPago - Medios de pago" width="575" height="40" />
                </v-flex>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" flat="flat" @click="closeDialog">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import Vue from 'vue'
Validator.localize('es', es);
Vue.use(VeeValidate);

import api from '../../api/inscripcion';

const SUCCESS_INSCRIPCION_STATES = ['pago_realizado', 'pago_bonificado'];


export default {
    props: {
        openPendingPaymentDialog: {
            type: Boolean,
            default: false
        },
        initPoint: {
            type: String,
            default: null
        },
        verificationResult: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            SUCCESS_INSCRIPCION_STATES: SUCCESS_INSCRIPCION_STATES,
            code: null,
            cuponApplied: {}
        }
    },
    watch: {
        openPendingPaymentDialog(val) {
            if (val) {

            }
        }
    },
    mounted() {
        this.$validator.localize("es", this.dictionary);
    },
    computed: {
        total() {
            return parseFloat(this.verificationResult.ammount) + parseFloat(this.verificationResult.costo_servicio);
        },
        maxWidth () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'none';
                default: return '800px';
            }
        },
        fullScreen(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return true;
                default: return false;
            }
        },
        
        cuponCode() {
            if (this.verificationResult.tiene_cupon) {
                return this.verificationResult.codigo;
            } else if (this.cuponApplied.code) {
                return this.cuponApplied.code;
            } else {
                return "";
            }
        },
        mercadoPagoInitPoint() {
            if (this.verificationResult) {
                return this.verificationResult.init_point;
            } else {
                return false
            }
        },
        discountValue() {
            if (this.verificationResult.tiene_cupon) {
                return this.verificationResult.valor_normal - this.verificationResult.ammount;
            } else if (this.cuponApplied.code) {
                return this.verificationResult.valor_normal - this.cuponApplied.ammount;
            } else {
                return 0;
            }
        },
        discountPercent() {
            if (this.verificationResult.tiene_cupon) {
                return this.verificationResult.porcentaje;
            } else if (this.cuponApplied.code) {
                return this.cuponApplied.discount;
            } else {
                return 0;
            }
        },
        tieneCupon() {
            if (this.verificationResult.tiene_cupon || this.cuponApplied.code) {
                return true;
            } else {
                return false;
            }
        },
        // ammountToPay() {
        //     let ammount = this.verificationResult.valor_normal;
        //     if (this.verificationResult.tiene_cupon) {
        //         ammount = this.verificationResult.ammount;
        //     } else if (this.cuponApplied.code) {
        //         ammount = this.cuponApplied.ammount;
        //     }
        //     return ammount;
        // },
    },
    methods: {
        closeDialog() {
            this.$emit('closePendingPaymentInscriptionDialog');
        },
        agregarCupon() {
            this.loading = true
            api.agregarCupon(this.verificationResult.id, this.code)
                .then((response) => {
                    this.cuponApplied = { code: this.code, ammount: response.new_value };
                }).catch((error) => {
                    alert("Ocurrió un error al aplicar el cupón, intente mas tarde");
                   
                }).finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>