import axios from 'axios'
import auth from './auth'



export const DISCIPLINAS_BASE_PATH = 'disciplinas';
  

export default {

    getDisciplinaById(id){       
        let url = DISCIPLINAS_BASE_PATH + "/"+ id;
        return axios.get( url)
            .then(res=> res.data);
            
    },

    getDisciplinasListado(params){
       let url = DISCIPLINAS_BASE_PATH + '/list';
        var options = {
            params:{
                'page':params.page,
                'direction':params.descending?'DESC':'ASC',
                'sortBy':params.sortBy,
                'rowsPerPage':params.rowsPerPage
            }
        }; 
        
        
        return axios.get(url, options)
            .then(res=> res.data)
            .catch(res=>{ 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                }
            }); 
    },
    
    
    
	getDisciplinas(){ 
		return axios.get(DISCIPLINAS_BASE_PATH)
		.then(res=> res.data)
		.catch(res=>{ 
            if(res.response){                   
                if(res.response.status == 401){
                    auth.logout();
                }
            }
		}); 
	},

    actualizarDisciplina(id, parameters){
        let url = DISCIPLINAS_BASE_PATH + "/" + id;
        return axios.put(url, parameters).
            then(res => res.data)
            .catch(res => { 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                    else{
                        throw res; //lanzara una excpeción, la captura el catch mas cercano en la cadena de Promise en el retorno
                    }
                }
            }); 
    },

    crearDisciplina(parameters){
        return axios.post(DISCIPLINAS_BASE_PATH, parameters)
            .then(res => res.data)
            .catch(res => { 
                if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
                    else{
                        throw res; //lanzara una excpeción, la captura el catch mas cercano en la cadena de Promise en el retorno
                    }
                }
            }); 
    },
    
	borrarDisciplina(id){
		let url = DISCIPLINAS_BASE_PATH + "/" + id; 
		return axios.delete(url)
			.then((res) => res.data)
			.catch(res=>{ 
				if(res.response){                   
                    if(res.response.status == 401){
                        auth.logout();
                    }
				}
				
			}); 
	},
}
