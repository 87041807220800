const SET_MESSAGE = "SET_MESSAGE";
const SET_TYPE = "SET_TYPE";
const SET_SHOW = "SET_SHOW"; 
 

// initial state
const state = {
    message:'',
    alertType:'success',
    showAlert:false    
}

 
const getters = {
     
}

 
const actions = { 
    reset (context) {
        context.commit(SET_MESSAGE,"");
        context.commit(SET_TYPE,"success");
        context.commit(SET_SHOW,false);
    }
}

 
const mutations = {
    [SET_MESSAGE] (state,message) {
        state.message = message;
    },
    [SET_TYPE](state,alertType) {
        state.alertType = alertType;
    },
    [SET_SHOW](state,show) {
        state.showAlert = show;
        if(show)
            setTimeout(() => {state.showAlert = false}, 1500)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}