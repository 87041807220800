<template>
    <v-btn depressed small :color="getColorEstado(state)">
        {{ state }}
    </v-btn>
</template>

<script>
export default {
    data() {
            return {
                
            }
        },
    props:{
            state: String
        },
    methods: {
        getColorEstado( ){
            let cssClass = '';
            if( this.state == 'pago_pendiente'){
                cssClass = 'yellow lighten-1'
            }
            if( this.state == 'pago_realizado'){
                cssClass = 'green lighten-3'
            }
            return cssClass;
        },
    }
}
</script>