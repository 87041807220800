<template>
  <v-layout align-center justify-center>
    <div v-if="loading" class="my-3 xs12 sm4">
      <v-progress-circular
        :size="50"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-flex v-if="!loading">
      <section>
        <v-parallax :src="evento.image_full_path" height="300">
          <v-layout column align-center justify-center class="black--text">
            <v-card
              color="white lighten-2"
              class="align-center elevation-23 black--text"
            >
              <v-card-title min-width="500" primary-title>
                <div>
                  <div class="headline">{{ evento.nombre }}</div>
                  <span>{{ evento.lugar }}</span>
                </div>
              </v-card-title>
            </v-card>

            <v-btn
              v-if="evento.inscripcion_abierta"
              color="#fa5b3f"
              class="elevation-23 mt-5"
              dark
              large
              :to="{ name: 'inscripcion', params: { id: evento.id } }"
            >
              Inscripcion
              <v-icon dark right>create</v-icon>
            </v-btn>
          </v-layout>
        </v-parallax>
      </section>

      
        <div>
          <v-toolbar color="#fb5610" dark tabs>
            <v-spacer></v-spacer>
            <v-toolbar-title>Resultados y fotos</v-toolbar-title>
            <v-spacer></v-spacer>


            <template v-slot:extension>
                  
              <v-tabs
                v-model="model"
                centered
                color="#fb5610"
                slider-color="black"
                show-arrows
              >
                <v-tab
                  v-for="( link, index) in JSON.parse(evento.event_result_links)"
                  :key="link.textoBoton"
                  :href="`#tab-${index}`"
                >
                  {{ link.textoBoton }}
                </v-tab>
                <v-tab
                  :key="'fotos'"
                  :href="`#tab-fotos`"
                >
                  Fotos <v-icon>photo</v-icon>
                </v-tab>
              </v-tabs>
              
            </template>
          </v-toolbar>

          <v-tabs-items v-model="model">
            <v-tab-item
              v-for="(link, index) in JSON.parse(evento.event_result_links)"
            
              v-show="link.url"
              :key="link.textoBoton"
              :value="`tab-${index}`"
            >
              <v-container>
                <v-card flat>                  
                  <iframe
                    :src="getIframeURL(link.url)"
                    width="100%"
                    height="680px"
                  >
                  </iframe>
                  <v-btn flat color="primary" :href="link.url">Descargar</v-btn>
                </v-card>
              </v-container>
            </v-tab-item>

            <v-tab-item
              
              :key="'fotos'"
              :value="`tab-fotos`"
            >
              <v-container>
                <v-card flat>
                    <h3>Fotos</h3>
                    <ul>
                        <div v-for="link in JSON.parse(evento.photo_album_links)">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn dark v-on="on"  outline color="secondary" :href="link.url">{{link.textoBoton}} </v-btn>
                                </template>
                                <span>Album en Facebook</span>
                            </v-tooltip>
                        </div>
                    </ul>
                </v-card>
              </v-container>
            </v-tab-item>


          </v-tabs-items>
        </div>
        <v-btn dark fab fixed bottom left @click="back" color="secondary">
          <v-icon>arrow_back</v-icon>
        </v-btn>
       
    </v-flex>
  </v-layout>
</template>

<script>
import Vue from "vue";
import {  mapState } from "vuex";

export default {
  data: (vm) => ({
    model: 'tab-0',
    loading: true,
  }),

  computed: mapState({
    evento: (state) => state.eventos.eventoActual,
    modalidades: (state) => state.eventos.eventoActual.modalidades,
  }),

  created() {
    scroll(0, 0);
    let eventoId = this.$route.params.id;
    this.loading = true;
    this.$store.dispatch("eventos/getEventoById", eventoId).then((res) => {
      this.loading = false;
    });
  },

  watch: {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    
    getIframeURL(sharingURL) {
      if(sharingURL){
        const parts = sharingURL.split("/");
        const fileId = parts[5];
        return `https://docs.google.com/viewer?srcid=${fileId}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`;

      }
      return ''
    },


  },
};
</script>
