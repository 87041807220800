import disciplinaAPI from '../../api/disciplina'


// initial state
const state = {
    disciplinas_listado:[],
    disciplinaActual:{},
    disciplinas_todas:[]
}

// getters
const getters = {
     
}

// actions
const actions = {
 
    getDisciplinaById({ commit }, disciplinaId){  
        return disciplinaAPI.getDisciplinaById(disciplinaId).then( disciplina => {
            commit('setDisciplinaActual', disciplina)
        })
    },
    
    getDisciplinasListado({ commit }, params){
        return disciplinaAPI.getDisciplinasListado(params).then( (disciplinas) => {
                commit('setDisciplinasListado', disciplinas);
            })
    },
    
    getDisciplinas({ commit }){
    	return  disciplinaAPI.getDisciplinas().then( (disciplinas) => {
                commit('setDisciplinas', disciplinas);
            })
    },
    
}

// mutations
const mutations = {
    setDisciplinasListado(state, disciplinas) { 
        state.disciplinas_listado = disciplinas
    },
    
    setDisciplinaActual (state, disciplina) {
        state.disciplinaActual = disciplina
    },
    
    setDisciplinas (state, disciplinas) {
        state.disciplinas_todas = disciplinas
    },

    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
