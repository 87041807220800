<template>
    <v-dialog v-model="show" max-width="800px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">Enviar email</span>
            </v-card-title>


            <v-card-text>

                <v-text-field v-model="subject" label="Asunto" placeholder="Ingrese el asunto del email"  ></v-text-field>

                <v-text-field v-if="!allSelected" disabled   :value="emailsList.map(email => email).join(', ')" label="Destinatarios"
                    placeholder="Placeholder" box></v-text-field>

                <v-alert outline v-show="allSelected" color="warning">
                    El email se enviara a todos los inscriptos de la busqueda actual 
                </v-alert>
                
                <v-container grid-list-md>
                    <ckeditor :editor="editor" v-model="body" :config="editorConfig"></ckeditor>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-1" flat @click="cancel">Cancelar</v-btn>
                <v-btn :disabled="loading" :loading="loading"  color="primary"   @click="sendEmails">
                    Enviar Emails
                    <template v-slot:loader>
                        <span>Enviando...</span>
                    </template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "../../../api/inscripcion";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    props: {
        inscriptions: {
            type: Array,
            required: false,
        },
        allSelected: {
            type: Boolean,
            required: true,
        },
        query:{
            type: Object,
            required: false,
        },
        eventId:{
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
            },
            ids: [],
            show: false,
            loading: false,
            body: "",
            subject: ""

        };
    },

    methods: {
        openDialog(ids) {
            this.ids = ids;
            this.show = true;
        },
        cancel(){
            let res = window.confirm("¿Desea cancelar el envio de emails?");
            if(res){
                this.closeDialog();
            }
        },
        closeDialog() {
            this.show = false;
        },

        async sendEmails() {
            try {
                this.loading = true;   
                
                
                let searchCriteria = this.query ? this.query : null;

                let requestBody = {
                    event_id: this.eventId,
                    email:{
                        subject: this.subject,
                        body: this.body
                    },
                    send_all_search: this.allSelected,
                    inscriptions_id: this.idsList,
                    search_criteria: searchCriteria
                };

                let result = await api.sendEmails(requestBody);
                

                if (result && result.status == 'ok') {
                    this.$store.commit('app/SET_MESSAGE', result.message);
                    this.$store.commit('app/SET_SHOW', true);
                }else{
                    alertr("Ocurrio  un error al enviar los emails  ");
                    // this.$store.commit('app/SET_MESSAGE', "Ocurrio  un error al enviar los emails  ");
                    // this.$store.commit('app/SET_TYPE', 'error');
                    // this.$store.commit('app/SET_SHOW', true);
                }


                this.show = false;
                this.$emit("refrescarDatos");
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    },
    computed: {
        emailsList: function () {
            return this.inscriptions.reduce((emails, inscripcion) => {
                return emails.concat(inscripcion.personas.map(persona => persona.email));
            }, []);
        },

        idsList: function () {
            return this.inscriptions.reduce((ids, inscripcion) => {
                return ids.concat(inscripcion.id);
            }, []);
        },
    }
};
</script>