const DESDEADMIN = "DESDEADMIN";
const NODESDEADMIN = "NODESDEADMIN";

// initial state
const state = {
  estados_inscripcion: [
    {
      name: "PAGO PENDIENTE",
      value: "pago_pendiente",
    },
    {
      name: "PAGO REALIZADO",
      value: "pago_realizado",
    },
    {
      name: "PAGO BONIFICADO",
      value: "pago_bonificado",
    },
  ],
  desdeAdmin: false,
};

// getters
const getters = {};

// actions
const actions = {
  setDesdeAdmin({ commit }) {
    commit(DESDEADMIN);
  },
};

// mutations
const mutations = {
  [DESDEADMIN](state) {
    state.desdeAdmin = true;
  },
  [NODESDEADMIN](state) {
    state.desdeAdmin = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
