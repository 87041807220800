<template>
     
    <v-layout align-center justify-center>
        <v-flex>
           
            <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
            <v-btn color="orange" rigth to="/admin/evento/nuevo"  >
                Crear Evento
                <v-icon right small>library_add</v-icon>
            </v-btn> 

             
            <section>
                <v-card>
                    <v-card-title>
                      <h1 class="mx-2">Eventos</h1>    
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="pagination.search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mx-2"
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :total-items="totalItems"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :items="items" 
                    class="elevation-3"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.id }}</td>
                        <td class="text-xs-left">{{ props.item.nombre }}</td>
                        <td class="text-xs-left">{{ props.item.fecha_hora | formatDateTime }}</td>
                        <td class="text-xs-left">{{ props.item.ciudad }}</td>
                        <td class="text-xs-left">{{ props.item.organizador.nombre }}</td>
                        <td class="text-xs-left">{{ props.item.fin_inscripcion | formatDateTime }}</td>
                        <td class="text-xs-center">{{ props.item.total_inscriptos }}</td>
                        <td class="text-xs-center">
                            <v-icon v-if="props.item.usa_pago_online" alt="editar" small color="green" > brightness_1</v-icon>
                            <v-icon v-if="!props.item.usa_pago_online" alt="editar" small > brightness_1</v-icon>
                        </td>
                        <td class="text-xs-center">
                            <v-icon v-if="props.item.visible" alt="editar" small color="green" > brightness_1</v-icon>
                            <v-icon v-if="!props.item.visible" alt="editar" small > brightness_1</v-icon>
                        </td>
                        <td class="text-xs-center">
                            <v-btn title="Editar" flat  icon  :to="'/admin/evento/'+props.item.id+'/editar'">
                                <v-icon alt="editar" small > edit</v-icon>
                            </v-btn>
                            <v-btn title="Ver Inscriptos"  :to="'/backoffice/event/'+props.item.id" icon  flat    >
                                <v-icon small  >notes</v-icon>
                            </v-btn>
                            <v-btn   title="Nueva inscripción"  :to="'/admin/evento/'+props.item.id+'/inscripcion'" icon  flat    >
                                <v-icon small>person_add</v-icon>
                            </v-btn>
                            <v-btn   title="Agregar clasificacion y fotos"  :to="'/admin/evento/'+props.item.id+'/adjuntos'" icon  flat    >
                                <v-icon small>file_upload</v-icon>
                            </v-btn>
                        </td>
                    </template>
                    <template slot="no-data">
                        <v-alert :value="true" color="warning" icon="warning">
                            <span v-if="!loading">Sin Eventos aún.</span>
                            <span v-if="loading">Cargando...</span>
                        </v-alert>
                    </template>
                </v-data-table>
                </v-card>
            </section>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex' 
export default {
    data () {
        return { 
            loading:null,
            steps:[                 
                {
                    text: 'Eventos',
                    disabled: false,
                    href: '/#/admin/evento/lista'
                }],
            pagination: {
                sortBy:'id',
                descending:true,
                search:null
            },
            headers: [
                {text:'Id', align:'center', sortable:true, value:'id'},
                {
                    text: 'Nombre Evento',
                    align: 'left',
                    sortable: true,
                    value: 'nombre'
                },
                { text: 'Fecha', value: 'fecha_hora',   align: 'left'},
                { text: 'Ciudad', value: 'ciudad' },
                { text: 'Organizador', value: 'organizador_id' },
                { text: 'Cierre inscripcion', value: 'fin_inscripcion' },
                { text: 'Inscriptos', value: 'total_inscriptos',align: 'center' },
                { text: 'MercadoPago', value: 'usa_pago_online',align: 'center' },
                { text: 'Visible', value: 'visible',align: 'center' },
                { text: 'Acciones', value: 'acciones', align: 'center',sortable: false },
            ],
            
        }
    },
    computed: mapState({
        items: state => state.eventos.eventos_listado? state.eventos.eventos_listado.data:[],
        totalItems: state => state.eventos.eventos_listado? state.eventos.eventos_listado.total:0
    }),
    created () {
        scroll(0,0);
        //this.$store.dispatch('eventos/getEventosListado', {filter:{},page:1})
    },
    watch:{
        pagination:{
            handler(){
                this.loading = true;
                this.getDataFromApi() 
            },
            deep: true
        }        
    },
    mounted(){
        this.loading = true;
        //this.getDataFromApi()
    },
    methods:{
        getDataFromApi(){
            this.loading = true;
            return this.$store.dispatch('eventos/getEventosListado',  this.pagination)
            .then((res)=>{
                this.loading = false;
            })
        }
    }
}
</script>                   
