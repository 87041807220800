
<template>

    <v-layout align-center justify-center>
        <v-flex>
            <v-breadcrumbs :items="steps" divider=">"></v-breadcrumbs>
            <section>
                <div class="mx-5">
                    <v-btn color="#fa5b3f" class="   mt-5" dark @click="openImageSelection">
                        Seleccionar Imagen de cabecera
                    </v-btn>
                    <p>La imagen de cabecera es la que se verá en la parte superior de la pantalla de informacion de un
                        evento</p>

                    <input hidden id="imagenFile" type="file" v-on:change="onImageChange" class="form-control">
                    <v-img max-height="100" max-width="300" :src=srcImagen></v-img>
                </div>

                <div class="mx-5">
                    <v-btn color="#fa5b3f" class="  mt-5" dark @click="openBannerSelection">
                        Seleccionar imagen de banner
                    </v-btn>
                    <p>El banner es la imagen que se visualiza en el listado de eventos y tambien se visualiza en la
                        pantalla de informacion de un evento del lado derecho</p>
                    <input id="bannerFile" hidden type="file" v-on:change="onBannerChange" class="form-control">
                    <v-img max-height="100" max-width="300" :src=srcBanner></v-img>
                </div>


                <div class="mx-5">
                    <v-btn color="#fa5b3f" class="mt-5" dark @click="openExtraImage">
                        Seleccionar imagenes extra
                    </v-btn>
                    <input id="extraImagesInput" type="file" @change="onExtraImageChange" multiple hidden>

                    <p>Se puede seleccionar mas de una imagen extra que se visuliza del lado derecho en la pantalla de
                        evento</p>
                    <v-layout row wrap>

                        <v-flex v-for="srcExtra in extraImages" :key="`3${i}`" xs3 pa-2>
                            <v-card dark color="primary">
                                <v-img :src="srcExtra.src" height="200px">
                                    <v-container fill-height fluid pa-2>

                                    </v-container>
                                </v-img>
                                <v-spacer></v-spacer>
                                <v-card-actions>
                                    <v-btn icon @click="removeImage(srcExtra)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>


                </div>

            </section>



            <section>
                <v-alert type="success" :value="exito">
                    {{ this.mensaje }}
                </v-alert>
                <v-alert type="error" :value="errores">
                    {{ this.errores }}
                </v-alert>
            </section>

            <section class="mx-5">
                <v-btn right :loading="loading" dark color="#fa5b3f" @click="uploadImage"><v-icon>upload</v-icon>Subir
                    imagenes</v-btn>
                <v-btn to='/admin/evento/lista'>Salir</v-btn>

                <v-btn :to="'/informacion-evento/' + eventoId">Ver Pantalla del evento</v-btn>

            </section>
        </v-flex>
    </v-layout>

</template>

<script>
import axios from 'axios'
import config from '../../config'
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'


export default {
    data() {
        return {
            extra_images_d: [],

            loading: false,
            image: '',
            banner: '',
            exito: false,
            errores: false,
            mensaje: '',
            eventoId: ''
        }
    },
    created() {
        scroll(0, 0);
        this.eventoId = this.$route.params.id;
        this.$store.dispatch('eventos/getEventoById', this.eventoId);
    },
    computed: mapState({
        evento: state => state.eventos.eventoActual,
        modalidades: state => state.eventos.eventoActual.modalidades,

        srcImagen(state) { return (this.image) ? this.image : state.eventos.eventoActual.image_full_path },
        srcBanner(state) { return (this.banner) ? this.banner : state.eventos.eventoActual.banner_full_path },

        extraImages(state) {
            const paths = state.eventos.eventoActual.imagenes ? state.eventos.eventoActual.imagenes.map((i) => { return { id: i.id, src: i.ruta_absoluta, uploaded: true } }) : [];
            return [...this.extra_images_d, ...paths]
        },

        steps(state) {

            var a = this.eventoId;

            return [
                {
                    text: 'Eventos',
                    disabled: false,
                    href: '/#/admin/evento/lista'
                },
                {
                    text: "Editar Evento",
                    disabled: false,
                    href: '/#/admin/evento/' + a + '/editar'
                },
                {
                    text: "Configurar Imagenes",
                    disabled: true,
                    href: '#'
                }
            ]
        },
    }),
    methods: {
        onExtraImageChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            this.extra_images_d = []
            for (let index = 0; index < files.length; index++) {
                this.createImage(files[index], 'extra_image', index);
            }
        },
        onImageChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0], 'image');
        },
        onBannerChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0], 'banner');
        },
        createImage(file, type, index) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                if (type == 'image') {
                    vm.image = e.target.result;
                } else if (type == 'banner') {
                    vm.banner = e.target.result;
                } else {
                    vm.$set(vm.extra_images_d, index, { id: this.guidGenerator(), src: e.target.result, uploaded: false })
                }

            };
            reader.readAsDataURL(file);
        },
        guidGenerator() {
            var S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
        },

        uploadImage() {
            let url = config.api_eventos_subir_imagen.replace('ID', this.eventoId);
            this.loading = true;
            axios.post(url, { image: this.image, banner: this.banner, imagenes: this.extra_images_d }).then(response => {
                this.loading = false;
                if (response.status == 200) {
                    this.$store.commit('app/SET_MESSAGE', response.data.message);
                    this.$store.commit('app/SET_SHOW', true);
                    this.extra_images_d = []
                    this.$store.dispatch('eventos/getEventoById', this.eventoId);
                    //this.exito = true;
                } else {
                    this.errores = response.data.message;
                }
            });
        },
        openImageSelection() {
            document.getElementById('imagenFile').click();
        },
        openBannerSelection() {
            document.getElementById('bannerFile').click();
        },
        openExtraImage() {
            document.getElementById('extraImagesInput').click()
        },
        removeImage(img) {
            if (img.uploaded) {
                //post para borrarla 
                this.loading = false;
                const url = config.api_eventos_borrar_imagen.replace('ID', this.eventoId).replace('IDIMG', img.id);
                axios.post(url, {})
                    .then(response => {

                        if (response.status == 200) {
                            this.$store.commit('app/SET_MESSAGE', response.data.message);
                            this.$store.commit('app/SET_SHOW', true);
                            this.$store.dispatch('eventos/getEventoById', this.eventoId);
                        } else {
                            console.log(response.data.message)
                            this.errores = response.data.message;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                //buscar en el array y borrar
                const index = this.extra_images_d.findIndex((i) => i.id == img.id);
                this.extra_images_d.splice(index, 1);
            }
        }
    }
}
</script>